import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PlotData } from "plotly.js";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
import Plot, { PlotParams } from "react-plotly.js";
//
import { E5Store } from "../../store/E5Store";
import { E5Time } from "../../util/E5Time";
//
import "./E5MetricChart.css";
import ReactECharts from 'echarts-for-react';

//E5
export interface E5MetricData {
	hovertext?: string[];
	xaxisdata: (number | string)[];
	datalabel?: string;
	text?: string[];
	hovertemplate?: string;
	fillcolor?: string;
	stairs?: boolean;
	markers?: boolean;
	reason?: string[];
	source?: string[];
	color?: string;
}

//E5
export interface E5MetricNumData extends E5MetricData {
	yaxisdata: (number | string | undefined)[];
}

//E5
export interface E5MetricCategData extends E5MetricData {
	yaxisdata: (string | undefined)[];
}

//E5
export interface E5MetricOptions {
	fill?: boolean;
	stacked?: boolean;
	ytickvals?: string[];
	yticktext?: string[];
}

//E5
export interface E5MetricTimeOption {
	xtimezone: number;
}

//E5
export interface E5MetricSource {
	numdatas?: E5MetricNumData[];
	categdatas?: E5MetricCategData[];
	options?: E5MetricOptions;
}

export interface E5XYChartOption {
	type?: string;
	filled?: boolean;
	colors?: string[];
	selected?: any;
	withoutSymbol?: boolean;
	logarithmic?: boolean;
}

//E5
interface E5MetricChartProps {
	leftsource: E5MetricSource;
	rightsource: E5MetricSource;
	additionalsource?: E5MetricSource;
	xoptions?: E5MetricTimeOption;
	loading: boolean;
	height: number;
	isSingleSelected?: boolean;
	chartOption?: E5XYChartOption;
	withNewComponent?: boolean;
	scrollableLegend?: boolean;
}

const COLOR_LIST = ['#43A047', '#66BB6A', '#9CCC65', '#D4E157', '#FFEE58', '#FFCA28', '#FFA726', '#FF7043', '#EF5350', '#E53935']


//E5
export const E5MetricChart: React.FC<E5MetricChartProps> = observer((props) => {

	const [data, setData] = useState<PlotData[]>([])
	const [selectedIndex, setSelectedIndex] = useState(-1)

	const GetData = useCallback((src: E5MetricSource, isright: boolean, isSingleSelected: boolean = false, isAdditional: undefined | boolean = false): void => {
		let shape: string = src.numdatas !== undefined ? "linear" : src.categdatas !== undefined ? "hv" : "";
		let propsdatas: E5MetricNumData[] | E5MetricCategData[] = src.numdatas ?? src.categdatas ?? [];
		const findIdx = selectedIndex >= 0 ? selectedIndex : propsdatas.findIndex(propdata => propdata.datalabel === 'hg6d')
		const selectedIdx = findIdx >= 0 ? findIdx : 0;

		const datas: PlotData[] = propsdatas.map((propdata, idx) => {
			let data: any;
			const visible = isSingleSelected ? idx === selectedIdx ? '' : 'legendonly' : ''
			data = {
				x: JSON.parse(JSON.stringify(propdata.xaxisdata)), y: JSON.parse(JSON.stringify(propdata.yaxisdata)),
				name: propdata.datalabel ?? "", text: propdata.text ?? "", hoverlabel: { font: { family: "montserrat" } },
				line: { shape }, hovertemplate: propdata.hovertemplate === 'none' ? "%{hovertext}<extra></extra>" : propdata.hovertemplate ?? "%{y} (%{x})<extra>%{fullData.name}</extra>",
				visible, hovertext: propdata.hovertext || ' '
			};
			if (propdata.fillcolor !== undefined) {
				data.marker = { color: propdata.fillcolor };
				data.fillcolor = propdata.fillcolor + "66";
			}

			if (propdata.stairs) data.line = { shape: "hv" };


			if (propdata.markers) data.mode = "markers";


			if (src.options?.stacked) data.stackgroup = "e5stackgroup";

			if (src.options?.stacked === false && src.options.fill) {
				if (idx > 0) data.fill = "tonexty";
				else {// imperfect yet helpful workaround to improve filled areas when data has gaps
					data.fill = "tozeroy";
					data.fillcolor = "transparent";
				}
			}

			if (isright) data.yaxis = "y2";

			if (isAdditional) data.yaxis = "y3";


			if (props.xoptions !== undefined && (data.x.length > 1 || isright || isAdditional)) {
				// add undefined at first and last value
				data.x.splice(0, 0, data.x[0]);
				data.x.push(moment(data.x[data.x.length - 1]).add(1, 'minute').utcOffset(E5Time.h_tzoffset).format());
				data.y.splice(0, 0, undefined);
				data.y.push(undefined);

				// find start and end of day
				let start: Moment = moment(data.x[Math.round(data.x.length / 2)]);// timezone already set by caller
				start.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
				start.startOf("day");
				let end: Moment = moment(start);
				end.utcOffset(E5Time.h_tzoffset);// always absolutety necessary
				end.endOf("day");
				// add undefined at start and end of the day
				data.x.splice(0, 0, start.format());
				data.x.push(end.format());
				data.y.splice(0, 0, undefined);
				data.y.push(undefined);
			}
			return data
		})
		setData(prev => (isright || isAdditional) ? [...prev, ...datas] : [...datas])
	}, [props?.xoptions, selectedIndex]);
	// force rerender when lang changes
	let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
	useEffect(() => {
		GetData(props.leftsource, false, props.isSingleSelected);
		GetData(props.rightsource, true, false);
		if (props.additionalsource?.numdatas?.[0]?.yaxisdata.length) {
			GetData(props.additionalsource, false, false, true);
		}

	}, [props, GetData, props.loading])

	const graphdata: PlotParams = useMemo(() => ({
		data: data,
		layout: {
			xaxis: { fixedrange: false, automargin: true, nticks: 5, tickformat: "%H:%M" },
			yaxis: {
				fixedrange: true, automargin: true, tickvals: props.leftsource.options?.ytickvals, ticktext: props.leftsource.options?.yticktext,
				tickmode: props.leftsource.options?.ytickvals !== undefined && props.leftsource.options.yticktext !== undefined ? "array" : "auto",
				categoryorder: props.leftsource.categdatas !== undefined ? "category ascending" : "trace"
			},
			yaxis2: {
				fixedrange: true, automargin: true, side: "right", overlaying: "y",
				categoryorder: props.rightsource.categdatas !== undefined ? "category ascending" : "trace"
			},
			yaxis3: {
				fixedrange: true, automargin: true, side: "right", overlaying: "y",
				categoryorder: props.additionalsource?.categdatas !== undefined ? "category ascending" : "trace"
			},
			font: { family: "montserrat" }, height: props.height, margin: { t: 30, r: 30, l: 30, b: 30 },
			legend: { orientation: "h" }, paper_bgcolor: "#00000000", plot_bgcolor: "#00000000"
		},
		config: { displayModeBar: false }
	}), [data, props]);


	const options = useMemo(() => {
		const data = props.leftsource?.numdatas || props.leftsource?.categdatas;

		const colors = props.chartOption?.filled && data?.[0]?.datalabel?.includes('100') ? [...COLOR_LIST].reverse() : COLOR_LIST;

		const series: any = data?.map((item: any, index: number) => ({
			name: item.datalabel,
			type: props.chartOption?.type || 'line',
			stack: 'Total',
			smooth: props.chartOption?.filled,
			lineStyle: props.chartOption?.filled ? { width: 0 } : null,
			showSymbol: (props.chartOption?.filled || props.chartOption?.withoutSymbol) ? false : true,
			areaStyle: props.chartOption?.filled ? { opacity: 0.8 } : null,
			itemStyle: props.chartOption?.filled ? { color: colors[index] } : { color: props.chartOption?.colors?.[index] },
			emphasis: {
				focus: 'series'
			},
			data: item.xaxisdata.map((value: string, index: number) => [value, item.yaxisdata[index] || 0])
		})) || [];

		const option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'line',
					label: {
						backgroundColor: '#6a7985'
					}
				}
			},
			legend: {
				type: props.scrollableLegend ? 'scroll' : 'plain',
				bottom: 0,
				selectedMode: 'single',
			},
			toolbox: {
				right: '4%',
				feature: {
					dataView: { show: true, readOnly: true },
					dataZoom: {
						yAxisIndex: 'none'
					},
					magicType: !props.chartOption?.filled ? {
						show: true,
						type: ['line', 'bar'],
						option: { bar: { stack: 'Add' }, line: { stack: '' } }
					} : null,
					// restore: {},
					saveAsImage: {}
				}
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '20%',
				containLabel: true
			},
			xAxis: [
				{
					type: 'time',
					boundaryGap: false,
				}
			],
			yAxis: [
				{
					type: props.leftsource?.categdatas ? 'category' : 'value'
				},
				{
					type: 'value',
					show: false,
					interval: 2,
					max: 2,
					axisLine: {
						show: false
					}
				}
			],
			series: [...series]
		};

		return option;
	}, [props])



	// e5column-5 solves many css issues, in particular width is not imposed by plotly
	// don't hide <Plot> when loading, because <Plot> fixes component height
	return <div className="e5compo e5metric-chart e5column-0">
		<div className="e5line-5">
			<div className="e5compotitle">
				{props.loading && <BP.Spinner className="e5spinwait" size={15} />}
			</div>
		</div>
		{props.withNewComponent ? <ReactECharts option={options} /> : <Plot
			{...graphdata}
			//@ts-ignore
			onLegendClick={(data) => {
				if (props?.isSingleSelected) {
					setSelectedIndex(data.expandedIndex)
					return false
				}
			}}
		/>}

	</div>
});
