import React from 'react';
import { E5Store } from '../../../store/E5Store';
import { E5StoreHConnectivity, E5StoreHStations } from "../../../store/E5StoreH";
import ReactECharts from 'echarts-for-react';
import "./E5HHeatmap.css";
import { E5UtilI18n } from '../../../global/E5MainLang';
import { Spinner } from '@blueprintjs/core';
import moment from 'moment';

//E5
interface E5HHeatmapProps {
	connectivityinfo: E5StoreHConnectivity;
	stationinfo: E5StoreHStations;
}

export default function E5HHeatmap(props: Readonly<E5HHeatmapProps>) {
	// force rerender when lang changes
	const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

	const getHostname = (mac: string) => {
		const station = props.stationinfo.stations.find((s) => s.macaddress === mac);

		return station ? station.hostname : mac;
	}

	const parseRssi = (rssi: number | null) => {
		return rssi ? Math.round(rssi * 100) / 100 : '-';
	}

	const hoursArray: number[] = [];
	const xAxisDataText = [
		...new Set(props.connectivityinfo.rssipersta
			.map((station) => station.rssiperhour
			.map((rssi) => rssi.time.unix()))
			.flat(1)
			.sort((a, b) => a - b))
	];

	for (let i = xAxisDataText[0]; i <= xAxisDataText[xAxisDataText.length - 1]; i += 3600) {
		hoursArray.push(i);
	}

	const yAxis = props.connectivityinfo.rssipersta.map((station) => `${getHostname(station.child)}\n(${station.child})`);
	const data = props.connectivityinfo.rssipersta
		.map((station, stationIndex) => station.rssiperhour
		.map((rssi) => [hoursArray.findIndex(hour => hour === rssi.time.unix()), stationIndex, parseRssi(rssi.rssi)]))
		.flat(1);


	console.log(data)
	const chartOptions = {
		tooltip: {
			position: 'top'
		},
		grid: {
			left: '10%',
			right: '5%',
			height: 'auto',
			width: '85%',
		},
		xAxis: {
			type: 'category',
			data: hoursArray.map((hour) => moment.unix(hour).utc(true).format('DD/MM/YYYY HH:mm').replace(' ', '\n')),
			splitArea: {
				show: true
			},
			axisLabel: {
				fontFamily: 'Montserrat',
				color: '#000',
				fontSize: 13,
			}
		},
		yAxis: {
			type: 'category',
			data: yAxis,
			splitArea: {
				show: true
			},
			axisLabel: {
				fontFamily: 'Montserrat',
				color: '#000',
				fontSize: 13,
			}
		},
		visualMap: {
			min: -80,
			max: -60,
			calculable: false,
			color: ["#15b371", "#5c7080", "#d9822b", "#ff0000"],
			show: false,
		},
		legend: {
			show: false,
		},
		series: [{
			type: 'heatmap',
			label: {
				show: true,
				fontFamily: 'Montserrat',
				fontSize: 13,
				color: '#fff',
			},
			emphasis: {
				itemStyle: {
					shadowBlur: 10,
				},
				label: {
					fontFamily: 'Montserrat',
					fontSize: 15,
					color: '#fff',
				}
			},
			data
		}],
	}

	if (props.connectivityinfo.loading) {
		return (
			<div className="e5line-20 e5hloading">
				<Spinner className="e5spinwait" size={30} />
			</div>
		)
	} else if (!data.length) {
		return null;
	} else {
		return (
			<div className="e5wifiheatmap">
				<ReactECharts option={chartOptions} />

				<div className="legend">
					<p>{E5UtilI18n._('wifih-topology-avg-rssi')}</p>

					<div className="legend-item">
						<div className="block rssi-red"></div>
						<p>{'< -80'}</p>
					</div>

					<div className="legend-item">
						<div className="block rssi-orange"></div>
						<p>-80 - -70</p>
					</div>

					<div className="legend-item">
						<div className="block rssi-gray"></div>
						<p>-70 - -60</p>
					</div>

					<div className="legend-item">
						<div className="block rssi-green"></div>
						<p>{'> -60'}</p>
					</div>
				</div>
			</div>
		)
	}

}

