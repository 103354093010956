import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
import * as BPD from "@blueprintjs/datetime";
import { IReactionDisposer, reaction } from "mobx";
import MomentLocaleUtils from "react-day-picker/moment";
//
import { E5EntCBPop, E5CBPopCriteria } from "../../../entity/customer_base/E5EntCBPop";
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import { E5RequestACL } from "../../../request/E5RequestACL";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreACL } from "../../../store/E5StoreACL";
import { E5BandEnum } from "../../../entity/E5Enums";
//
import "./E5CBPopPopup.css";
import Button from "../../../global/component/Button";
import { E5RequestCBPop } from "../../../request/E5RequestCBPop";
import { E5RequestStatus } from "../../../request/E5ServiceCommon";

//E5
enum E5CBPopDialMode { netid = "netid", crit = "crit" }

//E5
interface E5CBPopPopupState {
	pop: E5EntCBPop;
	dialopen: boolean;
	dialjustopen: boolean;
	dialmode: E5CBPopDialMode;
	netidsinput: string;
	critinput: E5CBPopCriteria;
	criterionValue: string;
}

//E5
export type E5CBPopUpdateCallback = (pop: E5EntCBPop, onlyinfochanged: boolean) => void;

//E5
interface E5CBPopPopupProps {
	langinfo: E5StoreLangInfo;
	pop: E5EntCBPop;
	updatefunc: E5CBPopUpdateCallback;
	canaddnetworkids: boolean;
	canaddcriterias: boolean;
	wholeparkexists: boolean;
}

//E5
export const E5CBPopPopup = observer(class E5CBPopPopup extends React.PureComponent
	<E5CBPopPopupProps, E5CBPopPopupState> {

	// ---------------- MEMBERS ----------------

	//E5
	stop_change_pop?: IReactionDisposer;
	popnameinput?: HTMLInputElement;
	popnameinputref = (ref: any): void => this.popnameinput = ref;
	netidstextarea?: HTMLTextAreaElement;
	netidstextarearef = (ref: any): void => this.netidstextarea = ref;
	toasterref: React.RefObject<BP.Toaster>;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBPopPopupProps, state: E5CBPopPopupState) {
		super(props, state);
		let pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.props.pop);
		this.state = {
			pop, dialopen: false, dialjustopen: false, dialmode: E5CBPopDialMode.netid, netidsinput: "",
			critinput: new E5CBPopCriteria(), criterionValue: '',
		};
		E5RequestCBPop.Ins().FetchNetIds(pop.populationid, "USER", this.parseNetwarkIds)
		this.toasterref = React.createRef();
	}

	//E5
	parseNetwarkIds = (status: E5RequestStatus, netids: string[]): void => {
		if (status.success) {
			this.state.pop.networkids = netids;
			this.setState({ pop: this.state.pop });
		}
	};

	//E5
	componentDidMount(): void {
		this.popnameinput?.focus();
		this.stop_change_pop = reaction(() => this.props.pop.populationid, () => {
			let pop: E5EntCBPop = new E5EntCBPop();
			pop.Copy(this.props.pop);
			this.setState({ pop });
		});
	}

	//E5
	componentDidUpdate() {
		if (this.state.dialopen && this.state.dialjustopen && this.state.dialmode === E5CBPopDialMode.netid)
			this.netidstextarea?.focus();
	}

	//E5
	componentWillUnmount(): void {
		this.stop_change_pop?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { NONE, PRIMARY, WARNING } = BP.Intent;
		let { creationdate, criterias, networkidcount, networkids } = this.state.pop;
		let { criteria, condition, value } = this.state.critinput, { cb_filtermap } = E5RequestMeta.Ins();

		let hasaclgroup: boolean = this.state.pop.aclname !== "";
		let redstarjsx: JSX.Element = <span style={{ color: "#FF0000" }}>*</span>;

		let idx: number, dialtitle: string, dialjsx: JSX.Element, savejsx: JSX.Element = <></>;
		if (this.state.dialmode === E5CBPopDialMode.netid) {
			dialtitle = _("wificb-pops-netids-defids");
			dialjsx = <div>
				<div className="wificb-pops-dial-row">{_("wificb-pops-netids-explain")}</div>
				<BP.FormGroup label={_("wificb-pops-listof")} labelFor="wificb-pops-netids-txt"
					labelInfo={redstarjsx}>
					<BP.TextArea id="wificb-pops-netids-txt" onChange={this.ChangeNetIds} value={this.state.netidsinput}
						className="wificb-pops-netids-txt" inputRef={this.netidstextarearef} fill={true} />
				</BP.FormGroup>
				<BP.Tooltip content={_(hasaclgroup ? "wificb-pops-acl-ids-warn" : "wificb-pops-acl-ids-help")}>
					<BP.Button text={_("wificb-pops-acl-ids-load")} intent={PRIMARY}
						className={hasaclgroup ? "" : BP.Classes.DISABLED} onClick={() => {
							if (hasaclgroup) {
								let existing: boolean = E5Store.Ins().userinfo.curuser.roles.includes("ROLE_ADMIN");
								E5RequestACL.Ins().GetGroup(this.state.pop.aclname, existing,
									(status, group: E5EntAclGroup) => {
										if (status.success) this.setState({ netidsinput: group.listnetid });
										else this.toasterref.current?.show({
											message: E5UtilI18n._("wificb-pops-acl-ids-fail") + " : " + status.message,
											intent: BP.Intent.DANGER
										});
									}, null);
							}
						}} />
				</BP.Tooltip>
			</div>;
			if (this.state.netidsinput !== "")
				//savejsx = <BP.Button text={_("ok")} intent={PRIMARY} onClick={this.SetNetIds} />;
				savejsx = <Button theme="green" onClick={this.SetNetIds}>{_("ok")}</Button>;
		} else {
			dialtitle = _("wificb-pops-crits-add");
			let critstr: string = criteria ?? "", valstr: string = value ?? "", critjsx: JSX.Element[] = [];
			for (let [filter] of cb_filtermap)
				critjsx.push(<BP.Radio key={filter} value={filter} label={_("meta-pop-filter-" + filter).replace(
					"Wan", "WAN").replace("Wifi", "Wi-Fi")} />);
			let condjsx: JSX.Element[] = [], conds: string[] | undefined = cb_filtermap.get(critstr);
			if (conds !== undefined) for (idx = 0; idx < conds.length; idx++) if (conds[idx] !== "") {
				let condstr: string = conds[idx], intent: BP.Intent = condition === condstr ? PRIMARY : NONE;
				if (["contains", "!contains"].includes(condstr)) condstr = _("wificb-pops-cond-" + conds[idx]);
				condjsx.push(<BP.Button className="e5linefull" intent={intent} value={conds[idx]} text={condstr}
					onClick={(evt: any) => this.ChangeCrit("condition", evt)} key={idx} />);
			}
			let hascrit: boolean = critstr !== "", hascond: boolean = condition !== "",
				usesselector: boolean = critstr === "bandsUsed" || critstr === "standardsUsed",
				{ freq2ghz, freq5ghz, freq6ghz, eth } = E5BandEnum,
				bands: string[] = [freq2ghz, freq5ghz, freq6ghz, eth.toLowerCase()],
				bandopts: JSX.Element[] = [
					<option value="" key={-1} id="" />, ...bands.map(band =>
						<option value={band} key={band} id={band}>{_(band)}</option>)
				],
				stds: string[] = ["a", "b", "g", "n", "ac", "ax"],
				stdopts: JSX.Element[] = [
					<option value="" key={-1} id="" />, ...stds.map(std =>
						<option value={std} key={std} id={std}>{std}</option>)
				];
			const criterionOptions = critjsx.map((item) => <option value={item.props.value}>{item.props.label}</option>);
			criterionOptions.unshift(<option value={'empty'}></option>)
			dialjsx = <div>
				<BP.FormGroup className="criterion-inputs" label={_("wificb-pops-criteria")} labelInfo={redstarjsx}>
					<BP.HTMLSelect className="wificb-pops-criteria-select" id='wificb-pops-criteria-select' onChange={(evt: any) => { this.ChangeCrit("criteria", evt) }}>
						{criterionOptions}
					</BP.HTMLSelect>
				</BP.FormGroup>
				<BP.FormGroup className="criterion-inputs" label={_("wificb-pops-condition")} labelInfo={redstarjsx}>
					<div className="wificb-pops-condition-wrapper">
						{hascrit && <div className="e5line-10">{condjsx}</div>}
					</div>
				</BP.FormGroup>
				<BP.FormGroup className="criterion-inputs" label={_("wificb-pops-value")} labelFor="wificb-pops-value" labelInfo={redstarjsx}>
					{usesselector && <BP.HTMLSelect
						className="wideselect" children={critstr === "bandsUsed" ? bandopts : stdopts}
						id="wificb-pops-value" value={valstr} disabled={!hascrit || !hascond}
						onChange={evt => this.ChangeCrit("value", evt)} />}
					{!usesselector && <BP.InputGroup
						id="wificb-pops-value" value={valstr} disabled={!hascrit || !hascond}
						onChange={(evt: any) => this.ChangeCrit("value", evt)} />}
				</BP.FormGroup>
				{
					["extenderCount", "stbCount", "deviceCount"].includes(critstr) ?
						_("wificb-pops-crit-integer") : ""
				}
				{
					["softwareVersion.gateway", "softwareVersion.extenders"].includes(critstr) ?
						_("wificb-pops-crit-string") : ""
				}
				{
					["standardsUsed", "bandsUsed"].includes(critstr) ?
						_("wificb-pops-crit-option") : ""
				}
				{
					["health.systemHealth", "health.wanHealth", "health.wifiHealth", "health.globalHealth"].includes(
						critstr) ? _("wificb-pops-crit-float") : ""
				}
				{critstr === "" ? <div>&nbsp;</div> : ""}
			</div >;
			if (hascrit && hascond && valstr !== "")
				// savejsx = <BP.Button text={_("ok")} intent={PRIMARY} onClick={this.AddCrit} />;
				savejsx = <Button theme="green" onClick={this.AddCrit}>{_("ok")}</Button>;
		}

		let rowheights: number[] = criterias !== null ? criterias.map(() => 23) : [];

		let wholepark: boolean = false;
		if (criterias !== null && criterias.length === 1) {
			let crit: E5CBPopCriteria = criterias[0];
			if (crit.criteria === null && crit.condition === null && crit.value === null) wholepark = true;
		}

		let aclopts: JSX.Element[] = [<option key="status" value="" />];
		for (let { name } of E5StoreACL.Ins().aclinfo.groups)
			aclopts.push(<option key={name} value={name} label={name} />);

		return <div className="e5wificb-pops-popup">
			<BP.Toaster ref={this.toasterref} />
			<div className="e5line-10">
				<div className="e5linefull">
					<BP.FormGroup label={_("wificb-pops-name")} labelFor="wificb-pops-name" labelInfo={redstarjsx}>
						<BP.InputGroup id="wificb-pops-name" value={this.state.pop.name} onChange={this.ChangeName}
							title="name" inputRef={this.popnameinputref} />
					</BP.FormGroup>
				</div>
				<div className="e5linefull">
					<BP.FormGroup labelFor="wificb-pops-populationid">
						<BP.InputGroup placeholder={_("wificb-pops-populationid-pop")} id="wificb-pops-populationid" value={this.state.pop.populationid}
							title="populationid" readOnly />
					</BP.FormGroup>
				</div>
			</div>
			<div className="e5line-10">
				<div className="e5linefull">
					<BP.FormGroup label={_("wificb-pops-aclname")} className="wificb-pops-acl-select" labelFor="wificb-pops-aclname"
						labelInfo={redstarjsx}>
						<BP.HTMLSelect value={this.state.pop.aclname} onChange={this.ChangeAclName}>
							{aclopts}
						</BP.HTMLSelect>
					</BP.FormGroup>
				</div>
			</div>
			<div className="e5line-10">
				<div className="e5linefull">
					<BP.FormGroup>
						<BPD.DateInput placeholder={_("wificb-pops-creationdate")} value={creationdate !== null ? creationdate.toDate() : null}
							formatDate={this.FormatDateTime} parseDate={this.ParseDateTime}
							localeUtils={MomentLocaleUtils} locale={E5UtilI18n.curlang} disabled={true} />
					</BP.FormGroup>
				</div>
				<div className="e5linefull">
					<BP.FormGroup labelFor="wificb-pops-networkidcount">
						<BP.InputGroup
							placeholder={_("wificb-pops-networkidcount")}
							id="wificb-pops-networkidcount" title="totalnetwork" disabled={true}
							value={networkidcount !== null ? "" + networkidcount : _("wificb-pops-unavailable")} />
					</BP.FormGroup>
				</div>
			</div>
			<div className="e5line-10 wificb-pops-dial-row">
				<div className="e5linefull" />
				{!this.props.canaddcriterias &&
					<BP.Button intent={WARNING} onClick={this.Reset}>
						{_("wificb-pops-netids-remove")}&nbsp;
						({typeof networkids === "number" ? networkids : networkids !== null ? networkids.length : 0})
					</BP.Button>}
				{!this.props.canaddnetworkids && !wholepark &&
					<BP.Button intent={WARNING} onClick={this.Reset}>
						{_("wificb-pops-crits-remove")} ({criterias !== null ? criterias.length : 0})
					</BP.Button>}
				{wholepark &&
					<BP.Button intent={WARNING} onClick={this.Reset}>{_("wificb-pops-remove-park")}</BP.Button>}
				{this.props.canaddnetworkids &&
					<BP.Button onClick={() => this.OpenDial(E5CBPopDialMode.netid)}>
						{_("wificb-pops-netids-defids")}
					</BP.Button>}
				{this.props.canaddcriterias && !wholepark &&
					<BP.Button onClick={() => this.OpenDial(E5CBPopDialMode.crit)}>
						{_("wificb-pops-crits-add")}
					</BP.Button>}
				{!this.props.wholeparkexists && this.props.canaddnetworkids && this.props.canaddcriterias &&
					<BP.Button onClick={this.SetWholePark}>
						{_("wificb-pops-whole-park")}
					</BP.Button>}
			</div>
			<div className="e5columnfull" />
			<BPT.Table numFrozenRows={0} selectionModes={BPT.SelectionModes.ROWS_AND_CELLS} enableRowHeader={false}
				className="e5wificb-pops-crittable" numRows={criterias !== null ? criterias.length : 0}
				columnWidths={[260, 100, 100]} rowHeights={rowheights}>
				<BPT.Column cellRenderer={(ridx) => this.CellRenderer(ridx, "criteria")}
					columnHeaderCellRenderer={() => this.HeadRenderer("criteria")} />
				<BPT.Column cellRenderer={(ridx) => this.CellRenderer(ridx, "condition")}
					columnHeaderCellRenderer={() => this.HeadRenderer("condition")} />
				<BPT.Column cellRenderer={(ridx) => this.CellRenderer(ridx, "value")}
					columnHeaderCellRenderer={() => this.HeadRenderer("value")} />
			</BPT.Table>
			<BP.Dialog title={dialtitle} isOpen={this.state.dialopen} canOutsideClickClose={false}
				canEscapeKeyClose={false} onClose={this.CloseDial} lazy={false}>
				<div className={BP.Classes.DIALOG_BODY}>{dialjsx}</div>
				<div className={BP.Classes.DIALOG_FOOTER}>
					<div className={BP.Classes.DIALOG_FOOTER_ACTIONS}>
						<div style={{ flex: 1 }} />
						{savejsx}
						{/* <BP.Button text={_("cancel")} onClick={this.CloseDial} /> */}
						<Button style={{ marginLeft: '15px' }} theme="blue" onClick={this.CloseDial}>{_("cancel")}</Button>
					</div>
				</div>
			</BP.Dialog>
		</div>;
	}

	//E5
	CellRenderer = (rowidx: number, field: string): JSX.Element => {
		let cell: JSX.Element = <div />;
		if (this.state.pop.criterias !== null && rowidx < this.state.pop.criterias.length) {
			// double check this.state.statepop.criterias.length because this code may be called outside of boundaries
			let datavalue: any = (this.state.pop.criterias[rowidx] as any)[field];
			if (datavalue === null) datavalue = "null";
			let deletebtn: JSX.Element = <></>;
			if (field === "criteria") {
				deletebtn = <BP.Button icon="cross" intent={BP.Intent.DANGER} minimal={true} small={true}
					onClick={() => this.DeleteCrit(rowidx)} />;
				if (datavalue !== "null") datavalue = E5UtilI18n._("meta-pop-filter-" + datavalue);
				else datavalue = "";
			} else if (field === "condition") {
				if (datavalue === "contains" || datavalue === "!contains")
					datavalue = E5UtilI18n._("wificb-pops-cond-" + datavalue);
				if (datavalue === "null") datavalue = "";
			} else if (field === "value" && datavalue === "null") datavalue = "";
			cell = <BPT.Cell>
				<div className="e5line-5">
					<div>{datavalue}</div>
					<div className="e5linefull" />
					{deletebtn}
				</div>
			</BPT.Cell>;
		}
		return cell;
	}

	//E5
	HeadRenderer = (field: string): JSX.Element =>
		<div className="tbhead">
			<div className="tbheadname">{E5UtilI18n._("wificb-pops-" + field)}</div>
		</div>;

	// ---------------- EVENTS ----------------

	//E5
	ChangeName = (event: any): void => {
		let val = event.currentTarget.value, pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.state.pop);
		pop.name = val;
		this.setState({ pop });
		this.props.updatefunc(pop, true);
	};

	//E5
	ChangeAclName = (event: any) => {
		let val: string | undefined = event.currentTarget.value, pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.state.pop);
		pop.aclname = val ?? "";
		this.setState({ pop });
		this.props.updatefunc(pop, true);
	};

	//E5
	ChangeNetIds = (event: any): void =>
		this.setState({ dialjustopen: false, netidsinput: event.currentTarget.value });

	//E5
	SetNetIds = (): void => {
		let netids: string[] = [], validnetids: string[] = [], idx: number;
		if (this.state.netidsinput.length > 0) {
			netids = this.state.netidsinput.split(/\r\n|\r|\n|,|;| /g);
			for (idx = 0; idx < netids.length; idx++) if (netids[idx] !== "") validnetids.push(netids[idx]);
		}
		let pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.state.pop);
		pop.networkids = [];
		let duplicates = [];
		for (idx = 0; idx < validnetids.length; idx++) {
			pop.networkids.push(validnetids[idx]);
			if (validnetids.indexOf(validnetids[idx]) !== validnetids.lastIndexOf(validnetids[idx])) {
				duplicates.push(validnetids[idx])
			}
		}
		if (duplicates.length > 0) {
			this.toasterref.current?.show({
				message: E5UtilI18n._("wificb-pops-updateok-withduplicates") + " : "
					+ [...new Set(duplicates)] + " Duplicates will be auto-removed and only uniques left",
				intent: BP.Intent.WARNING
			})
			pop.networkids = [...new Set(pop.networkids.toString().split(/\r\n|\r|\n|,|;| /g))];
		}
		this.setState({ pop, dialopen: false, netidsinput: "" });
		this.props.updatefunc(pop, false);
	};

	//E5
	ChangeCrit = (field: "criteria" | "condition" | "value", event: any): void => {
		if (event.currentTarget.value !== 'empty') {
			let val: string = event.currentTarget.value, crit: E5CBPopCriteria = new E5CBPopCriteria(this.state.critinput);
			if (field === "criteria" && val !== crit.criteria) crit.condition = crit.value = "";
			crit[field] = val;
			this.setState({ dialjustopen: false, critinput: crit });
		}

	};

	//E5
	AddCrit = (): void => {
		let crit: E5CBPopCriteria = new E5CBPopCriteria(this.state.critinput), pop: E5EntCBPop = new E5EntCBPop();

		const isDuplicate = this.state.pop.criterias?.find(
			({ criteria, condition, value }) => crit.criteria === criteria && crit.condition === condition && crit.value === value)

		pop.Copy(this.state.pop);
		if (pop.criterias === null) pop.criterias = [];
		if (!isDuplicate) pop.criterias.push(crit);
		this.setState({ pop, dialopen: false, critinput: new E5CBPopCriteria() });
		this.props.updatefunc(pop, false);
	};

	//E5
	DeleteCrit = (rowidx: number): void => {
		let crits: E5CBPopCriteria[] = this.state.pop.criterias !== null ?
			this.state.pop.criterias.filter((crit, idx) => idx !== rowidx) : [];
		let pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.state.pop);
		pop.criterias = crits;
		this.setState({ pop });
		this.props.updatefunc(pop, false);
	}

	//E5
	SetWholePark = (): void => {
		let pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.state.pop);
		pop.networkids = [];
		pop.criterias = [{ criteria: null, condition: null, value: null }];
		this.setState({ pop });
		this.props.updatefunc(pop, false);
	};

	//E5
	Reset = (): void => {
		let pop: E5EntCBPop = new E5EntCBPop();
		pop.Copy(this.state.pop);
		pop.networkids = [];
		pop.criterias = [];
		this.setState({ pop });
		this.props.updatefunc(pop, false);
	};

	//E5
	OpenDial = (dialmode: E5CBPopDialMode): void => this.setState({ dialopen: true, dialjustopen: true, dialmode });

	//E5
	CloseDial = (): void =>
		this.setState({ dialopen: false, dialjustopen: false, netidsinput: "", critinput: new E5CBPopCriteria() });

	// ---------------- UTILS ----------------

	//E5
	FormatDateTime = (dt: Date): string => moment(dt).format(E5UtilI18n._("dateformat") + " HH:mm");

	//E5
	ParseDateTime = (str: string): Date => moment(str, E5UtilI18n._("dateformat") + " HH:mm").toDate();
});
