import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { IReactionDisposer, reaction } from "mobx";
import moment, { Moment } from "moment";
//
import { E5StoreH, E5StoreHEquips, E5StoreHIndicSys, E5StoreHMetSys, E5StoreHTopoSelected } from "../../../store/E5StoreH";
import { E5EntHKpiSysProcStatus } from "../../../entity/household/metric/E5EntHKpiSysProcStatus";
import { E5EntHMetSys, E5HMetSysField } from "../../../entity/household/metric/E5EntHMetSys";
import { E5EntHMetSysCpuMem } from "../../../entity/household/metric/E5EntHMetSysCpuMem";
import { E5EntHMetSysFlash } from "../../../entity/household/metric/E5EntHMetSysFlash";
import { E5EntHMetSysCrash } from "../../../entity/household/metric/E5EntHMetSysCrash";
import { E5EntHMetSysProc } from "../../../entity/household/metric/E5EntHMetSysProc";
import { E5EntHMetSysTemp } from "../../../entity/household/metric/E5EntHMetSysTemp";
import { E5MetricNumData } from "../../../global/plot/E5MetricChart";//eslint-disable-line
import { E5XYChart } from "../../../global/plot/E5XYChart";//eslint-disable-line
import { E5EntHKpiSysReb } from "../../../entity/household/metric/E5EntHKpiSysReb";
import { E5EntHMetSysReb } from "../../../entity/household/metric/E5EntHMetSysReb";
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5PieChart } from "../../../global/plot/E5PieChart";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5NetElementType } from "../../../entity/E5Enums";
import { E5HMetricWifiEquip } from "./E5HMetricWifiEquip";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5HMetricIndic } from "./E5HMetricIndic";
import { E5HEquipList } from "../E5HEquipList";
import { E5Text } from "../../../util/E5Text";
import ReactECharts from 'echarts-for-react';
import { getValue, renderValue, sort } from "../../../util/E5SortByDateUtils";
//
import "./E5HMetricSysEquip.css";
import { E5EntHStation } from "../../../entity/household/topo/E5EntHStation";
import { Select, OutlinedInput, MenuItem, InputLabel, FormControl } from '@mui/material'
import { Spinner } from "@blueprintjs/core";
import { crashEventChartOptions, rebootChartOptions, uptimeChartOptions } from "../../../util/E5HMetricSysEquipUtils";
import Table from '../../../global/component/E5Table'
import { E5EntHIndSysCrash } from "../../../entity/household/indic/E5EntHIndSysCrash";


//E5
interface E5HMetSysPieData {
	rebootids: string[];
	rebootparents: string[];
	rebootvalues: number[];
	rebootlabels: string[];

	procmemids: string[];
	procmemparents: string[];
	procmemvalues: number[];
	procmemlabels: string[];

	proccpuids: string[];
	proccpuparents: string[];
	proccpuvalues: number[];
	proccpulabels: string[];

	procstatusids: string[];
	procstatusparents: string[];
	procstatusvalues: number[];
	procstatuslabels: string[];
}

//E5
interface E5HMetSysSummaryData {
	cpumin?: number;
	cpuavg?: number;
	cpumax?: number;

	memmin?: number;
	memavg?: number;
	memmax?: number;

	tempmin?: number;
	tempavg?: number;
	tempmax?: number;

	flashtot?: number;
	rebootcnt?: number;
}

//E5
interface E5HMetricSysEquipState {
	leftsource: string[];
	leftfield: (E5HMetSysField | null)[];

	rightsource: string[];
	rightfield: (E5HMetSysField | null)[];

	xydata: any;

	rebootData: any;
	upTimeEvent: any;
	crashEvent: any;
	rebootReasons: any;
}

//E5
interface E5HMetricSysEquipProps {
	langinfo: E5StoreLangInfo;
	selectedinfo: E5StoreHTopoSelected;
	equipinfo: E5StoreHEquips;
	metricinfo: E5StoreHMetSys;
	indicsysinfo: E5StoreHIndicSys;
}

//E5
export const E5HMetricSysEquip = observer(class E5HMetricSysEquip extends React.PureComponent
	<E5HMetricSysEquipProps, E5HMetricSysEquipState> {

	// ---------------- MEMBERS ----------------

	//E5
	stop_change_selected_equip?: IReactionDisposer;
	stop_change_curlang?: IReactionDisposer;
	options: JSX.Element[];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HMetricSysEquipProps, state: E5HMetricSysEquipState) {
		super(props, state);
		let { fields } = E5EntHMetSys, udf: undefined = undefined,
			leftfield: (E5HMetSysField | null)[] = [//eslint-disable-line
				E5MainConfig.GetSystemCpuEnabled() ? fields[2] : null,
				E5MainConfig.GetSystemMemoryEnabled() ? fields[3] : null,
				E5MainConfig.GetSystemProcessEnabled() ? fields[6] : null,
				E5MainConfig.GetSystemProcessEnabled() ? fields[7] : null
			];
		this.options = this.BuildOptions();
		this.state = {
			leftsource: ["cpuload", "processcputime", "cpuload", "memory", 'processmemusage', 'uptime', 'reboot'],
			leftfield: [fields[2], fields[7], fields[2], fields[3], fields[6], fields[4], fields[0]],

			rightsource: ["", "", "", "", "", "", "", ""], rightfield: [null, null, null, null, null, null, null,],

			xydata: {
				left: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
				],
				right: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
				]
			},

			rebootData: [],
			upTimeEvent: [],
			crashEvent: [],
			rebootReasons: [],
		};
	}

	//E5
	componentDidMount(): void {
		this.Populate();
		this.stop_change_selected_equip =
		reaction(() => String(E5StoreH.Ins().metsysinfo.loading) + this.props.selectedinfo.equip.imei,
				() => {
					this.Populate();
				});
		this.stop_change_curlang =
			reaction(() => this.props.langinfo.curlang, () => this.options = this.BuildOptions());
	}

	//E5
	Populate(): void {
		this.BuildGraphData();
		this.BuildRebootChartData();
		this.RebootReasonsTableData();
	}

	//E5
	componentWillUnmount(): void {
		this.stop_change_selected_equip?.();
		this.stop_change_curlang?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { type, equip } = this.props.selectedinfo;
		let { node } = E5NetElementType, { PadNumberOrNA } = E5HMetricWifiEquip;

		let pie: E5HMetSysPieData = this.BuildPieData(), piepx: number = 308;
		let summary: E5HMetSysSummaryData = this.BuildSummaryData(), dig: number = 2;

		let xytitle: string = _("wifih-metrics-charttitle-equip") + type === node ?
			" (" + _("netelementtype-" + node) + " " + equip.imei + ")" : "";

		let descs: string[] = ["", "", "", ""], descmap: Map<string, string> = new Map();
		descmap.set("flashtotalerase", "h-metric-sys-equip-flashtotalerase-desc");
		descmap.set("flashavailerase", "h-metric-sys-equip-flashavailerase-desc");
		descmap.set("flashbadpebcnt", "h-metric-sys-equip-flashbadpebcnt-desc");
		descmap.set("flashmaxerasecnt", "h-metric-sys-equip-flashmaxerasecnt-desc");
		let idx: number, name: string, desc: string;
		for (idx = 0; idx < 4; idx++)
			for ([name, desc] of descmap)
				if (this.state.leftsource[idx] === name || this.state.rightsource[idx] === name) {
					if (descs[idx] !== "") descs[idx] += ", ";
					descs[idx] += _(desc);
				}

		return <div className="e5wifih-metrics-content e5columnfull e5wifih-metrics-overview">
			<div className="tabdesc" />
			<div className="e5wifih-metrics-components e5column-20">
				<div className="e5line-20">
					<div className="e5wifih-metrics-leftcolumn">
						<div className="e5wifih-metrics-householdelems">
							<E5HEquipList
								indicsysinfo={E5StoreH.Ins().indicsysinfo} langinfo={E5Store.Ins().langinfo}
								selectedinfo={E5StoreH.Ins().selectedinfo} showbox hidescoreimg={true}
								equipinfo={E5StoreH.Ins().equipinfo} minimal />
						</div>
					</div>
					<div className="e5linefull e5column-20">
						<div className="e5h-metric-sys-equip e5column-20">
							<E5HMetricIndic langinfo={E5Store.Ins().langinfo} rebootcnt={summary.rebootcnt}
								flashusage={summary.flashtot} elemtype={type} elemname={equip.imei}
								health={type === node ? equip.health : undefined} mode="system">
								<FormControl className={"filterbloc"} sx={{ m: 1, width: 250 }}>
									<InputLabel id="name-label">Equipment</InputLabel>
									<Select labelId="name-label" onChange={this.handleChange} value={E5StoreH.Ins().selectedinfo.equip.imei} input={<OutlinedInput label="Equipment" />}>
										<MenuItem value={''}>
											Select equipment
										</MenuItem>
										{E5StoreH.Ins().equipinfo.equips.map((equip) => {

											const equipmentType = equip.nodetype;
											let img = <div></div>
											if (equipmentType === 'ext') {
												img = <img src='./img/v3/metrics/extender.svg' className='e5wifih-metrics-nodes-select-svg' alt="Extender" />
											} else {
												img = <img src='./img/v3/metrics/gateway.svg' className='e5wifih-metrics-nodes-select-svg' alt="Gateway" />
											}
											return <MenuItem value={equip.imei} key={equip.imei}>
												<div className='e5wifih-metrics-nodes-select-wrapper'>
													<div style={{ width: '30px', display: 'flex', justifyContent: "center" }}>
														{img}
													</div>
													{equip.imei}
												</div>
											</MenuItem>
										}
										)}
									</Select>
								</FormControl>
							</E5HMetricIndic>
							<div className="e5column-20">
								<div className="e5line-20 e5grid-two">
									<div className="e5compo">
										<span className="e5compotitle">
											{_("h-metric-sys-equip-pie-reboot")}
										</span>

										{
											this.state.rebootReasons.length ?
												(
													<div className="p-2">
														<Table className='' rowHeight={35} 
															firstColumnName='time'
															sortMode='desc'
															columns={[
																{
																	field: 'time',
																	headerName: _('wifih-metrics-overview-reboot-reason-timestamp'),
																	editable: true,
																	headerAlign: 'center',
																	align: 'center',
																	valueGetter: (params: any) => {
																		return getValue(params.row.time);
																	},
																	renderCell: (params: any) => {
																		return renderValue(params.row.time);
																	},
																	sortComparator: (v1: any, v2: any) => {
																		return sort(v1, v2);
																	},
																},
																{
																	field: 'reason',
																	headerName: _('wifih-metrics-overview-reboot-reason-reboot-reason'),
																	editable: true,
																	headerAlign: 'center',
																	align: 'center',
																	renderCell: (params: any) => {
																		return params.row.reason
																	}
																},
																{
																	field: 'source',
																	headerName: _('wifih-metrics-overview-reboot-reason-reboot-source'),
																	editable: true,
																	headerAlign: 'center',
																	align: 'center',
																	renderCell: (params: any) => {
																		return params.row.source
																	}
																},
																{
																	field: 'selfHealingSource',
																	headerName: _('wifih-metrics-overview-reboot-reason-self-healing-source'),
																	editable: true,
																	headerAlign: 'center',
																	align: 'center',
																	renderCell: (params: any) => {
																		return params.row.selfHealingSource
																	}
																},
															]}
															rows={
																this.state.rebootReasons.map((row: any, index: number) => {
																	return { ...row, id: index }
																})
															}
														/>
													</div>
												)
											:
											(
												<p className="no-data">{E5UtilI18n._(E5StoreH.Ins().metsysinfo.loading ? "wificb-dashboard-notfound" : "no-data-collected")}</p>
											)
										}
									</div>

									<div className="e5wifih-metrics-nodes-summary-table">
										<div className="e5column-0">
											<div>{_("h-metric-sys-equip-summary-table")}</div>
											<div className="e5line-10">
												<div>{_("h-metric-sys-equip-summary-table-cpu")}</div>
												<BP.Popover children={<BP.Button small={true} icon="help" />}
													content={<div className="load1">Load1</div>}
													position={BP.Position.RIGHT} />
											</div>
											<div>{_("h-metric-sys-equip-summary-table-mem")}</div>
											<div>{_("h-metric-sys-equip-summary-table-temperature")}</div>
										</div>
										<div className="e5column-0">
											<div>{_("wifih-metrics-eqp-summary-table-min")}</div>
											<div>{PadNumberOrNA(summary.cpumin, dig)}</div>
											<div>{PadNumberOrNA(summary.memmin, 0)}</div>
											<div>{PadNumberOrNA(summary.tempmin, dig)}</div>
										</div>
										<div className="e5column-0">
											<div>{_("wifih-metrics-eqp-summary-table-avg")}</div>
											<div>{PadNumberOrNA(summary.cpuavg, dig)}</div>
											<div>{PadNumberOrNA(summary.memavg, 0)}</div>
											<div>{PadNumberOrNA(summary.tempavg, dig)}</div>
										</div>
										<div className="e5column-0">
											<div>{_("wifih-metrics-eqp-summary-table-max")}</div>
											<div>{PadNumberOrNA(summary.cpumax, dig)}</div>
											<div>{PadNumberOrNA(summary.memmax, 0)}</div>
											<div>{PadNumberOrNA(summary.tempmax, dig)}</div>
										</div>
									</div>
								</div>
								{E5MainConfig.GetSystemProcessEnabled() && <div className="e5line-20">
									<E5PieChart pieinfo={{
										title: _("h-metric-sys-equip-pie-procmem"), ids: pie.procmemids,
										values: pie.procmemvalues, parents: pie.procmemparents, width: piepx,
										height: piepx, labels: pie.procmemlabels, loading: false,
										valueisseconds: false, labelisincident: false
									}} withNewComponent />
									<E5PieChart pieinfo={{
										title: _("h-metric-sys-equip-pie-proccpu"), ids: pie.proccpuids,
										values: pie.proccpuvalues, parents: pie.proccpuparents, width: piepx,
										height: piepx, labels: pie.proccpulabels, loading: false,
										valueisseconds: false, labelisincident: false
									}} withNewComponent />
									<E5PieChart pieinfo={{
										title: _("h-metric-sys-equip-pie-procstatus"), ids: pie.procstatusids,
										parents: pie.procstatusparents, values: pie.procstatusvalues, width: piepx,
										height: piepx, labels: pie.procstatuslabels, loading: false,
										valueisseconds: false, labelisincident: false
									}} withNewComponent />
								</div>}
								<div className="e5compo">
									<h3>{`${_("wifih-metrics-ovrview-header")} ${equip.imei})`}</h3>
									<p className="wifih-metrics-chart-title">{_("wifih-metrics-chartsubtitle")}</p>

									{this.RenderChart(0, _("wifih-metrics-ovrview-chart-1"), true)}
									{this.RenderChart(1, _("wifih-metrics-ovrview-chart-2"), true)}
									{this.RenderChart(2, _("wifih-metrics-ovrview-chart-3"), true)}
									{this.RenderChart(3, _("wifih-metrics-ovrview-chart-4"), true)}
									{this.RenderChart(4, _("wifih-metrics-ovrview-chart-5"), true)}
									{this.RenderUptimeDataChart()}
									{this.RenderCrashEventDataChart()}
									{this.RenderRebootDataChart()}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>;
	}

	//E5
	RenderChart = (idx: number, title: string | null, withFullDayXAxis: boolean | null): JSX.Element => <div>
		<E5XYChart loading={this.props.metricinfo.loading} height={200} withFullDayXAxis title={title}
			leftsource={this.state.xydata.left[idx]} rightsource={{}} withNewComponent baseYaxis

			chartOption={
				{
					type: idx === 6 ? 'bar' : undefined,
					customBar: idx === 6,
					withoutSymbol: true,
					longLegendList: [1, 4].includes(idx),
					selected: idx === 1 ? ['hg6d', 'syslog-ng', 'mesh-agent', 'voiceManager', 'data-collector',]
						: idx === 4 ? ['mesh-agent', 'hg6d', 'voiceManager', 'dhcpd', 'local_bus_legacy',] : null
				}} />
	</div>;

	//E5
	RenderUptimeDataChart(): JSX.Element {
		return (
			<div className="e5hactivity-box">
				<span className="e5hactivity-graph-title">{E5UtilI18n._('wifih-metrics-ovrview-chart-6')} {this.props.metricinfo.loading ? <Spinner className="e5spinwait" size={15} /> : null}</span>

				<ReactECharts option={uptimeChartOptions(this.state.upTimeEvent)} />
			</div>
		);
	}

	//E5
	RenderCrashEventDataChart(): JSX.Element {
		return (
			<div className="e5hactivity-box">
				<span className="e5hactivity-graph-title">{E5UtilI18n._('h-metric-sys-equip-crash-event-title')} {this.props.metricinfo.loading ? <Spinner className="e5spinwait" size={15} /> : null}</span>

				<ReactECharts option={crashEventChartOptions(this.state.crashEvent)} />
			</div>
		);
	}

	//E5
	RenderRebootDataChart(): JSX.Element {
		return (
			<div className="e5hactivity-box">
				<span className="e5hactivity-graph-title">{E5UtilI18n._('wifih-metrics-ovrview-chart-7')} {this.props.metricinfo.loading ? <Spinner className="e5spinwait" size={15} /> : null}</span>

				<ReactECharts option={rebootChartOptions(this.state.rebootData)} />
			</div>
		);
	}

	handleChange = (e: any) => {
		const { value } = e.target
		const eqp = E5StoreH.Ins().equipinfo.equips.find(equip => equip.imei === value)
		if (eqp) {
			E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node, eqp, new E5EntHStation());
		}
	}
	//E5
	BuildOptions = (): JSX.Element[] => {
		let opts: JSX.Element[] = [<option value="" key={-1} id="" />], idx: number;
		for (idx = 0; idx < E5EntHMetSys.fields.length; idx++) {
			let { name, unit } = E5EntHMetSys.fields[idx];
			if ((["reboot", "uptime"].includes(name) && !E5MainConfig.GetSystemRebootEnabled()) ||
				(name === "temperature" && !E5MainConfig.GetSystemTemperatureEnabled()) ||
				(name === "cpuload" && !E5MainConfig.GetSystemCpuEnabled()) ||
				(name === "memory" && !E5MainConfig.GetSystemMemoryEnabled()) ||
				(["crashevent", "processmemusage", "processcputime"].includes(name) &&
					!E5MainConfig.GetSystemProcessEnabled()) ||
				(name.startsWith("flash") && !E5MainConfig.GetSystemFlashEnabled()))
				continue;
			if (idx === 0) opts.push(<option disabled>-- {E5UtilI18n._("system")} --</option>);
			if (idx === 5) opts.push(<option disabled>-- {E5UtilI18n._("h-metric-sys-equip-proc")} --</option>);
			if (idx === 8) opts.push(<option disabled>-- {E5UtilI18n._("h-metric-sys-equip-flash")} --</option>);
			if (unit !== "") unit = " [" + unit + "]";
			opts.push(<option value={name} key={idx} id={"" + idx}>
				{E5UtilI18n._("h-metric-sys-field-" + name) + unit}
			</option>);
		}
		return opts;
	};

	// ---------------- EVENTS ----------------

	//E5
	ChangeField = (event: React.ChangeEvent<HTMLSelectElement>, idx: number, side: "left" | "right"): void => {
		let st: Partial<E5HMetricSysEquipState> = {
			leftsource: JSON.parse(JSON.stringify(this.state.leftsource)),
			leftfield: JSON.parse(JSON.stringify(this.state.leftfield)),
			rightsource: JSON.parse(JSON.stringify(this.state.rightsource)),
			rightfield: JSON.parse(JSON.stringify(this.state.rightfield))
		};

		let { options, selectedIndex, value } = event.currentTarget, opt: HTMLOptionElement = options[selectedIndex];
		let selectedfield: E5HMetSysField | null = null;
		if (opt.id !== "") selectedfield = E5EntHMetSys.fields[parseInt(opt.id)];

		if (st.leftsource !== undefined && st.rightsource !== undefined)
			if (side === "left") st.leftsource[idx] = value;
			else st.rightsource[idx] = value;

		if (st.leftfield !== undefined && st.rightfield !== undefined)
			if (side === "left") st.leftfield[idx] = selectedfield;
			else st.rightfield[idx] = selectedfield;

		this.setState(st as E5HMetricSysEquipState, () => {
			this.BuildGraphData();
			this.BuildRebootChartData();
			this.BuildUptimeChartData();
			this.BuildCrashEventChartData();
		});
	};

	// ---------------- UTILS ----------------

	//E5
	BuildPieData(): E5HMetSysPieData {
		let idx: number, key: string, cnt: number, udf: undefined = undefined;

		let rebootids: string[] = [], rebootparents: string[] = [], rebootvalues: number[] = [];
		let rebootlabels: string[] = [], procmemids: string[] = [], procmemparents: string[] = [];
		let procmemvalues: number[] = [], procmemlabels: string[] = [], proccpuids: string[] = [];
		let proccpuparents: string[] = [], proccpuvalues: number[] = [], proccpulabels: string[] = [];
		let procstatusids: string[] = [], procstatusparents: string[] = [], procstatusvalues: number[] = [];
		let procstatuslabels: string[] = [];

		if (this.props.selectedinfo.type !== E5NetElementType.none) {
			rebootids = ["Reboot"];
			rebootparents = [""];
			rebootvalues = [0];
			rebootlabels = ["Reboot"];

			procmemids = ["Proc"];
			procmemparents = [""];
			procmemvalues = [0];
			procmemlabels = [E5UtilI18n._("h-metric-sys-equip-pie-procs")];

			proccpuids = ["Proc"];
			proccpuparents = [""];
			proccpuvalues = [0];
			proccpulabels = [E5UtilI18n._("h-metric-sys-equip-pie-procs")];

			procstatusids = ["Proc"];
			procstatusparents = [""];
			procstatusvalues = [0];
			procstatuslabels = [E5UtilI18n._("h-metric-sys-equip-pie-procs")];

			let met: E5EntHMetSysProc, kpi: E5EntHKpiSysReb | E5EntHKpiSysProcStatus;
			let memmap: Map<string, number> = new Map(), memusage: number | undefined;
			let cpumap: Map<string, number> = new Map(), cputime: number | undefined;

			for (idx = 0; idx < this.props.metricinfo.rebootkpi.length; idx++) {
				kpi = this.props.metricinfo.rebootkpi[idx];
				if (kpi.imei === this.props.selectedinfo.id && kpi.reasonmap !== null && kpi.reasonmap !== udf)
					for ([key, cnt] of kpi.reasonmap) {
						rebootids.push(key);
						rebootparents.push("Reboot");
						rebootvalues.push(cnt);
						rebootlabels.push(key);
					}
			}

			for (idx = 0; idx < this.props.metricinfo.process.length; idx++) {
				met = this.props.metricinfo.process[idx];
				if (met.imei === this.props.selectedinfo.id) {
					memusage = memmap.get(met.name);
					if (memusage === undefined) memusage = 0
					memusage += met.memusage;
					memmap.set(met.name, memusage);

					cputime = cpumap.get(met.name);
					if (cputime === undefined) cputime = 0
					cputime += met.cputime;
					cpumap.set(met.name, cputime);
				}
			}

			for ([key, memusage] of memmap) {
				procmemids.push(key === "" ? "N/A" : key);
				procmemparents.push("Proc");
				procmemvalues.push(memusage);
				procmemlabels.push(key === "" ? "N/A" : key);
			}

			for ([key, cputime] of cpumap) {
				proccpuids.push(key === "" ? "N/A" : key);
				proccpuparents.push("Proc");
				proccpuvalues.push(cputime);
				proccpulabels.push(key === "" ? "N/A" : key);
			}

			for (idx = 0; idx < this.props.metricinfo.procstatus.length; idx++) {
				kpi = this.props.metricinfo.procstatus[idx];
				if (kpi.imei === this.props.selectedinfo.id && kpi.statusmap !== null && kpi.statusmap !== udf)
					for ([key, cnt] of kpi.statusmap) {
						procstatusids.push(key);
						procstatusparents.push("Proc");
						procstatusvalues.push(cnt);
						procstatuslabels.push(key);
					}
			}
		}

		return {
			rebootids, rebootparents, rebootvalues, rebootlabels, procmemids, procmemparents, procmemvalues,
			procmemlabels, proccpuids, proccpuparents, proccpuvalues, proccpulabels, procstatusids, procstatusparents,
			procstatusvalues, procstatuslabels
		};
	}

	//E5
	BuildSummaryData(): E5HMetSysSummaryData {
		let met: E5EntHMetSysCpuMem | E5EntHMetSysTemp | E5EntHMetSysFlash | E5EntHMetSysReb;
		let cntcpu: number = 0, cntmem: number = 0, cnttemp: number = 0;
		let flashavailsum: number = 0, flashtotalsum: number = 0;
		let idx: number, udf: undefined = undefined;

		let cpumin: number | undefined = udf, cpuavg: number | undefined = udf, cpumax: number | undefined = udf;
		let memmin: number | undefined = udf, memavg: number | undefined = udf, memmax: number | undefined = udf;
		let tempmin: number | undefined = udf, tempavg: number | undefined = udf, tempmax: number | undefined = udf;
		let flashtot: number | undefined = udf, rebootcnt: number | undefined;

		for (idx = 0; idx < this.props.metricinfo.cpumem.length; idx++) {
			met = this.props.metricinfo.cpumem[idx];
			if (this.props.selectedinfo.id !== met.imei) continue;

			if (met.load1 !== udf) {
				if (cpumin === udf || cpumin > met.load1) cpumin = met.load1;
				if (met.duration > 0) {
					if (cpuavg === udf) cpuavg = 0;
					cpuavg += met.load1 * met.duration;
					cntcpu += met.duration;
				}
				if (cpumax === udf || cpumax < met.load1) cpumax = met.load1;
			}

			if (met.memtotal !== udf && met.memfree !== udf) {
				let memused: number = E5Text.ScoreToPercent(1 - (met.memfree / met.memtotal));
				if (memmin === udf || memmin > memused) memmin = memused;
				if (met.duration > 0) {
					if (memavg === udf) memavg = 0;
					memavg += memused * met.duration;
					cntmem += met.duration;
				}
				if (memmax === udf || memmax < memused) memmax = memused;
			}
		}

		for (idx = 0; idx < this.props.metricinfo.temperature.length; idx++) {
			met = this.props.metricinfo.temperature[idx];
			if (this.props.selectedinfo.id !== met.imei) continue;

			if (met.tempval !== udf) {
				if (tempmin === udf || tempmin > met.tempval) tempmin = met.tempval;
				if (met.duration > 0) {
					if (tempavg === udf) tempavg = 0;
					tempavg += met.tempval * met.duration;
					cnttemp += met.duration;
				}
				if (tempmax === udf || tempmax < met.tempval) tempmax = met.tempval;
			}
		}

		for (idx = 0; idx < this.props.metricinfo.flash.length; idx++) {
			met = this.props.metricinfo.flash[idx];
			if (this.props.selectedinfo.id !== met.imei) continue;

			if (met.totalerase === udf || met.availerase === udf) continue;
			flashtotalsum += met.totalerase;
			flashavailsum += met.availerase;
		}

		if (cntcpu > 0 && cpuavg !== udf) cpuavg = E5Text.RoundUp(cpuavg / cntcpu);
		if (cntmem > 0 && memavg !== udf) memavg = E5Text.RoundUp(memavg / cntmem);
		if (cnttemp > 0 && tempavg !== udf) tempavg = E5Text.RoundUp(tempavg / cnttemp);
		if (flashtotalsum !== 0) flashtot = E5Text.RoundUp(flashavailsum / flashtotalsum);
		rebootcnt = this.props.metricinfo.rebootmet.filter(met => this.props.selectedinfo.id === met.imei).length;

		return { cpumin, cpuavg, cpumax, memmin, memavg, memmax, tempmin, tempavg, tempmax, flashtot, rebootcnt };
	}

	//E5
	BuildGraphData(): void {
		let content: any = document.getElementsByClassName("e5wifih-metrics-content");
		if (content !== undefined && content !== null && content.length > 0) {
			let inner: any = document.getElementsByClassName("e5wifih-metrics-leftcolumn");
			if (inner !== undefined && inner !== null && inner.length > 0) {
				let innerheight: number = content[0].clientHeight - 10;
				inner[0].style.height = innerheight + "px";
				if (this.props.equipinfo.equips.length * 47 > innerheight)
					inner[0].className = "e5wifih-metrics-leftcolumn scroll-less-wide";
				else inner[0].className = "e5wifih-metrics-leftcolumn";
			}

			inner = document.getElementsByClassName("e5metrics-scrollable");
			if (inner !== undefined && inner !== null && inner.length > 0)
				inner[0].style.height = (content[0].clientHeight - 73) + "px";
		}

		let sides: string[] = ["left", "right"];
		let { selectedinfo, metricinfo } = this.props, xydata: any = { left: [], right: [] };
		let udf: undefined = undefined;

		for (let idx = 0; idx < 7; idx++) {
			xydata.left.push({ numdatas: udf, categdata: udf });
			xydata.right.push({ numdatas: udf, categdata: udf });
		}

		if (selectedinfo.type === E5NetElementType.node)
			for (let side of sides) {
				for (let idx = 0; idx < 7; idx++) {
					let field: E5HMetSysField | null = (this.state as any)[side + "field"][idx];
					if (field !== null) {
						let { curveper, name, categ, addholes } = field;
						let numdatas: E5MetricNumData[] = [];
						if (name === 'crashevent') { curveper = 'procname' }
						if (curveper !== udf) {
							xydata[side][idx].options = { stacked: true };
							let metlist: E5EntHMetSys[] = [];
							let gfieldname: string = "";
							switch (name) {
								case "processmemusage":
									metlist = metricinfo.process;
									gfieldname = "memusage";
									break;
								case 'crashevent':
									metlist = metricinfo.crash;
									break;
								case "processcputime":
									metlist = metricinfo.process;
									gfieldname = "cputime";
									break;
								case "flashtotalerase":
									metlist = metricinfo.flash;
									gfieldname = "totalerase";
									break;
								case "flashavailerase":
									metlist = metricinfo.flash;
									gfieldname = "availerase";
									break;
								case "flashbadpebcnt":
									metlist = metricinfo.flash;
									gfieldname = "badpebcnt";
									break;
								case "flashmaxerasecnt":
									metlist = metricinfo.flash;
									gfieldname = "maxerasecnt";
									break;
								case "flashvolumestrue":
									metlist = metricinfo.flash;
									gfieldname = "volumestrue";
									break;
								case "flashvolumesfalse":
									metlist = metricinfo.flash;
									gfieldname = "volumesfalse";
									break;
							}
							let curveset: Set<string> = new Set(), curvename: string;
							for (let met of metlist) {
								if (met.imei !== selectedinfo.equip.imei) continue;
								curvename = (met as any)[curveper];
								if (!curveset.has(curvename)) curveset.add(curvename);
							}
							for (curvename of curveset) {
								let numdata: E5MetricNumData = {
									xaxisdata: [], yaxisdata: [],
									datalabel: curvename.length > 20 ? curvename.substring(0, 16) + " ..." : curvename,
									markers: name === 'crashevent',
									hovertemplate: "%{y} (%{x})<extra>" + (curvename.length > 63 ?
										curvename.substring(0, 59) + " ..." : curvename) + "</extra>"
								};
								let prevmet: E5EntHMetSys | undefined = udf;
								for (let met of metlist) {
									if (met.imei !== selectedinfo.equip.imei) continue;
									if ((met as any)[curveper] === curvename) {
										let value: number = name === 'crashevent' ? 1 : (met as any)[gfieldname], prevval: number = 0;
										if (gfieldname === "cputime") value = E5Text.RoundUp(value / 3600);
										if (["totalerase", "availerase"].includes(gfieldname)) value = E5Text.RoundUp(value / (1024 * 1024));
										// process cut = prev.end < cur.start - holesizesec
										if (addholes) {
											if (prevmet !== udf && prevmet.endtime.unix() < met.starttime.unix() -
												E5MainConfig.GetHoleSizeSec()) {
												if (gfieldname !== udf) {
													prevval = (prevmet as any)[gfieldname];
													if (gfieldname === "cputime") prevval = E5Text.RoundUp(prevval / 3600);
												}
												numdata.xaxisdata.push(prevmet.endtime.format());
												numdata.yaxisdata.push(prevval);
												numdata.xaxisdata.push(prevmet.endtime.format());
												numdata.yaxisdata.push(udf);
												numdata.xaxisdata.push(met.starttime.format());
												numdata.yaxisdata.push(udf);
												numdata.xaxisdata.push(met.starttime.format());
												numdata.yaxisdata.push(value);
											}
										}
										numdata.xaxisdata.push(met.time.format());
										numdata.yaxisdata.push(value);
										prevmet = met;
									}
								}
								numdatas.push(numdata);
							}
							xydata[side][idx].numdatas = numdatas;
						} else if (categ !== true) {
							let groups: string[] = field.group ?? [""];
							for (let group of groups) {
								let numdata: E5MetricNumData = { xaxisdata: [], yaxisdata: [] };

								numdatas.push(numdata);
								numdata.datalabel = group === "" ?
									E5UtilI18n._("h-metric-sys-field-" + name) :
									E5UtilI18n._("h-metric-sys-group-" + group);

								let prevmet: E5EntHMetSys | undefined = udf;
								let gfieldname: string | undefined = udf;
								let gstaticval: number = 0;
								let metlist: E5EntHMetSys[] = [];
								let prevprocnamex: string | undefined = udf;
								let procnames: string[] = [];
								switch (name) {
									case "reboot":
										metlist = metricinfo.rebootmet;
										gfieldname = "rebootcnt";
										numdata.stairs = true;
										break;
									case "temperature":
										metlist = metricinfo.temperature;
										gfieldname = group;
										break;
									case "crashevent":
										metlist = metricinfo.crash;
										gstaticval = 1;
										xydata[side][idx].options = { yticktext: [], ytickvals: [] };
										for (let met of metlist) {
											if (met instanceof E5EntHMetSysCrash) {
												procnames.push(met.procname);
												numdata.hovertemplate = "%{x}<extra>" + met.procname + "</extra>";
											}
										}
										numdata.text = procnames;
										break;
									case "cpuload":
										metlist = metricinfo.cpumem;
										gfieldname = group;
										break;
									case "memory":
										metlist = metricinfo.cpumem;
										gfieldname = group;
										break;
									case "uptime":
										metlist = metricinfo.cpumem;
										gfieldname = "uptime";
										break;
								}
								for (let met of metlist) {
									if (met.imei !== selectedinfo.equip.imei) continue;

									let val: number = gstaticval, prevval: number = gstaticval;
									if (gfieldname !== udf) {
										val = (met as any)[gfieldname];
										if (gfieldname === "uptime") val = E5Text.RoundUp(val / 3600);
									}
									// process cut = prev.end < cur.start - holesizesec
									if (addholes) {
										if (prevmet !== udf && prevmet.endtime.unix() < met.starttime.unix() -
											E5MainConfig.GetHoleSizeSec()) {
											if (gfieldname !== udf) {
												prevval = (prevmet as any)[gfieldname];
												if (gfieldname === "uptime") prevval = E5Text.RoundUp(prevval / 3600);
											}
											numdata.xaxisdata.push(prevmet.endtime.format());
											numdata.yaxisdata.push(prevval);
											numdata.xaxisdata.push(prevmet.endtime.format());
											numdata.yaxisdata.push(0);
											numdata.xaxisdata.push(met.starttime.format());
											numdata.yaxisdata.push(0);
											numdata.xaxisdata.push(met.starttime.format());
											numdata.yaxisdata.push(val);
										}
									}

									let allprocnamex: string | undefined = udf, timestr: string = met.time.format();
									if (met instanceof E5EntHMetSysCrash) {
										allprocnamex = met.procname;
										if (prevmet?.time.format() === timestr)
											allprocnamex = prevprocnamex + ", " + allprocnamex;
										numdata.xaxisdata.push(timestr);
										numdata.yaxisdata.push(udf);
										numdata.xaxisdata.push(timestr);
										numdata.yaxisdata.push(0);
										numdata.xaxisdata.push(timestr);
										numdata.yaxisdata.push(val);
										numdata.xaxisdata.push(timestr);
										numdata.yaxisdata.push(0);
										numdata.xaxisdata.push(timestr);
										numdata.yaxisdata.push(udf);
										procnames.push("", "", "", "", allprocnamex);
									} else {
										numdata.xaxisdata.push(timestr);
										numdata.yaxisdata.push(val);
									}

									prevmet = met;
									prevprocnamex = allprocnamex;
								}
							}
							xydata[side][idx].numdatas = numdatas;
						}
					}
				}
			}
		this.setState({ xydata });
		this.BuildUptimeChartData();
		this.BuildCrashEventChartData();
	}

	//E5
	RebootReasonsTableData(): void {
		const currentEquipment = E5StoreH.Ins().selectedinfo.equip.imei;
		const filteredRebootData = this.props.metricinfo.rebootmet.filter(met => met.imei === currentEquipment);

		const rebootReasons = filteredRebootData.map(met => {
			const parsedTime = met.time.format("DD/MM/YYYY HH:mm");
			const reason = met.reason ?? '-';
			const source = met.source ?? '-';
			let selfHealingSource = '-';

			if (source === 'SelfHealing') {
				selfHealingSource = met.selfHealingSource
			}

			return {
				time: parsedTime,
				reason,
				source,
				selfHealingSource
			}
		});

		this.setState({ rebootReasons });
	}

	//E5
	BuildRebootChartData(): void {
		const currentEquipment = E5StoreH.Ins().selectedinfo.equip.imei;
		const softwareVersion = E5StoreH.Ins().equipinfo.equips.find(equip => equip.imei === currentEquipment)?.softwarev;

		const filteredRebootData = this.props.metricinfo.rebootmet.filter(met => met.imei === currentEquipment);
		const rebootData = filteredRebootData.map(met => {
			const tooltipValues: {[key: string]: any} = {};
			let isFwUpdate = false;
			const parsedTime = met.time.format("DD/MM/YYYY HH:mm");

			tooltipValues['h-metric-sys-equip-reboot-event'] = parsedTime;

			if (met.reason) {
				tooltipValues['h-metric-sys-equip-reboot-reason'] = met.reason;
			}

			if (met.source) {
				tooltipValues['h-metric-sys-equip-reboot-source'] = met.source;

				if (met.source === 'SelfHealing') {
					tooltipValues['h-metric-sys-equip-self-healing-source'] = met.selfHealingSource;
				}

				if (met.source === 'FU' || met.source === 'FU TR69') {
					isFwUpdate = true;
				}
			}

			if (softwareVersion) {
				tooltipValues['h-metric-sys-equip-software-version'] = softwareVersion;
			}

			return {
				yaxis: met.rebootcnt,
				xaxis: met.time.format('YYYY-MM-DD HH:mm'),
				isFwUpdate,
				tooltipValues
			}
		});

		this.setState({ rebootData });
	}

	//E5
	BuildUptimeChartData(): void {
		const currentEquipment = E5StoreH.Ins().selectedinfo.equip.imei;
		let metlist: E5EntHMetSys[] = this.props.metricinfo.cpumem || [];
		const upTimeEvent = metlist.filter(met => met.imei === currentEquipment).map(met => {
			const isFwUpdate = false;
			const tooltipValues: {[key: string]: any} = {};
			if (met.imei !== currentEquipment) return;
			let val = (met as any)["uptime"];
			val = E5Text.RoundUp(val / 3600);

			return {
				yaxis: val,
				xaxis: met.time.format('YYYY-MM-DD HH:mm'),
				isFwUpdate,
				tooltipValues
			}
		});

		this.setState({ upTimeEvent });
	}

	BuildCrashEventChartData(): void {
		const currentEquipment = E5StoreH.Ins().selectedinfo.equip.imei;
		let crashList: E5EntHIndSysCrash[] = this.props.indicsysinfo.crash || [];
		const crashEvent = crashList.filter(crash => crash.imei === currentEquipment).map(met => {
			const seriesData = met.crashdetail?.map(crash => {
				const tooltipValues: {[key: string]: any} = {};
				const parsedTime = crash.time.format("HH:mm");

				tooltipValues['h-metric-sys-equip-crash-event'] = parsedTime;
	
				if (crash.procname) {
					tooltipValues['h-metric-sys-equip-process-name'] = crash.procname;
				}
	
				if (crash.procid) {
					tooltipValues['h-metric-sys-equip-process-id'] = crash.procid;
				}

				if (crash.softwarev) {
					tooltipValues['h-metric-sys-equip-software-version'] = crash.softwarev;
				}

				if (crash.coreDumpFileName) {
					tooltipValues['h-metric-sys-equip-core-dump-name'] = crash.coreDumpFileName;
				}

				if (crash.coreDumpSize) {
					tooltipValues['h-metric-sys-equip-core-dump-size'] = crash.coreDumpSize;
				}
	
				return {
					yaxis: crash.coreDumpSize,
					xaxis: crash.time.format('YYYY-MM-DD HH:mm'),
					tooltipValues
				}
			});

			return {
				seriesData,
			}
		});

		this.setState({ crashEvent });
	}
});