import React from "react";
import moment from "moment";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5EntHStationRssi} from "../../../entity/household/topo/E5EntHStationRssi";
import {E5EntHTopology} from "../../../entity/household/topo/E5EntHTopology";
import {E5EntHStation} from "../../../entity/household/topo/E5EntHStation";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5TestTopoGraph} from "./E5TestTopoGraph";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestHeatmap.css";
import E5HHeatmap from "../../household/connectivity/E5HHeatmap";

//E5
interface E5TestHeatmapState {
	nbsta: number;
	worst: number;
	best: number;
}

//E5
interface E5TestHeatmapProps {}

//E5
export const E5TestHeatmap = observer(class E5TestHeatmap extends React.PureComponent
	<E5TestHeatmapProps, E5TestHeatmapState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestHeatmapProps, state: E5TestHeatmapState) {
		super(props, state);
		this.state = {nbsta: 9, worst: -90, best: -50};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let stations: E5EntHStation[] = E5TestHeatmap.GetStations(this.state.nbsta),
			rssipersta: E5EntHStationRssi[] = this.GetRssiPerSta(stations);

		return <div className="e5testpage e5test-heatmap e5fullheight e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-heatmap-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Number of stations">
					<div className="e5line-20">
						<BP.Button disabled={this.state.nbsta < 2}
								   text="-" onClick={() => this.UpdateNbDevicesBy(-1)}/>
						<BP.Button text="+" onClick={() => this.UpdateNbDevicesBy(+1)}/>
						<BP.H4>{this.state.nbsta}</BP.H4>
					</div>
				</BP.Callout>
				<BP.Callout title="Worst RSSI">
					<div className="e5line-20">
						<BP.Button text="-" onClick={() => this.UpdateWorstBy(-10)}/>
						<BP.Button disabled={this.state.worst >= this.state.best - 10}
								   text="+" onClick={() => this.UpdateWorstBy(+10)}/>
						<BP.H4>{this.state.worst}</BP.H4>
					</div>
				</BP.Callout>
				<BP.Callout title="Best RSSI">
					<div className="e5line-20">
						<BP.Button disabled={this.state.best <= this.state.worst + 10}
								   text="-" onClick={() => this.UpdateBestBy(-10)}/>
						<BP.Button text="+" onClick={() => this.UpdateBestBy(+10)}/>
						<BP.H4>{this.state.best}</BP.H4>
					</div>
				</BP.Callout>
			</div>
			<E5HHeatmap stationinfo={{stations, stationmap: new Map()}}
						connectivityinfo={{loading: false, topologies: [new E5EntHTopology()], rssipersta}}
						/>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	UpdateNbDevicesBy = (amount: number): void => {
		this.setState({nbsta: amount < 0 && this.state.nbsta < 2 ? 1 : this.state.nbsta + amount});
	};

	//E5
	UpdateWorstBy = (amount: number): void => {
		let lim: number = this.state.best - 10;
		this.setState(
			{worst: amount > 0 && this.state.worst >= lim ? lim : this.state.worst + amount});
	};

	//E5
	UpdateBestBy = (amount: number): void => {
		let lim: number = this.state.worst + 10;
		this.setState({best: amount < 0 && this.state.best <= lim ? lim : this.state.best + amount});
	};

	// ---------------- UTILS ----------------

	//E5
	static GetStations: (nbsta: number) => E5EntHStation[] = (nbsta: number): E5EntHStation[] => {
		let stations: E5EntHStation[] = [],
			owners: string[] = ["Teddy", "Céleste", "Émile", "Maggie", "Marianne", "Piotr", "Laure"],
			types: string[] = ["phone", "tv", "tablette", "montre", "frigo", "enceintes", "console"];
		for (let idx = 0; idx < nbsta; idx++) stations.push(new E5EntHStation({
			mac_address: E5TestTopoGraph.GetFakeMacAddress(),
			hostname: types[Math.floor(Math.random() * types.length)] + "-de-"
				+ owners[Math.floor(Math.random() * owners.length)]
		}));
		return stations;
	};

	//E5
	GetRssiPerSta = (devices: E5EntHStation[]): E5EntHStationRssi[] => {
		let rssipersta: E5EntHStationRssi[] = [];
		for (let idx = 0; idx < devices.length; idx++) {
			let rssi_per_hour: any[] = [];
			for (let idx2 = 0; idx2 < 11; idx2++) rssi_per_hour.push({
				rssi: Math.random() * (this.state.best - this.state.worst) + this.state.worst,
				time: moment().startOf("day").add(idx2 * 2, "hours").unix()
			});
			rssipersta.push(new E5EntHStationRssi({child_id: devices[idx].macaddress, rssi_per_hour}));
		}
		return rssipersta;
	};
});
