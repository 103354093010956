export const messages: any = {
	"error-rdm-403-401": "Erreur de connexion avec le module RDM",
	// bands
	"bandunknown": "Inconnue",
	"MULTIPLE": "Multiples",
	"2_4GHZ": "2,4GHz",
	"5GHZ": "5GHz",
	"6GHZ": "6GHz",
	"eth": "ETH",
	"ETH": "ETH",
	"ETH100": "ETH100",
	"ETH1000": "ETH1000",
	// terms
	"system": "Système",
	"system-cpu": "CPU",
	"system-mem": "Mémoire",
	"system-reb": "Reboot",
	"system-temp": "Température",
	"system-proc": "Processus",
	"system-flash": "Flash",
	// misc
	"hours": "heures",
	// global
	"processingerror": "Erreur de traitement",
	"formaterror": "Erreur de format",
	"conflicterror": "Erreur de conflit",
	"servernotresponding": "Serveur injoignable",
	"dateformat": "DD/MM/YYYY",
	"dayshort": "j",
	"netelementtype-device": "station",
	"netelementtype-node": "équipement",
	"nodetype-ext": "Extendeur",
	"nodetype-gw": "Passerelle",
	"nodetype-stb": "Décodeur TV",
	"ok": "OK",
	"cancel": "Annuler",
	"delete": "Supprimer",
	"save": "Enregistrer les modifications",
	"update": "Modifier",
	"add": "Ajouter",
	"all": "Tous",
	"none": "Aucun",
	"operation-forbidden": "Opération non permise",
	"total": "Total",
	"piefromparent": "de",
	"download": "Télécharger",
	"delfilters": "Supprimer tous les filtres",
	"nextpage": "page suivante",
	"prevpage": "page précédente",
	"firstpage": "première page",
	"lastpage": "dernière page",
	"id": "Id",
	"no-data-collected": "Aucune donnée collectée",
	// main
	"mainwifititle": "COSMOS",
	// mainheader
	"mainheader-language": "Langue",
	"mainheader-welcome": "Bienvenue, ",
	"mainheader-disconnect": "Déconnecter",
	"mainheader-goen": "Change to English",
	"mainheader-my-profile": "Mon profil",
	// login
	"login-welcome": "Bienvenue !",
	"login-welcome-text": "Connectez-vous pour accéder sur le portail COSMOS",
	"login-title": "Identification",
	"login-username": "Identifiant",
	"login-password": "Password",
	"login-ok": "Se connecter",
	"login-baduser": "Identification incorrecte",
	"login-expired": "Votre session a expiré, merci de vous reconnecter",
	"login-forbidden-expired": "Connexion impossible, le compte est expiré",
	"login-forbidden-locked": "Connexion impossible, le compte est bloqué",
	"login-forbidden-Forbidden": "Connexion impossible, la session a expiré",
	"login-forbidden-disabled": "Connexion impossible, le compte est désactivé",
	"login-forbidden-unknown": "Connexion impossible, raison indéterminée",
	// mainmenu
	"mainmenu-collapsetooltip": "Réduire le menu",
	"mainmenu-expandtooltip": "Ouvrir le menu",
	"mainmenu-copyright": "© Sagemcom",
	"mainmenu-frontversion": "Portail version #0",
	"mainmenu-cmsversion": "COSMOS release #0",
	"mainmenu-rc1.1-cmswifipage": "Page Wi-Fi RC1.1",
	"mainmenu-customer-base": "Parc",
	"mainmenu-cb-dashboard": "Tableau de bord",
	"mainmenu-cb-populations": "Populations",
	"mainmenu-household": "Foyer",
	"mainmenu-h-dashboard": "Tableau de bord",
	"mainmenu-h-incidents": "Incidents",
	"mainmenu-h-connectivity": "Connectivité",
	"mainmenu-h-eventlogs": "Evénements",
	"mainmenu-h-metrics": "Métriques",
	"mainmenu-management": "Gestion",
	"mainmenu-m-users": "Utilisateurs",
	"mainmenu-m-rdm": "Équipements",
	"mainmenu-m-acl": "Groupes ACL",
	// mainmenu-test
	"mainmenu-test": "Tests",
	"mainmenu-test-xy-chart": "T~ graphique XY",
	"mainmenu-test-xy-chart-long": "Page de test pour le graphique XY (cartésien)",
	"mainmenu-test-pie": "T~ camembert",
	"mainmenu-test-pie-long": "Page de test pour le camembert",
	"mainmenu-test-radar": "T~ radar (étoile)",
	"mainmenu-test-radar-long": "Page de test pour le radar (graphe en étoile)",
	"mainmenu-test-angular-gauge": "T~ jauge angulaire",
	"mainmenu-test-angular-gauge-long": "Page de test pour la jauge angulaire",
	"mainmenu-test-bullet-gauge": "T~ jauge cartouche",
	"mainmenu-test-bullet-gauge-long": "Page de test pour la jauge horizontale plate (cartouche)",
	"mainmenu-test-cb-indics-long": "Page de test pour les indicateurs du parc",
	"mainmenu-test-populations": "T~ populations",
	"mainmenu-test-populations-long": "Page de test pour les populations",
	"mainmenu-test-h-indics": "T~ indics foyer",
	"mainmenu-test-h-indics-long": "Page de test pour les indicateurs du foyer",
	"mainmenu-test-incids-recoms": "T~ incids recoms",
	"mainmenu-test-incids-recoms-long": "Page de test pour les incidents et recommandations",
	"mainmenu-test-topo-items": "T~ éléments topo",
	"mainmenu-test-topo-items-long": "Page de test pour les éléments de topologie",
	"mainmenu-test-topo-graph": "T~ graphe topo",
	"mainmenu-test-topo-graph-long": "Page de test pour le graphe de topologie",
	"mainmenu-test-connections": "T~ connexions",
	"mainmenu-test-connections-long": "Page de test pour le tableau des connexions",
	"mainmenu-test-heatmap": "T~ carte chaleur",
	"mainmenu-test-heatmap-long": "Page de test pour la carte de chaleur",
	"mainmenu-test-admin-users": "T~ gestion utils",
	"mainmenu-test-admin-users-long": "Page de test pour la gestion des utilisateurs",
	// side menu
	"sidemenu-how-works": "Comment fonctionne COSMOS ?",
	// pagetitle
	"pagetitle-cb-dashboard": "Indicateurs de performance de la population",
	"pagetitle-cb-populations": "Gestion des populations",
	"pagetitle-h-dashboard": "Vue d'ensemble du foyer",
	"pagetitle-h-incidents": "Incidents et recommandations du foyer",
	"pagetitle-h-connectivity": "Connectivité réseau du foyer",
	"pagetitle-h-eventlogs": "Journaux d'événements Wi-Fi",
	"pagetitle-h-metrics": "Métriques du foyer",
	"pagetitle-h-stations": "Stations domestiques",
	"pagetitle-management-users": "Gestion des comptes utilisateurs",
	"pagetitle-management-rdm": "Gestion à distance des équipements",
	"pagetitle-management-acl": "Gestion des groupes ACL",
	// search household id
	"searchni-label": "Saisir un identifiant de foyer",
	"searchni-refresh": "Rafraîchir les données de ce même identifiant pour la date choisie",
	"searchni-autocomplete": "Rechercher les identifiants de foyer finissant par cette valeur",
	"searchni-autocomplete-none": "Aucun identifiant trouvé",
	// welcomepage
	"home-title": "Accueil",
	"home-welcome": "Bienvenue sur la plateforme Sagemcom COSMOS",
	"home-how-to-work": "Comment fonctionne COSMOS ?",
	"home-data-collection": "Collecte des données",
	"home-data-collection-description": `Les données des CPE sont collectées à une fréquence d'échantillonnage élevée par l'agent COSMOS maître (par exemple, toutes les 10 secondes) et sont stockées en mémoire. Ces données sont ensuite périodiquement formatées dans un message Json et envoyées à la plateforme COSMOS à l'aide du protocole HTTPs (par exemple, toutes les 30 minutes).`,
	"home-data-ingestion-aggregation": "Ingestion et agrégation des données",
	"home-data-ingestion-aggregation-description": `Les messages Json reçus sont vérifiés par la plateforme COSMOS en fonction d'un schéma de données prédéfini. Les données valides sont ensuite agrégées en tranches de 10 minutes maximum, chaque tranche étant associée à un ensemble de mesures descriptives (par exemple, RSSI moyen, niveau de bruit maximal, charge moyenne de l'unité centrale, etc.)`,
	"home-anomaly-severity-scoring": "Analyse des anomalies et de la sévérité",
	"home-anomaly-severity-scoring-description": "Chaque lot de données est analysé en fonction d'un ensemble de règles prédéfinies afin de détecter les anomalies (par exemple, instabilité des liens, utilisation élevée de l'unité centrale, etc.) Un score de sévérité est ensuite calculé pour chaque anomalie afin d'évaluer sa gravité à l'échelle de l'ensemble sélectionné.",
	"home-health-scores": "Scores de santé",
	"home-health-scores-description": "À tout moment, la plateforme COSMOS peut évaluer l'état de santé de chaque élément du système (station, lien Wi-Fi, équipement, foyer, population, etc.), sur la base des scores de sévérité calculés pour les anomalies. Ces scores de santé permettent aux opérateurs d'évaluer le niveau de performance de ces éléments.",
	"home-incidents-recommendations": "Incidents et recommandations",
	"home-incidents-recommendations-description": "A la fin de chaque journée, la plateforme COSMOS analyse toutes les données collectées, détecte les incidents et évalue leurs scores de criticité, en fonction de leur impact et de leur occurrence. Ensuite, pour chaque incident détecté, des recommandations sont générées pour aider les opérateurs à optimiser la qualité de l'expérience.",
	"home-customer-base-kpis": "KPIs du parc",
	"home-customer-base-kpis-description": "À la fin de chaque journée, la plateforme COSMOS calcule les indicateurs clés de performance (KPI) de chaque population définie. Ces KPI sont affichés sur un tableau de bord complet pour aider les opérateurs à suivre et à gérer la qualité de l'expérience à l'échelle de leur base de clients.",
	'home-cosmos': 'Solution Cloud Opérée pour la Surveillance, l\'Optimisation et les Services.',
	'home-cosmos-description-1': `Une solution de big data dans le cloud qui aide les fournisseurs de services et les équipes de support client à automatiser la surveillance, le diagnostic, l'optimisation et la gestion des équipements situés chez les clients (CPE) et des services résidentiels (par exemple : WiFi, WAN, Voix, etc.).`,
	'home-cosmos-description-2': 'Les métriques collectées des CPE sont traitées à l\'aide d\'algorithmes d\'IA pour détecter les incidents et générer des recommandations visant à améliorer la qualité globale de l\'expérience (QoE).',
	'home-connect-cpes': 'Connecter vos CPE',
	"home-cpes-platform": "Connectez vos CPE à la plateforme COSMOS en activant les collecteurs de données (via TR-069) et commencez à surveiller votre",
	"home-customer-base" : "Parc",
	"home-household": "Foyers",
	"home-Metrics": "Métriques",
	"home-monitor-wiFi-networks": "Surveiller les réseaux Wi-Fi domestiques",
	"home-households-using": "Surveillez vos foyers à l'aide de la page",
	"home-dashboard": "Tableau de bord",
	"home-page-health-score": ", en termes de score de santé, de stations et de nœuds connectés, des KPIs Wi-Fi des foyers et des",
	"home-comma": ", vos",
	"home-and": "et vos",
	"home-customer-base-analytics": "Analyse du Parc client",
	"home-monitor-deployment": "Contrôlez le déploiement et la qualité d'expérience de vos réseaux Wi-Fi domestiques à l'échelle de votre",
	"home-target-populations": "ou de vos populations cibles.",
	"home-manage-users-roles": "Gérer les utilisateurs et les rôles",
	"home-create-manage": "Créer et gérer la liste des",
	"home-user": "utilisateurs",
	"home-allowed-connect": "autorisés à se connecter à la plateforme COSMOS et à accéder à ses fonctionnalités. La liste des rôles disponibles est disponible ici :",
	"home-act-upon-incidents": "Agir en cas d'incident",
	"home-optimize-quality": "Optimisez la qualité d'expérience de vos réseaux Wi-Fi domestiques en analysant les",
	"home-incidents": "Incidents",
	"home-proactively-acting": "détectés et en agissant de manière proactive sur les",
	"home-recommendations": "Recommandations",
	"home-générées": " générées.",
	"home-manage-populations": "Gérer vos populations",
	"home-finitune-your": "Affinez l'analyse de votre",
	"home-analytics-target": "en créant et en gérant une liste de",
	"home-populations": "populations",
	"home-various-criteria": "cibles en fonction de divers critères.",

	// wificb-dashboard
	"wificb-dashboard-over": "Vue d'ensemble générale",
	"wificb-dashboard-wifi": "Wi-Fi",
	"wificb-dashboard-wan": "WAN",
	"wificb-dashboard-system": "Système",
	"wificb-dashboard-notfound": "Aucune donnée trouvée",
	"wificb-dashboard-from": "du",
	"wificb-dashboard-to": "au",
	"wificb-dashboard-of": "des",
	"wificb-dashboard-selectpop": "Selectionnez une population...",
	"wificb-dashboard-downloadni-cat": "Voulez-vous afficher #0 identifiants de foyer pour la catégorie \"#2\" (#1) ?",
	// cb
	"cb-health": "Score de santé",
	"cb-health-over-time": "Scores de santé au cours du temps",
	"cb-pie-inc-cat": "Criticité par categorie d'incident",
	"cb-inc-over-time": "Évolution des scores d'incidents",
	"cb-incidents-sub": "Criticité et durée moyennes des catégories d'incidents par foyer (en heures)",
	"cb-incidents-help": "Criticité et durée cumulées des incidents par catégorie par foyer et par jour, moyennées sur l'ensemble des foyers de la population.",
	"cb-dashboard-downloadni-health": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour la catégorie \"#1\" et la date \"#2\" ?",
	"cb-dashboard-downloadni-cpu": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour le processus \"#1\" et la date \"#2\" ?",
	"cb-dashboard-downloadni-mem": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour le processus \"#1\" et la date \"#2\" ?",
	"cb-dashboard-downloadni-crash": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour le processus \"#1\" et la date \"#2\" ?",
	"cb-dashboard-downloadni-incid": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour les incidents \"#2\" (#1) de la catégorie \"#3\" ?",
	"cb-dashboard-downloadni-equip": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour la catégorie \"#1\" et le type d'équipement \"#2\" ?",
	"cb-dashboard-downloadni-detail": "Voulez-vous afficher #0 identifiants de foyer (maximum) pour la catégorie \"#1\", la version de firmware \"#2\" et le modèle \"#3\"?",
	"cb-dashboard-serialnumber": "Household ID",
	"cb-dashboard-avgval-usage": "Utilisation moyenne",
	"cb-dashboard-device-activity": "Détection de l'activité des appareils",
	"cb-dashboard-device-disconnected": "Débranché",
	"cb-dashboard-device-notreceived": "Non reçu",
	"cb-dashboard-device-mbsend": "Mo envoyés et reçus",
	"cb-dashboard-device-lowactivity": "Faible activité",
	"cb-dashboard-device-highactivity": "Haute activité",
	"cb-dashboard-day": "Jour",
	"cb-dashboard-week": "Semaine",
	"cb-dashboard-imei": "Equipement impacté",
	"cb-dashboard-avgval-none": "Valeur moyenne",
	"cb-dashboard-avgval-cpu": "Moyenne de l'utilisation CPU",
	"cb-dashboard-avgval-mem": "Moyenne de l'utilisation mémoire",
	"cb-dashboard-avgval-crash": "Nombre de crash",
	"cb-dashboard-downloaderr": "Erreur de téléchargement",
	"cb-dashboard-nilist": "Identifiants de foyer",
	"cb-dashboard-topcontentswitch-true": "Mode pourcentage",
	"cb-dashboard-topcontentswitch-false": "Mode somme",
	// cb-overview
	"cb-overview-pie-softwarev": "Versions des firmwares",
	"cb-overview-pie-equips": "Types d'équipements",
	"cb-overview-pie-stations": "Types des stations",
	"cb-overview-growth-equips": "Croissance au cours de temps (équipements)",
	"cb-overview-growth-stations": "Croissance au cours de temps (stations)",
	"cb-overview-equip": "Taille des foyers au cours du temps",
	// cb-wifi
	"cb-wifi-pie-channelusage": "Utilisation des canaux",
	"cb-wifi-pie-autochan": "Utilisation des canaux automatiques",
	"cb-wifi-pie-bandstd": "Utilisation des bandes et standards",
	"cb-wifi-pie-categ-help": "Criticité cumulée des incidents par catégorie et par foyer, moyennée sur l'ensemble des foyers de la population.",
	"cb-wifi-incidents-criticality": " (criticité)",
	"cb-wifi-incidents-duration": " (durée)",
	"cb-wifi-connections-bands": "Connexions au cours du temps (bandes)",
	"cb-wifi-connections-standards": "Connexions au cours du temps (standards)",
	// wificb-wan
	"cb-wan-pie-incid": "Criticité par type de connexion et d'incident",
	"cb-wan-pie-access": "Types de connexion",
	// cb-sys
	"cb-sys-pie-equips-sub": "Par type d'équipement",
	"cb-sys-pie-details-sub": "Par modèle et version de firmware",
	"cb-sys-pie-gw-sub": "Par modèle de passerelle et version du firmware",
	"cb-sys-pie-ext-sub": "Par modèle de répéteur et version du micrologiciel",
	"cb-sys-pie-val-suffix": "équipements (somme)",
	// cb-sys-overview
	"cb-sys-overview": "Vue d'ensemble système",
	"cb-sys-overview-healths": "Scores de santé système",
	// cb-sys-cpu
	"cb-sys-cpu-pie": "Charge CPU",
	"cb-sys-cpu-gw": "Évolution de la charge CPU des passerelles",
	"cb-sys-cpu-ext": "Évolution de la charge CPU des extendeurs",
	"cb-sys-cpu-stb": "Évolution de la charge CPU des set-top boxes",
	// cb-sys-memory
	"cb-sys-memory-pie": "Utilisation de mémoire",
	"cb-sys-memory-gw": "Évolution de l'utilisation de mémoire des passerelles",
	"cb-sys-memory-ext": "Évolution de l'utilisation de mémoire des extendeurs",
	"cb-sys-memory-stb": "Évolution de l'utilisation de mémoire des set-top boxes",
	// cb-sys-reboot
	"cb-sys-reboot-pie-reason-sub": "Par raison de reboot",
	"cb-sys-reboot-piereb": "Nombre de reboots",
	"cb-sys-reboot-pieupt": "Temps de fonctionnement",
	"cb-sys-reboot-gwreb": "Évolution du nombre de reboots des passerelles",
	"cb-sys-reboot-extreb": "Évolution du nombre de reboots des extendeurs",
	"cb-sys-reboot-stbreb": "Évolution du nombre de reboots des set-top boxes",
	"cb-sys-reboot-gwupt": "Évolution du temps de fonctionnement des passerelles",
	"cb-sys-reboot-extupt": "Évolution du temps de fonctionnement des extendeurs",
	"cb-sys-reboot-stbupt": "Évolution du temps de fonctionnement des set-top boxes",
	// cb-sys-temperature
	"cb-sys-temperature-pie": "Température",
	"cb-sys-temperature-gw": "Évolution de la température des passerelles",
	"cb-sys-temperature-ext": "Évolution de la température des extendeurs",
	"cb-sys-temperature-stb": "Évolution de la température des set-top boxes",
	// cb-sys-process
	"cb-sys-process-cpuproc": "Processus les plus intensifs en CPU",
	"cb-sys-process-memproc": "Processus les plus intensifs en mémoire",
	"cb-sys-process-crashproc": "Fréquence de crashes de processus les plus élevés",
	"cb-sys-process-name": "Nom du processus",
	"cb-sys-process-cpuval": "Conso. CPU moyenne",
	"cb-sys-process-memval": "Conso. mémoire moyenne",
	"cb-sys-process-crashval": "Nombre de crashs de processus moyen",
	"cb-sys-process-statusmet": "Évolution de la fréquence des statuts de processus",
	"cb-sys-process-cpumet": "Évolution des processus les plus intensifs en CPU",
	"cb-sys-process-memmet": "Évolution des processus les plus intensifs en mémoire",
	"cb-sys-process-crashmet": "Nombre de crashes moyen de processus sur la population au cours du temps",
	// cb-sys-flash
	"cb-sys-flash-pieflusa": "Utilisation de mémoire flash",
	"cb-sys-flash-pieflcor": "Corruption de mémoire flash",
	"cb-sys-flash-gwflusa": "Évolution de l'utilisation de mémoire flash des passerelles",
	"cb-sys-flash-extflusa": "Évolution de l'utilisation de mémoire flash des extendeurs",
	"cb-sys-flash-stbflusa": "Évolution de l'utilisation de mémoire flash des set-top boxes",
	"cb-sys-flash-gwflcor": "Évolution de la corruption de mémoire flash des passerelles",
	"cb-sys-flash-extflcor": "Évolution de la corruption de mémoire flash des extendeurs",
	"cb-sys-flash-stbflcor": "Évolution de la corruption de mémoire flash des set-top boxes",
	// cb-eventlog
	"cb-eventlog-time": "Heure de l'évènement",
	"cb-eventlog-device": "Appareil IMEI",
	"cb-eventlog-type": "type d'événement",
	"cb-eventlog-args": "Clé-Valeur",
	"cb-eventlog-args-key": "Clé",
	"cb-eventlog-args-value": "Valeur",
	"cb-eventlog-access-point": "Adresse MAC du point d'accès",
	"cb-eventlog-event-name": "Nom d'évènement",
	"cb-eventlog-station-mac": "Adresse MAC de la station",
	"cb-eventlog-reason": "Raison",
	"cb-eventlog-previous-band": "Bande originaire",
	"cb-eventlog-new-band": "Bande destinataire",
	"cb-eventlog-connectivity": "Connectivité",
	"cb-eventlog-band-steering": "Band steering",
	"cb-eventlog-filter-by-eqiupment": "Filtrer par équipement",
	"cb-eventlog-network": "Réseau",
	"cb-eventlog-select-eqiupment": "Sélectionner un équipement",
	"cb-eventlog-event-time": "Heure de l'évènement",

	// wificb-pops
	"wificb-pops-nilist": "Liste des identifiants de foyer (max. #0)",
	"wificb-pops-no-netids": "Aucune donnée trouvée",
	"wificb-pops-save-name": "Enregistrer le nouveau nom",
	"wificb-pops-popup-update": "Modifier ou supprimer une population",
	"wificb-pops-popup-add": "Ajouter une population",
	"wificb-pops-crits-add": "Ajouter un critère",
	"wificb-pops-crit-integer": "Saisissez un nombre entier, par exemple : 12.",
	"wificb-pops-crit-string": "Saisissez un nom de version exact.",
	"wificb-pops-crit-option": "Sélectionnez une option.",
	"wificb-pops-crit-float": "Saisissez un score de santé, par exemple : 0.85.",
	"wificb-pops-whole-park": "Tout le parc",
	"wificb-pops-remove-park": "Retirer tout le parc",
	"wificb-pops-crits-remove": "Retirer tous les critères",
	"wificb-pops-netids-defids": "Définir les identifiants de foyer",
	"wificb-pops-unavailable": "Indisponible",
	"wificb-pops-netids-remove": "Retirer tous les identifiants de foyer",
	"wificb-pops-netids-explain": "Saisissez/collez des ids de foyer séparés par un espace, une virgule, un point-virgule ou un retour à la ligne.",
	"wificb-pops-deletetip": "Supprimer cette population après confirmation",
	"wificb-pops-duplicatetip": "Dupliquer cette population après confirmation",
	"wificb-pops-confirmdelete": "Voulez-vous vraiment supprimer cette population ? Cette opération ne peut pas être annulée.",
	"wificb-pops-confirmduplicate": "Voulez-vous vraiment dupliquer cette population ? La nouvelle s'appellera ",
	"wificb-pops-confirmmodify": "Voulez-vous vraiment modifier cette population ? Les données mises à jour seront disponibles demain.",
	"wificb-pops-duplicate": "Dupliquer",
	"wificb-pops-name": "Nom",
	"wificb-pops-populationid": "UUID",
	"wificb-pops-populationid-pop": "UUID (non modifiable)",
	"wificb-pops-aclname": "Groupe ACL",
	"wificb-pops-copynamesuffix": "Copie",
	"wificb-pops-creationdate": "Création",
	"wificb-pops-networkidcount": "Foyers",
	"wificb-pops-criterias": "Critères",
	"wificb-pops-criteria": "Critère",
	"wificb-pops-condition": "Condition",
	"wificb-pops-value": "Valeur",
	"wificb-pops-actions": "Identifiants de foyer",
	"wificb-pops-actions-display": "Afficher",
	"wificb-pops-actions-download": "Télécharger (.csv)",
	"wificb-pops-actions-user-ids": "les identifiants définis lors de la création de la population",
	"wificb-pops-actions-active-ids": "les identifiants qui étaient réellement actifs hier",
	"wificb-pops-not-found": "Population inconnue",
	"wificb-pops-updateok": "Opération réussie",
	"wificb-pops-updateok-withduplicates": "La liste des ID de réseau contient des enregistrements en double",
	"wificb-pops-update-failed": "Échec de l'enregistrement",
	"wificb-pops-get-failed": "Échec de lecture des données",
	"wificb-pops-listof": "Liste d'identifiants réseau",
	"wificb-pops-acl-ids-load": "Charger les identifiants réseau du groupe",
	"wificb-pops-acl-ids-warn": "Cela va remplacer les identifiants réseau ci-dessus.",
	"wificb-pops-acl-ids-help": "Veuillez d'abord sélectionner un groupe ACL.",
	"wificb-pops-acl-ids-fail": "Échec du chargement des identifiants réseau du groupe",
	"wificb-pops-cond-contains": "Existe",
	"wificb-pops-cond-!contains": "N'existe pas",
	"wificb-pops-filters": "Filtres",
	"wificb-pops-createstart": "Depuis",
	"wificb-pops-createend": "Jusqu'au",
	// wificb-indic
	"wificb-indic-sta": "Stations",
	"wificb-indic-sta-tip": "Nombre total de stations",
	"wificb-indic-gw": "GW",
	"wificb-indic-gw-tip": "Nombre total de passerelles (avec l'agent CMS activé)",
	"wificb-indic-ext": "EXT",
	"wificb-indic-ext-tip": "Nombre total d'extendeurs (avec l'agent CMS activé)",
	"wificb-indic-stb": "STB",
	"wificb-indic-stb-tip": "Nombre total de set-top box (avec l'agent CMS activé)",
	"wificb-indic-5g-tip": "Nombre total de connexions Wi-Fi 5GHz",
	"wificb-indic-6g-tip": "Nombre total de connexions Wi-Fi 6GHz",
	"wificb-indic-2g-tip": "Nombre total de connexions Wi-Fi 2,4GHz",
	"wificb-indic-wa": "802.11a",
	"wificb-indic-wa-tip": "Nombre total de connexions Wi-Fi 802.11a",
	"wificb-indic-wb": "802.11b",
	"wificb-indic-wb-tip": "Nombre total de connexions Wi-Fi 802.11b",
	"wificb-indic-wg": "802.11g",
	"wificb-indic-wg-tip": "Nombre total de connexions Wi-Fi 802.11g",
	"wificb-indic-wn": "802.11n",
	"wificb-indic-wn-tip": "Nombre total de connexions Wi-Fi 802.11n",
	"wificb-indic-wac": "802.11ac",
	"wificb-indic-wac-tip": "Nombre total de connexions Wi-Fi 802.11ac",
	"wificb-indic-wax": "802.11ax",
	"wificb-indic-wax-tip": "Nombre total de connexions Wi-Fi 802.11ax",
	"wificb-indic-extperh": "Avg EXT",
	"wificb-indic-stbperh": "Avg STB équipements par foyer",
	"wificb-indic-bandavg-2ghz": "Avg 2,4GHz",
	"wificb-indic-bandavg-5ghz": "Avg 5GHz",
	"wificb-indic-bandavg-6ghz": "Avg 6GHz",
	"wificb-indic-bandavg-eth": "Avg ETH",
	// customer base report
	"cbreport-button": "Rapport complet",
	"cbreport-loadall": "Charger toutes les données",
	"cbreport-loadall-wait": "Charger l'ensemble des données peut prendre plusieurs secondes.",
	"cbreport-close": "Fermer le rapport",
	"cbreport-notalldata": "Le rapport est incomplet car tous les onglets n'ont pas été ouverts.",
	// wifih-status
	"wifih-status-networkid": "id de foyer",
	"wifih-status-networkid-tip": "Identifiant du foyer",
	"wifih-status-last": "dernier message",
	"wifih-status-last-tip": "Heure d'envoi du dernier message reçu",
	"wifih-status-now": "heure d'affichage",
	"wifih-status-now-tip": "Horodatage des informations affichées",
	"wifih-status-timezone": "fuseau horaire",
	"wifih-status-timezone-tip": "Fuseau horaire du foyer",
	// wifih-dashboard
	"wifih-indic-global-notfound": "Aucune donnée trouvée pour le #0",
	"wifih-indic-wifi-notfound": "Aucune donnée trouvée pour le #0",
	"h-indic-wan-not-found": "Aucune donnée trouvée pour le #0",
	"h-indic-sys-not-found": "Aucune donnée trouvée pour le #0",
	"wifih-health-scores": "Scores de santé du foyer",
	"wifih-health-week-scores": "Historique des scores de santé",
	"h-dashboard-equip": "Équipements",
	"h-dashboard-station": "Stations",
	"wifih-health-wan-interf": "Interface WAN",
	"wifih-health-wan-interf-name": "Nom",
	"wifih-health-wan-interf-type": "Type",
	"wifih-health-wan-interf-status": "Statut",
	"wifih-health-wan-interf-totalbytesreceived": "Download total",
	"wifih-health-wan-interf-bytesreceived": "Download",
	"wifih-health-wan-interf-totalbytessent": "Upload total",
	"wifih-health-wan-interf-bytessent": "Upload",
	"wifih-health-wan-interf-totalbytessum": "Total",
	"wifih-health-wan-interf-bytes": "Débit (Mb/s)",
	"wifih-health-wifi-info": "Données Wi-Fi",
	"h-dashboard-health": "Santé",
	"h-dashboard-incident": "Incidents",
	"h-dashboard-anomaly": "Anomalies",
	"h-dashboard-anomaly-button": "Données d'anomalie",
	"h-dashboard-anomaly-chart": "Top 5 anomalies",
	"wifih-health-incidents": "Top 5 incidents",
	"h-equip-imei": "IMEI",
	"h-equip-model": "Modèle",
	"h-equip-uptime": "Temps de fonctionnement",
	"h-equip-nb-reboot": "Nombre de reboots",
	"h-equip-lastseen": "Dernière activité",
	"wifih-health-desc-wifistdavailable": "Wi-Fi dispo.",
	"wifih-health-desc-wifistdused": "Wi-Fi util.",
	"wifih-health-desc-ethernet": "Ethernet",
	"wifih-health-desc-macaddress": "Adr. MAC",
	"wifih-health-desc-hostname": "Hôte",
	"wifih-health-desc-band": "Bande",
	"wifih-health-desc-standards": "Standards",
	"wifih-health-desc-available": "Disponibles",
	"wifih-health-desc-supported": "Supportés",
	"wifih-health-desc-current": "Actuel",
	"wifih-health-desc-in-use": "Utilisés",
	"wifih-health-desc-channels": "Canaux",
	"wifih-health-desc-chan": "Canal",
	"wifih-health-desc-bandchans": "Canaux #0",
	"wifih-health-desc-curbandwidth": "Bandwidth",
	"wifih-health-desc-dev-lastseen": "Der. activité",
	"wifih-health-desc-linktype": "Type de lien",
	"wifih-health-desc-maxbitrate": "Bit rate max",
	"wifih-health-desc-interfaces": "Interfaces",
	"wifih-health-desc-interface": "Interface",
	"wifih-health-desc-details": "Détails",
	"wifih-health-desc-management": "Gestion à distance",
	"wifih-health-desc-createaction": "Créer une action",
	"wifih-health-desc-rdmaction": "action(s)",
	"wifih-health-desc-rdmstatus": "Statut",
	"wifih-health-desc-rdmstatus-none": "Pas d'action",
	"wifih-health-desc-lastcmds": "Dernières actions créées",
	"wifih-health-desc-status": "Statut",
	"wifih-health-desc-wifissid": "SSID",
	"wifih-health-desc-autochan": "Auto channel enable",
	"wifih-health-pietitle": "Temps de connexion des stations du foyer (secondes)",
	"wifih-health-chanconntime": "Temps de connexion par canal utilisé",
	// h-equip-sys
	"h-equip-sys-data": "Données système",
	"h-equip-sys-overview": "Vue d'ensemble",
	"h-equip-sys-manufacturer": "Constructeur",
	"h-equip-sys-model": "Modèle",
	"h-equip-sys-cpu-cores": "Cœurs CPU",
	"h-equip-sys-ram": "RAM",
	"h-equip-sys-flash": "Flash",
	"h-equip-sys-hardwarev": "Hardware",
	"h-equip-datacollector": "Collecteur de données",
	"h-equip-sys-softwarev": "Software",
	"h-equip-sys-health": "Scores de santé système",
	"h-equip-sys-raw": "Métriques système",
	"h-equip-sys-raw-mem-usage": "Mémoire",
	"h-equip-sys-raw-mem-usage-mb": "Mémoire utilisée",
	"h-equip-sys-raw-mem-total-mb": "totale",
	"h-equip-sys-raw-cpu-load1": "CPU Load 1",
	"h-equip-sys-raw-cpu-load5": "CPU Load 5",
	"h-equip-sys-raw-cpu-load15": "CPU Load 15",
	"h-equip-sys-mem-usage-mb": "Mémoire utilisée :",
	"h-equip-sys-mem-total-mb": "total :",
	"h-equip-sys-raw-sys-load1": "CPU Charge 1 :",
	"h-equip-sys-raw-sys-load5": "CPU Charge 5 :",
	"h-equip-sys-raw-sys-load15": "CPU Charge 15 :",
	"h-equip-sys-raw-temperature": "Température",
	"h-equip-sys-raw-temperature-low": "Seuil bas",
	"h-equip-sys-raw-temperature-high": "seuil haut",
	"h-equip-sys-reb-detail": "Raisons de reboot",
	"h-equip-sys-flash-detail-block": "Types de bloc flash (MB)",
	"h-equip-sys-flash-detail-corrupt": "Intégrité des volumes flash",
	"h-equip-sys-flash-avail": "Disponible",
	"h-equip-sys-flash-used": "Utilisé",
	"h-equip-sys-flash-bad": "Mauvais",
	"h-equip-sys-flash-vol-corrupt": "Corrompu",
	"h-equip-sys-flash-vol-valid": "Valide",
	"h-equip-sys-proc": "Processus",
	"h-equip-sys-proc-pid": "Pid",
	"h-equip-sys-proc-name": "Nom",
	"h-equip-sys-proc-priority": "Priorité",
	"h-equip-sys-proc-memsize": "RAM (MB)",
	"h-equip-sys-proc-memusage": "RAM (%)",
	"h-equip-sys-proc-cputime": "Temps CPU",
	"h-equip-sys-crash": "Plantages",
	"h-equip-sys-crash-proc-id": "Id processus",
	"h-equip-sys-crash-time": "Heure",
	"h-equip-sys-crash-proc-name": "Nom du processus",
	"h-equip-sys-crash-softwarev": "Version logicielle",
	// wifih-incident
	"wifih-incident-anomaly-notfound": "Aucune donnée trouvée pour le #0",
	"wifih-incident-tab-incident": "Incidents",
	"wifih-incident-tab-incident-one": "Incident",
	"wifih-incident-tab-anomaly": "Anomalies",
	"wifih-incident-anom-info": "Données d'anomalies",
	"wifih-incident-anomaly-severity-none": "Aucune donnée d'anomalie",
	"wifih-incident-anomaly-severity": "Sévérité des anomalies",
	"wifih-incident-anomaly-details": "Détails de l'anomalie",
	"wifih-incident-anomaly-severity-sub": "Scores de perturbation réseau en fonction du temps par type d'anomalie",
	"wifih-incident-anomaly-severity-max": "Plus haute sévérité possible : ",
	"wifih-incident-anomaly-severity-notice": "* Le score A010000 est calculé à partir des anomalies suivantes : A010101, A010104, A010200, A010301, A010401, A010402, A010403, A010501, A010502, A010503, A010302.",
	"wifih-incident-anomaly-severity-notice2": "* Le score A020000 est calculé à partir des anomalies suivantes : A020101, A020200.",
	"wifih-incident-desc-duration": "Durée",
	"wifih-incident-desc-eqp": "Équip.",
	"wifih-incident-desc-recoms": "Recom.",
	"wifih-incident-desc-contexts": "Informations de contexte",
	"wifih-incident-desc-context-none": "Aucune",
	"wifih-incident-desc-sta": "Stations",
	"wifih-incident-desc-criticality": "Criticité",
	"wifih-incident-desc-severity": "Sévérité moyenne",
	"wifih-incident-desc-band": "Bande",
	"wifih-incident-desc-wantype": "Type WAN",
	"wifih-incident-desc-software": "Version logicielle",
	"wifih-incident-desc-model": "Modèle",
	"wifih-incident-desc-procname": "Processus",
	"wifih-incident-desc-pid": "Pid",
	"wifih-incident-desc-coredump": "Coredump",
	"wifih-incident-desc-ubiname": "UBI device",
	"wifih-incident-desc-details": "Détails",
	"wifih-incident-desc-type": "Type",
	"wifih-incident-notyet": "Les incidents n'ont pas encore été calculés pour le #0",
	"wifih-incident-load-button": "Charger les incidents",
	"wifih-incident-day-load-button": "Charger les incidents du ",
	"wifih-incident-week-mode": "Pour toute la semaine",
	"wifih-incident-criticality-filter": "Criticité supérieure à",
	"wifih-incident-type-filter": "Type",
	"wifih-incident-categ-filter": "Catégorie",
	"wifih-incident-band-filter": "Bande",
	"wifih-incident-network-filter": "Réseau",
	"wifih-incident-all": "Tout",
	"wifih-incident-incidents": "Liste des incidents",
	"wifih-incident-notfound": "Aucun incident pour la date #0",
	"wifih-incident-notcomputed": "Les incidents du #0 n'ont pas encore été calculés",
	"wifih-incident-graphtitle": "Anomalies au cours du temps",
	"wifih-incident-graphtitle-help": "Scores d'anomalies cumulés de tous les liens Wi-Fi affectés par l'incident en fonction du temps.",
	"wifih-incident-graphtitle-help2": "L'anomalie associée à cet incident est : ",
	"wifih-incident-equip-filter": "Filtrer par équipement",
	"wifih-incident-station-filter": "Filtrer par station",
	"wifih-incident-software-filter": "Filtrer par version logicielle",
	"wifih-incident-model-filter": "Filtrer par modèle",
	"wifih-incident-week": "Incidents de la semaine (criticité en heures)",
	"wifih-incident-week-equipment": "Criticité totale par équipement au cours de la semaine (en heures)",
	"wifih-incident-week-station": "Criticité totale par station au cours de la semaine (en heures)",
	"wifih-incident-week-heatmap": "Carte de chaleur des criticités d'incidents au cours de la semaine (en heures)",
	"wifih-incident-date": "Incidents du #0",
	"wifih-incident-date-hint": "(cliquer sur le graphique pour changer la date)",
	"wifih-incident-over-week": "Incident au cours de la semaine (criticité en heures)",
	"wifih-incident-open": "Ouvrir les détails",
	"wifih-incident-close": "Fermer les détails",
	"wifih-incident-bf_both": "Backhaul+Fronthaul",
	"wifih-incident-bf_backhaul": "Backhaul",
	"wifih-incident-bf_fronthaul": "Fronthaul",
	"wifih-incident-chart-incidentbar": "Vue incidents",
	"wifih-incident-chart-equipmentbar": "Vue équipements",
	"wifih-incident-chart-stationbar": "Vue stations",
	"wifih-incident-chart-heatmap": "Carte de chaleur",
	// wifih-connectivity
	"wifih-connectivity-notfound": "Aucune topologie horaire pour la date #0",// TOCHECK
	"wifih-topology-tab-topology": "Topologie",
	"wifih-topology-tab-instability": "Connexions",
	"wifih-topology-tab-heatmap": "Carte de chaleur RSSI",
	"wifih-topology-avg-rssi": "RSSI moyen (dBm) :",
	"wifih-topology-unknown-band": "Inconnu",
	"wifih-topology-fullday": "Journée",
	"wifih-topology-endofday": "Fin de journée",
	"wifih-topology-now": "Maintenant",
	"wifih-topology-layouts": "Répartitions :",
	"wifih-topology-slider": "Heures :",
	// wifih-metrics
	"wifih-metrics-filters": "Filtres des métriques de la vue abonné",
	"wifih-metrics-globalscore": "Santé de l'élement sélectionné",
	"wifih-metrics-details": "Détails des métriques",
	"wifih-metrics-charttitle": "Métriques pour l'élement sélectionné",
	"wifih-metrics-charttitle-equip": "Métriques pour l'équipement sélectionné",
	"wifih-metrics-nodecharttitle": "Métriques pour l'interface sélectionnée",
	"wifih-metrics-chartsubtitle": "L'heure est locale au foyer.",
	"wifih-metrics-wifi-notfound": "Aucune métrique Wi-Fi pour la date #0",
	"wifih-metrics-sys-notfound": "Aucune métrique système pour la date #0",
	"wifih-metrics-ovrview-chart-1": "l'utilisation du CPU",
	"wifih-metrics-ovrview-chart-2": "Temps CPU",
	"wifih-metrics-ovrview-chart-3": "Charge du CPU",
	"wifih-metrics-ovrview-chart-4": "Mémoire disponible",
	"wifih-metrics-ovrview-chart-5": "Utilisation de la mémoire",
	"wifih-metrics-ovrview-chart-6": "Uptime au cours du temps (heure)",
	"wifih-metrics-ovrview-chart-7": "Événement de redémarrage et de mise à jour du micrologiciel au cours de la journée",
	"wifih-metrics-overview-reboot-reason-timestamp": "Horodatage",
	"wifih-metrics-overview-reboot-reason-reboot-reason": "Raison du redémarrage",
	"wifih-metrics-overview-reboot-reason-reboot-source": "Source de redémarrage",
	"wifih-metrics-overview-reboot-reason-self-healing-source": "Source d'auto-guérison",
	"h-metric-sys-equip-reboot-event": "Raison du redémarrage",
	"h-metric-sys-equip-reboot-reason": "Raison :",
	"h-metric-sys-equip-reboot-source": "Source :",
	"h-metric-sys-equip-self-healing-source": "Source d'auto-guérison :",
	"h-metric-sys-equip-software-version": "Version du logiciel :",
	"h-metric-sys-equip-crash-event-title": "Crash détecté au cours de la journée",
	"h-metric-sys-equip-crash-event": "Crash",
	"h-metric-sys-equip-process-name": "Nom du processus :",
	"h-metric-sys-equip-process-id": "ID du processus :",
	"h-metric-sys-equip-core-dump-name": "Nom du fichier core dump :",
	"h-metric-sys-equip-core-dump-size": "Taille du core dump :",
	"wifih-metrics-ovrview-header": 'Métriques avancées (équipement ',
	'wifih-metrics-chart-title': "Analyse des métriques du processus de l'équipement",
	"wifih-metrics-process-notfound": "Aucune métrique de processus pour la date #0",
	"wifih-metrics-pietitle": "Temps de connexion Wi-Fi de l'élément sélectionné",
	"wifih-metrics-chanconntime": "Temps de connexion de l'élément sélectionné par canal utilisé",
	"wifih-metrics-desc-bandchans": "Canaux #0",
	"wifih-metrics-leftsource": "Donnée sur l'axe de gauche",
	"wifih-metrics-rightsource": "Donnée sur l'axe de droite",
	"wifih-metrics-name-airtime": "Airtime",
	"wifih-metrics-name-airtime_idle": "Airtime idle",
	"wifih-metrics-name-airtime_interference": "Airtime interférences",
	"wifih-metrics-name-airtime_recvother": "Airtime reçu autre",
	"wifih-metrics-name-airtime_recvself": "Airtime reçu",
	"wifih-metrics-name-airtime_sent": "Airtime envoyé",
	"wifih-metrics-name-byterate": "Débit de transfert de données (envoi+réception)",
	"wifih-metrics-name-byterate_up": "Débit de transfert de données (envoi)",
	"wifih-metrics-name-byterate_down": "Débit de transfert de données (réception)",
	"wifih-metrics-name-packrate": "Débit de transfert de paquets (envoi+réception)",
	"wifih-metrics-name-packrate_up": "Débit de transfert de paquets (envoi)",
	"wifih-metrics-name-packrate_down": "Débit de transfert de paquets (réception)",
	"wifih-metrics-name-lastratelink": "Débit de liaison (envoi+réception)",
	"wifih-metrics-name-lastratelink_up": "Débit de liaison (envoi)",
	"wifih-metrics-name-lastratelink_down": "Débit de liaison (réception)",
	"wifih-metrics-name-pack": "Nombre de paquets (envoi+réception)",
	"wifih-metrics-name-rssi": "Puissance RSSI (min/moy/max)",
	"wifih-metrics-name-noise": "Bruit (min/moy/max)",
	"wifih-metrics-name-s": "Sévérité des anomalies",
	"wifih-metrics-name-wifi": "Changement de canaux/bande",
	"wifih-metrics-name-parentinterfmac": "Changement d'AP Wi-Fi",
	"wifih-metrics-name-parentip": "SSID/IP",
	"wifih-metrics-name-glitchrate": "Taux de glitch",
	"wifih-metrics-chart-titile-1": "RSSI (min/max/moy) [dBm]",
	"wifih-metrics-chart-titile-2": "Changements de chaîne/bande",
	"wifih-metrics-chart-titile-3": "Bruit (min/max/moy) [dBm]",
	"wifih-metrics-chart-titile-4": "Temps d'antenne [0-255]",
	"wifih-metrics-group_min": "Minimum",
	"wifih-metrics-group_max": "Maximum",
	"wifih-metrics-group_avg": "Moyenne",
	"wifih-metrics-group_up": "Envoi",
	"wifih-metrics-group_down": "Réception",
	"wifih-metrics-group_up_avg": "Envoi (moyenne)",
	"wifih-metrics-group_down_avg": "Réception (moyenne)",
	"wifih-metrics-group_idle": "Idle",
	"wifih-metrics-group_interference": "Interférence",
	"wifih-metrics-group_recvother": "Réception autres",
	"wifih-metrics-group_recvself": "Réception",
	"wifih-metrics-group_sent": "Envoi",
	"wifih-metrics-airtimeisparent": "La donnée Airtime est liée à l'équipement parent.",
	"wifih-metrics-noiseisparent": "La donnée de bruit est liée à l'équipement parent.",
	"wifih-metrics-lastratelinkisparent": "Le débit moyen en réception/émission de la liaison physique du Point d'Accès (AP) qui a été le plus récemment utilisé avec la station associée.",
	"wifih-metrics-lastratelinkupisparent": "Le débit moyen en émission de la liaison physique du Point d'Accès (AP) qui a été le plus récemment utilisé avec la station associée.",
	"wifih-metrics-lastratelinkdownisparent": "Le débit moyen en réception de la liaison physique du Point d'Accès (AP) qui a été le plus récemment utilisé avec la station associée.",
	"wifih-metrics-byterateisparent": "Le nombre moyen d'octets reçus/envoyés au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-byterateupisparent": "Le nombre moyen d'octets envoyés au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-byteratedownisparent": "Le nombre moyen d'octets reçus au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-packrateisparent": "Le nombre moyen de paquets reçus/envoyés au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-packrateupisparent": "Le nombre moyen de paquets envoyés au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-packratedownisparent": "Le nombre moyen de paquets reçus au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-packisparent": "Le nombre de paquets reçus/envoyés au niveau du Point d'Accès (AP) par rapport à la station associée.",
	"wifih-metrics-wifi-link-tab": "Liens Wi-Fi",
	"wifih-metrics-wifi-link-tab-desc": "Métriques calculées par rapport à la passerelle parente et/ou aux extendeurs correspondants",
	"wifih-metrics-wifi-eqp-tab": "Équipement Wi-Fi",
	"wifih-metrics-wifi-overview": "Aperçu Wi-Fi",
	"wifih-metrics-wifi-eqp-tab-desc": "Métriques calculées par rapport aux stations enfants et/ou aux extendeurs connectés",
	"wifih-metrics-wifi-eqp-tab-iftitle": "Interfaces",
	"wifih-metrics-sys-eqp-tab": "Métriques d'équipement système",
	"wifih-metrics-process-tab": "Process",
	"wifih-metric-status-up": "Actif",
	"wifih-metric-status-down": "Inactif",
	"wifih-metric-status-disabled": "Désactivé",
	"wifih-metric-dns-resolution": "Resolution DNS au cours du temps",
	"wifih-metric-ping-wan-performance": "Performance du ping WAN au cours du temps",
	"wifih-metric-select-station": "Sélectionner une station",
	// h-metric-sys-equip
	"h-metric-sys-equip-flash": "Flash",
	"h-metric-sys-equip-proc": "Processus",
	"h-metric-sys-equip-pie-reboot": "Raisons de reboot",
	"h-metric-sys-equip-pie-procs": "Processus",
	"h-metric-sys-equip-pie-procmem": "Utilisation RAM des processus",
	"h-metric-sys-equip-pie-proccpu": "Temps CPU des processus",
	"h-metric-sys-equip-pie-procstatus": "Statuts des processus",
	// h-metric-sys-equip-summary-table
	"h-metric-sys-equip-summary-table": "Métriques système",
	"h-metric-sys-equip-summary-table-cpu": "Charge CPU",
	"h-metric-sys-equip-summary-table-mem": "Utilisation mémoire (%)",
	"h-metric-sys-equip-summary-table-temperature": "Température (°C)",
	// h-metrics-sys-fields
	"h-metric-sys-field-reboot": "Compte des reboots",
	"h-metric-sys-field-uptime": "Temps de fonctionnement",
	"h-metric-sys-field-processmemusage": "Utilisation mémoire",
	"h-metric-sys-field-processcputime": "Temps CPU",
	"h-metric-sys-field-processcpuusage": "CPU usage",
	"h-metric-sys-field-processmemsize": "Memory size",
	"h-metric-sys-field-flashtotalerase": "Total eraseblocks size",
	"h-metric-sys-equip-flashtotalerase-desc": "Quantité totale d'eraseblocks physiques bons (non marqués comme mauvais)",
	"h-metric-sys-field-flashavailerase": "Available eraseblocks",
	"h-metric-sys-equip-flashavailerase-desc": "Quantité d'eraseblocks logiques disponibles",
	"h-metric-sys-field-flashbadpebcnt": "Bad PEB count",
	"h-metric-sys-equip-flashbadpebcnt-desc": "Compte des eraseblocks physiques mauvais",
	"h-metric-sys-field-flashmaxerasecnt": "Max eraseblock count",
	"h-metric-sys-equip-flashmaxerasecnt-desc": "Maximum physical eraseblock erase counter value",
	"h-metric-sys-field-flashvolumestrue": "Volumes corrompus",
	"h-metric-sys-field-flashvolumesfalse": "Volumes intègres",
	"h-metric-sys-field-temperature": "Température",
	"h-metric-sys-field-crashevent": "Événements de plantage",
	"h-metric-sys-field-cpuload": "Charge CPU",
	"h-metric-sys-field-memory": "Mémoire",
	"h-metric-sys-group-lowlimit": "Seuil limite bas",
	"h-metric-sys-group-highlimit": "Seuil limite haut",
	"h-metric-sys-group-tempval": "Température",
	"h-metric-sys-group-load1": "CPU Load 1",
	"h-metric-sys-group-load5": "CPU Load 5",
	"h-metric-sys-group-load15": "CPU Load 15",
	"h-metric-sys-group-memtotal": "Mem Totale",
	"h-metric-sys-group-memfree": "Mem Libre",
	"h-metric-sys-group-memcached": "Mem en Cache",
	"h-metric-sys-group-memcommit": "Mem Commitée",
	// wifih-metrics-eqp-summary-table
	"wifih-metrics-eqp-summary-table": "Métriques Wi-Fi",
	"wifih-metrics-eqp-summary-table-min": "Min.",
	"wifih-metrics-eqp-summary-table-avg": "Avg.",
	"wifih-metrics-eqp-summary-table-max": "Max.",
	"wifih-metrics-eqp-summary-table-total": "Total",
	"wifih-metrics-eqp-summary-table-rssi": "RSSI (dBm)",
	"wifih-metrics-eqp-summary-table-airtime": "Utilisation airtime (%)",
	"wifih-metrics-eqp-summary-table-noise": "Bruit (dBm)",
	"wifih-metrics-eqp-summary-table-glitchrate": "Taux de glitch (Hz)",
	"wifih-metrics-eqp-summary-table-uplinkrate": "Débit d'émission (Mb/s)",
	"wifih-metrics-eqp-summary-table-downlinkrate": "Débit de réception (Mb/s)",
	// wifih-indic
	"h-indic-health": "Santé",
	"h-dashboard-indic-health-global": "Score de santé globale du foyer",
	"h-dashboard-indic-health-wifi": "Score de santé Wi-Fi du foyer",
	"h-dashboard-indic-health-wan": "Score de santé WAN du foyer",
	"h-dashboard-indic-health-system": "Score de santé système du foyer",
	"wifih-indic-ext": "EXT",
	"wifih-indic-ext-tip": "Nombre total d'extendeurs  (avec l'agent CMS activé)",
	"wifih-indic-stb": "STB",
	"wifih-indic-stb-tip": "Nombre total de set-top box  (avec l'agent CMS activé)",
	"wifih-indic-dev": "Stations",
	"wifih-indic-dev-tip": "Nombre total de stations connectées",
	"wifih-indic-timeperdev": "Temps par station",
	"wifih-indic-timeperdev-tip": "Temps moyen de connexion par station",
	"wifih-indic-elemname-tip": "Identifiant du foyer ou adresse MAC de la station sélectionnée",
	"wifih-indic-avftimeeth": "Temps moyen ETH",
	"wifih-indic-avftimeeth-tip": "Temps moyen de connexion Ethernet",
	"wifih-indic-avftimewifi": "Temps moyen Wi-Fi",
	"wifih-indic-avftimewifi-tip": "Temps moyen de connexion Wi-Fi",
	"wifih-indic-rebootcnt": "Reboots",
	"wifih-indic-rebootcnt-tip": "Nombre total de reboots du jour",
	"wifih-indic-flashusage": "Utilisation flash",
	"wifih-indic-totaltime": "Temps total",
	"wifih-indic-totaltime-tip": "Temps de connexion total pour toutes les stations", // wifih-instability
	"wifih-instability-avg-time": "Temps de connexion moyen",
	"wifih-instability-time": "Voir les temps de connexion",
	"wifih-instability-count": "Voir les nombres de connexions",
	"wifih-instability-bandstitle": "Bandes",
	"wifih-instability-nodestitle": "Équipements",
	"wifih-instability-parentname": "Équipement parent",
	"wifih-instability-childname": "Elément connecté",
	// wifih-heatmap
	"wifih-heatmap-avg-rssi": "RSSI moyen (dBm)",
	// management-users
	"admin-users-inputs": "Inputs de l'administration des utilisateurs",
	"admin-users-status": "Statut",
	"admin-users-filters": "Filtres",
	"admin-users-list": "Liste des utilisateurs",
	"admin-users-popup-update": "Modifier ou supprimer un utilisateur",
	"admin-users-popup-add": "Ajouter un utilisateur",
	"admin-users-username": "Identifiant",
	"admin-users-lastname": "Nom",
	"admin-users-firstname": "Prénom",
	"admin-users-email": "Email",
	"admin-users-company": "Entreprise",
	"admin-users-jobtitle": "Fonction",
	"admin-users-phonenumber": "Téléphone",
	"admin-users-password": "Mot de passe",
	"admin-users-password-comment": "Changer le mot de passe",
	"admin-users-roles": "Droits",
	"admin-users-rolesbut": "Rôles disponibles",
	"admin-users-aclgroups": "Groupes ACL",
	"admin-users-aclgroups-select": "modifier",
	"admin-users-lastsuccessfullogin": "Dernière connexion",
	"admin-users-accountexpirationdate": "Date d'expiration",
	"admin-users-enabled": "Activé",
	"admin-users-accountnonexpired": "Compte non-expiré",
	"admin-users-credentialsnonexpired": "Crédit non-expiré",
	"admin-users-accountnonlocked": "Compte non bloqué",
	"admin-users-deletetip": "Supprimer cet utilisateur après confirmation",
	"admin-users-update-failed": "Échec de l'enregistrement",
	"admin-users-get-failed": "Échec de lecture des données",
	"admin-users-not-found": "Utilisateur inconnu",
	"admin-users-confirmdelete": "Voulez-vous vraiment supprimer cet utilisateur, cette opération ne peut pas être annulée",
	"admin-users-updateok": "Opération réussie",
	// roles-legend-table
	"roles-legend-table-menu-page": "Menu / Page",
	"roles-legend-table-cust-supp-1": "Support client Niveau 1",
	"roles-legend-table-cust-supp-2": "Support client Niveau 2",
	"roles-legend-table-cust-supp-3": "Support client Niveau 3",
	"roles-legend-table-cust-manager": "Manageur Parc",
	"roles-legend-table-cust-analyst": "Analyste Parc",
	"roles-legend-table-rdm-manager": "Manageur Équipements",
	"roles-legend-table-admin": "Administrateur",
	"roles-legend-table-welcome": "Accueil",
	"roles-legend-table-cust-dash": "Parc / Tableau de bord",
	"roles-legend-table-cust-pops": "Parc / Populations",
	"roles-legend-table-hoho-dash": "Foyer / Tableau de bord",
	"roles-legend-table-hoho-incs": "Foyer / Incidents",
	"roles-legend-table-hoho-conn": "Foyer / Connectivité",
	"roles-legend-table-hoho-mets": "Foyer / Métriques",
	"roles-legend-table-hoho-elogs": "Foyer / Evénements",
	"roles-legend-table-mana-usrs": "Gestion / Utilisateurs",
	"roles-legend-table-mana-eqps": "Gestion / Équipements",
	// management-rdm
	"admin-rdm-filters": "Filtres",
	"admin-rdm-uuid": "id",
	"admin-rdm-createtime": "Création",
	"admin-rdm-updatetime": "Modification",
	"admin-rdm-expiretime": "Expiration",
	"admin-rdm-userlogin": "Utilisateur",
	"admin-rdm-requestid": "Type",
	"admin-rdm-firmware": "Firmware",
	"admin-rdm-model": "Modèle",
	"admin-rdm-networkid": "Id de foyer",
	"admin-rdm-nodeimei": "Id équipement",
	"admin-rdm-ttlid": "TTL",
	"admin-rdm-statusid": "Statut",
	"admin-rdm-filter": "Filtre",
	"admin-rdm-filterreset": "Reset de filtre",
	"admin-rdm-filtertype": "Taper",
	"admin-rdm-statusstatusreason": "Raison",
	"admin-rdm-statusreason": "Raison",
	"admin-rdm-response": "Réponse",
	"admin-rdm-comment": "Commentaire",
	"admin-rdm-uiaction": "",
	"admin-rdm-uiactionedit": "Voir l'action et modifier le commentaire",
	"admin-rdm-popup-add": "Créer une action",
	"admin-rdm-createstart": "Depuis",
	"admin-rdm-createend": "Jusqu'à",
	"admin-rdm-button-refresh": "Rafraîchir",
	"admin-rdm-button-search": "Rechercher",
	"admin-rdm-canceltip": "Annuler cette action après confirmation",
	"admin-rdm-cancel": "Annuler l'action en cours",
	"admin-rdm-cancel-confirm": "Voulez-vous vraiment annuler cette action ? Cette opération ne peut pas être annulée.",
	"admin-rdm-closenocancel": "Fermer sans annuler l'action",
	"admin-rdm-closenosave": "Fermer sans sauvegarder",
	"admin-rdm-confirmcancel": "Voulez-vous vraiment annuler cette action ? Cette opération ne peut pas être annulée.",
	"admin-rdm-createdby": "créé par",
	"admin-rdm-reqerror": "Erreur lors du traitement de la demande",
	"admin-rdm-reqlegend": "Actions disponibles",
	"admin-rdm-reqlegend-action": "Action",
	"admin-rdm-reqlegend-description": "Description",
	"admin-rdm-showresponse": "Afficher la réponse complète",
	"admin-rdm-fullresponse": "Réponse",
	"admin-rdm-createaction": "Créer cette action",
	"admin-rdm-possiblereason": "Raisons possibles",
	"admin-rdm-fill-field": "Veuillez remplir ce champ",
	// management-acl
	"admin-acl-reqerror": "Erreur lors du traitement de la demande",
	"acl-existing-name-error": "Un groupe avec ce nom existe déjà.",
	"acl-in-use-cannot-delete-error": "Ce groupe ne peut être supprimé car il est utilisé.",
	"admin-acl-filters": "Filtres",
	"admin-acl-updatedatestart": "Depuis",
	"admin-acl-updatedateend": "Jusqu'au",
	"admin-acl-popup-add": "Créer un groupe",
	"admin-acl-closenosave": "Fermer sans sauvegarder",
	"admin-acl-uiaction": "",
	"admin-acl-uiactionedit": "Voir et modifier le groupe",
	"admin-acl-uiactiondelete": "Supprimer le groupe après confirmation",
	"admin-acl-confirmdelete": "Voulez-vous vraiment supprimer ce groupe ? Cette opération ne peut pas être annulée.",
	"admin-acl-delete-blocked": "Suppression bloquée: Ce group ACL est lié à une population active",
	"admin-acl-delete": "Supprimer le groupe",
	"admin-acl-close-delete": "Fermer sans supprimer le groupe",
	"admin-acl-uiactionshow": "Afficher la liste des identifiants de foyer",
	"admin-acl-creationdate": "Création",
	"admin-acl-updatedate": "Modification",
	"admin-acl-name": "Nom",
	"admin-acl-default": "Par défaut",
	"admin-acl-default-exists": "Un groupe par défaut existe déjà.",
	"admin-acl-type": "Type",
	"admin-acl-id": "Identifiant",
	"admin-acl-updatedbyuser": "Modifié par",
	"admin-acl-listnetid": "Liste des identifiants de foyer",
	"admin-acl-true": "Vrai",
	"admin-acl-false": "Faux",
	// Dashboard > Activity
	"h-dashboard-activity": "Activité",
	"h-dashboard-devices": "Appareils",
	"h-dashboard-stations": "Station(s)",
	"h-dashboard-connected-devices": "Appareils connectés",
	"h-dashboard-all-devices": "Tous les dispositifs",
	"h-dashboard-device-activity-detection": "Détection de l'activité de l'appareil",
	"h-dashboard-most-active-devices": "Appareils les plus actifs",
	"h-dashboard-most-consuming-devices": "Appareils les plus consommateurs",
	"h-dashboard-activity-low-activity": "Faible activité",
	"h-dashboard-activity-high-activity": "Haute activité",
	"h-dashboard-activity-traffic": "Trafic",
	// Equipment
	"h-equipment-rdm-create-action": "Créer une action :",
	"h-equipment-rdm-last-action": "Dernières actions créées (#) :",
	"h-equipment-date": "Date",
	// profile
	"profile-edit-profile": "Editer le profil",
	"profile-last-connection": "Dernière connexion",
	"profile-update-information": "Mettre à jour les informations",
	"profile-current-password": "Mot de passe actuel",
	"profile-write-password": "Écrivez votre mot de passe",
	"profile-new-password": "Nouveau mot de passe",
	"profile-repeat-password": "Répéter le mot de passe",
	"profile-password-validate-char-amount": "13 caractères minimum",
	"profile-password-validate-lowercase": "Au moins une lettre minuscule",
	"profile-password-validate-uppercase": "Au moins une lettre majuscule",
	"profile-password-validate-number": "Au moins un numéro",
	"profile-password-validate-special-char": "Au moins un caractère spécial ! / : ; < = > ? @ [  \\  ] ^ _ ` { | } ~",
	"profile-change-password": "Changer le mot de passe",
	"profile-show-password": "Montrer le mot de passe",
	"profile-phone-number": "Numéro de téléphone",
	"profile-pic-updated": "Photo de profil mise à jour",
	"profile-pic-not-updated": "La photo de profil n'a pas pu être mise à jour",
	"profile-password-updated": "Mot de passe mis à jour",
	"profile-password-not-updated": "Le mot de passe n'a pas pu être mis à jour",
	"profile-password-same": "Le nouveau mot de passe doit être différent de l'ancien. Veuillez entrer un mot de passe distinct.",
	"profile-user-updated": "Informations utilisateur mises à jour",
	"profile-user-not-updated": "Les informations de l'utilisateur n'ont pas pu être mises à jour",
	"profile-modal-title": "Nom d'utilisateur mis à jour",
	"profile-modal-body": "Votre profil a été mis à jour. Pour que ces modifications prennent effet, vous serez déconnecté. Veuillez vous reconnecter pour continuer à utiliser le site.",
	// maintenance
	"maintenance-title": "Entretien du Système",
	"maintenance-desc": "Désolé pour le désagrément, mais nous effectuons actuellement des travaux de maintenance. Nous serons de nouveau en ligne sous peu !",
	"not-found-title": "Page introuvable",
	"not-found-desc": "On dirait que vous avez suivi un mauvais lien.",
	"not-found-action": "Page d'accueil",
	"not-applied": "N/A",
	// Station
	"detectByAI": "Detecté par IA",
};
