import {E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender} from "../util/E5RestSender";
import {E5RequestCallback, E5RequestStatus} from "./E5ServiceCommon";
import {E5EntCBPop} from "../entity/customer_base/E5EntCBPop";
import {E5MainConfig} from "../global/E5MainConfig";
import {E5StoreCBPop} from "../store/E5StoreCBPop";
import {E5UtilI18n} from "../global/E5MainLang";
import {E5Storage} from "../util/E5Storage";
import {E5Request} from "./E5Request";

//E5
export enum E5RequestCBPopTags {
	NONE = "",
	POPS = "pops",
	NETIDS = "netids",
	DOWNLOADNETIDS = "downloadnetids",
	UPDATEPOP = "updatepop",
	ADDPOP = "addpop",
	DELETEPOP = "deletepop"
}

//E5
export class E5RequestCBPop implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestCBPop;

	//E5
	static Ins(): E5RequestCBPop {
		if (E5RequestCBPop.ins === undefined)
			E5RequestCBPop.ins = new E5RequestCBPop();
		return E5RequestCBPop.ins;
	}

	// --------- INSTANCE -------------

	//E5
	v3sender: E5RestSender;
	v4sender: E5RestSender;
	tokenmap: Map<string, number>;

	//E5
	constructor() {
		this.v3sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v3");
		this.v4sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v4");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		if (resp.request.otherdata.requesttag === E5RequestCBPopTags.POPS)
			this.PopsCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBPopTags.NETIDS)
			this.NetIdsCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBPopTags.DOWNLOADNETIDS)
			this.DownloadNetIdsCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBPopTags.UPDATEPOP)
			this.UpdatePopCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBPopTags.ADDPOP)
			this.AddPopCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBPopTags.DELETEPOP)
			this.DeletePopCB(resp);
	}

	//E5
	CancelTag(tag: E5RequestCBPopTags) {
		this.v3sender.Cancel(this.tokenmap.get(tag));
		this.v4sender.Cancel(this.tokenmap.get(tag));
	}

	//E5
	ClearAll() {
		// remove all data
		E5StoreCBPop.Ins().SetPops({loading: false, success: false, message: ""}, []);
		E5StoreCBPop.Ins().SetPopGet({loading: false, success: false, message: ""}, new E5EntCBPop());

		//
		E5RequestCBPop.Ins().CancelTag(E5RequestCBPopTags.POPS);
		E5RequestCBPop.Ins().CancelTag(E5RequestCBPopTags.UPDATEPOP);
	}

	// --------- POPS -------------

	//E5
	FetchPops(callback: E5RequestCallback): void {
		E5StoreCBPop.Ins().SetPops({loading: true, success: false, message: ""}, []);
		this.v4sender.Cancel(this.tokenmap.get(E5RequestCBPopTags.POPS));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: "/population",
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: {requesttag: E5RequestCBPopTags.POPS, callback: callback}
		};
		this.tokenmap.set(E5RequestCBPopTags.POPS, this.v4sender.Send(req));
	}

	//E5
	PopsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// success but nothing in list
			status.success = true;
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let pops: E5EntCBPop[] = [];
			if (status.success && resp.status !== 404) {
				let idx: number;
				for (idx = 0; idx < resp.body.length; idx++) {
					let pop: E5EntCBPop = new E5EntCBPop();
					pop.CopyFromJson(resp.body[idx]);
					pops.push(pop);
				}
				pops.sort(E5RequestCBPop.ComparePop);
				E5StoreCBPop.Ins().SetPops(status, pops);
			} else {
				E5StoreCBPop.Ins().SetPops(status, pops);
			}
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}

	// --------- NET IDS -------------

	//E5
	FetchNetIds(popid: string, type: "USER"|"COMPUTED", callback: E5RequestCallback): void {
		this.v3sender.Cancel(this.tokenmap.get(E5RequestCBPopTags.NETIDS));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: "/population/" + popid + "/networkid?type=" + type,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: {requesttag: E5RequestCBPopTags.NETIDS, callback: callback}
		};
		this.tokenmap.set(E5RequestCBPopTags.NETIDS, this.v3sender.Send(req));
	}

	//E5
	NetIdsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = resp.body.error || E5UtilI18n._("wificb-pops-no-netids");
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		let netids: string[] = [];
		try {
			if (status.success) {
				let idx: number;
				for (idx = 0; idx < resp.body.network_ids.length; idx++)
					netids.push(resp.body.network_ids[idx]);
			}
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status, netids);
	}

	// --------- DOWNLOAD NET IDS -------------

	//E5
	DownloadNetIds(popid: string, type: "USER"|"COMPUTED", callback: E5RequestCallback): void {
		this.v3sender.Cancel(this.tokenmap.get(E5RequestCBPopTags.DOWNLOADNETIDS));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: "/population/" + popid + "/download/networkid?type=" + type,
			header: [
				["Content-Type", "text/csv"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: {requesttag: E5RequestCBPopTags.DOWNLOADNETIDS, callback: callback}
		};
		this.tokenmap.set(E5RequestCBPopTags.DOWNLOADNETIDS, this.v3sender.SendAndDownload(req));
	}

	//E5
	DownloadNetIdsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-pops-no-netids");
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}

	// --------- UPDATE POP -------------

	//E5
	UpdatePop(popid: string, pop: E5EntCBPop, infoonly: boolean, callback: E5RequestCallback): void {
		E5StoreCBPop.Ins().SetPopGet({loading: true, success: false, message: ""}, new E5EntCBPop());
		this.v4sender.Cancel(this.tokenmap.get(E5RequestCBPopTags.UPDATEPOP));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let popjson: any = pop.CopyToJson(), updatejson: any = {...popjson};
		if (infoonly) {
			updatejson.networkIds = Array.isArray(popjson.networkIds) ? popjson.networkIds : null;
			updatejson.criterias = null;
		}
		let req: E5RestRequest = {
			method: "PUT",
			uri: "/population/" + popid,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: updatejson,
			otherdata: {requesttag: E5RequestCBPopTags.UPDATEPOP, callback, popjson}
		};
		this.tokenmap.set(E5RequestCBPopTags.UPDATEPOP, this.v4sender.Send(req));
	}

	//E5
	UpdatePopCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			if (String(resp.body.error).toLowerCase().includes("acl"))
				status.message = E5UtilI18n._("conflicterror") + " : " + E5Request.GetJsonErrors(resp.body);
			else status.message = E5UtilI18n._("wificb-pops-not-found");
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 202) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreCBPop.Ins().SetPopGet(status, new E5EntCBPop());
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		let {otherdata} = resp.request;
		if (typeof otherdata.popjson === "object" && otherdata.popjson !== null) {
			if (Array.isArray(otherdata.popjson.networkIds)) resp.body.networkIds = otherdata.popjson.networkIds;
			if (Array.isArray(otherdata.popjson.criterias)) resp.body.criterias = otherdata.popjson.criterias;
		}
		otherdata.callback?.(status, new E5EntCBPop(resp.body));
	}

	// --------- ADD POP -------------

	//E5
	AddPop(pop: E5EntCBPop, callback: E5RequestCallback): void {
		E5StoreCBPop.Ins().SetPopGet({loading: true, success: false, message: ""}, new E5EntCBPop());
		this.v4sender.Cancel(this.tokenmap.get(E5RequestCBPopTags.ADDPOP));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let popjson: any = pop.CopyToJson();
		let req: E5RestRequest = {
			method: "POST",
			uri: "/population",
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: popjson,
			otherdata: {requesttag: E5RequestCBPopTags.ADDPOP, callback: callback, popjson}
		};
		this.tokenmap.set(E5RequestCBPopTags.ADDPOP, this.v4sender.Send(req));
	}

	//E5
	AddPopCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("conflicterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 201) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreCBPop.Ins().SetPopGet(status, new E5EntCBPop());
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		let {otherdata} = resp.request;
		if (typeof otherdata.popjson === "object" && otherdata.popjson !== null) {
			if (Array.isArray(otherdata.popjson.networkIds)) resp.body.networkIds = otherdata.popjson.networkIds;
			if (Array.isArray(otherdata.popjson.criterias)) resp.body.criterias = otherdata.popjson.criterias;
		}
		if (otherdata.callback !== undefined) otherdata.callback(status, new E5EntCBPop(resp.body));
	}

	// --------- DELETE POP -------------

	//E5
	DeletePop(popid: string, callback: E5RequestCallback): void {
		E5StoreCBPop.Ins().SetPopGet({loading: true, success: false, message: ""}, new E5EntCBPop());
		this.v3sender.Cancel(this.tokenmap.get(E5RequestCBPopTags.DELETEPOP));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "DELETE",
			uri: "/population/" + popid,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: {requesttag: E5RequestCBPopTags.DELETEPOP, callback: callback}
		};
		this.tokenmap.set(E5RequestCBPopTags.DELETEPOP, this.v3sender.Send(req));
	}

	//E5
	DeletePopCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-pops-not-found");
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 204) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreCBPop.Ins().SetPopGet(status, new E5EntCBPop());
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}

	// --------- Utils -------------

	//E5
	static ComparePop = (p1: E5EntCBPop, p2: E5EntCBPop): number => p1.name.localeCompare(p2.name);


}