import { E5UtilI18n } from "../global/E5MainLang";
import moment, { Moment } from "moment";


export function getValue(obj: any) {
    if (!obj) return null;
        return moment(
            obj,
            `${E5UtilI18n._("dateformat")} HH:mm`
        ).toDate();
}

export function renderValue(obj: any) {
    if (!obj) return "--";
        const date = moment(
            obj,
            `${E5UtilI18n._("dateformat")} HH:mm`
        );
        return date.isValid()
				? date.format(`${E5UtilI18n._("dateformat")} HH:mm`)
				: "--";
}

export function sort (v1: any, v2: any) {
    if (!v1) return -1;
    if (!v2) return 1;
    return new Date(v1).getTime() - new Date(v2).getTime();
}