import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5RequestCallback, E5RequestStatus } from "./E5ServiceCommon";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5StoreAdmin } from "../store/E5StoreAdmin";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5EntUser } from "../entity/E5EntUser";
import { E5Storage } from "../util/E5Storage";
import { E5Request } from "./E5Request";

//E5
export enum E5RequestAdminTags {
	NONE = "",
	GETUSERS = "getusers",
	GETUSER = "getuser",
	UPDATEUSER = "updateuser",
	ADDUSER = "adduser",
	DELETEUSER = "deleteuser",
	DOWNLOADUSER = 'downloaduser'
}

//E5
export class E5RequestAdmin implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestAdmin;

	//E5
	static Ins(): E5RequestAdmin {
		if (E5RequestAdmin.ins === undefined)
			E5RequestAdmin.ins = new E5RequestAdmin();
		return E5RequestAdmin.ins;
	}

	// --------- INSTANCE -------------

	v2sender: E5RestSender;
	v3sender: E5RestSender;
	tokenmap: Map<string, number>;

	//E5
	constructor() {
		this.v2sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v2");
		this.v3sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v3");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		//console.log(resp.request.uri);
		if (resp.request.otherdata.requesttag === E5RequestAdminTags.GETUSERS)
			this.LoadUsersCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestAdminTags.GETUSER)
			this.GetUserCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestAdminTags.UPDATEUSER)
			this.UpdateUserCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestAdminTags.ADDUSER)
			this.AddUserCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestAdminTags.DELETEUSER)
			this.DeleteUserCB(resp)
		else if (resp.request.otherdata.requesttag === E5RequestAdminTags.DOWNLOADUSER)
			this.DownloadUsersCB(resp);
	}

	//E5
	CancelTag(tag: E5RequestAdminTags) {
		this.v2sender.Cancel(this.tokenmap.get(tag));
		this.v3sender.Cancel(this.tokenmap.get(tag));
	}

	//E5
	ClearAll() {
		// remove all data
		E5StoreAdmin.Ins().SetUsers({ loading: false, success: false, message: "" }, []);
		//
		E5RequestAdmin.Ins().CancelTag(E5RequestAdminTags.GETUSERS);
	}

	// --------- Load Users -------------

	//E5
	LoadUsers(callback: E5RequestCallback): void {
		E5StoreAdmin.Ins().SetUsers({ loading: true, success: false, message: "" }, []);
		this.v3sender.Cancel(this.tokenmap.get(E5RequestAdminTags.GETUSERS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: "/user",
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: { requesttag: E5RequestAdminTags.GETUSERS, callback: callback }
		};
		this.tokenmap.set(E5RequestAdminTags.GETUSERS, this.v3sender.Send(req));
	}

	//E5
	LoadUsersCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let users: E5EntUser[] = [];
			if (status.success) {
				//console.log(resp);
				let idx: number;
				for (idx = 0; idx < resp.body.length; idx++) {
					let user: E5EntUser = new E5EntUser();
					user.CopyFromJson(resp.body[idx]);
					users.push(user);
				}
				E5StoreAdmin.Ins().SetUsers(status, users);
			} else {
				E5StoreAdmin.Ins().SetUsers(status, users);
			}
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}

	// --------- Get One User -------------

	//E5
	GetUser(username: string, callback: E5RequestCallback): void {
		E5StoreAdmin.Ins().SetUserGet({ loading: true, success: false, message: "" }, undefined);
		this.v3sender.Cancel(this.tokenmap.get(E5RequestAdminTags.GETUSER));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: "/user/" + username,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: { requesttag: E5RequestAdminTags.GETUSER, callback: callback }
		};
		this.tokenmap.set(E5RequestAdminTags.GETUSER, this.v3sender.Send(req));
	}

	//E5
	GetUserCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		let user: E5EntUser = new E5EntUser();
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("admin-users-not-found");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			user.CopyFromJson(resp.body);
			E5StoreAdmin.Ins().SetUserGet(status, user);
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status, user);
	}

	// --------- Update User -------------

	//E5
	UpdateUser(username: string, user: E5EntUser, callback: E5RequestCallback): void {
		E5StoreAdmin.Ins().SetUserGet({ loading: true, success: false, message: "" }, undefined);
		this.v3sender.Cancel(this.tokenmap.get(E5RequestAdminTags.UPDATEUSER));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let userjson: any = user.CopyToJson();
		userjson.aclGroupNames = userjson.aclGroups.map((group: any) => group.name);
		if (userjson.password === "") delete userjson.password;//if password is empty, remove it or update will fail
		// // bug in api, there is no "name" in roles for update user
		// let idx: number;
		// userjson.roles = [];
		// for (idx = 0; idx < user.roles.length; idx++) {
		// 	userjson.roles.push(user.roles[idx]);
		// }
		// bug in api, timezone not supported (2020-04-11T23:25:00)
		if (userjson.accountExpirationDate !== null) {
			if (userjson.accountExpirationDate.length > 19)
				userjson.accountExpirationDate = userjson.accountExpirationDate.substr(0, 19);
		}
		//
		let req: E5RestRequest = {
			method: "PUT",
			uri: "/user/" + username,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: userjson,
			otherdata: { requesttag: E5RequestAdminTags.UPDATEUSER, callback: callback }
		};
		this.tokenmap.set(E5RequestAdminTags.UPDATEUSER, this.v3sender.Send(req));
	}

	//E5
	UpdateUserCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("admin-users-not-found");
		} else if (resp.status === 409) {
			status.message = E5UtilI18n._("conflicterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 202) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreAdmin.Ins().SetUserGet(status, undefined);
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}

	// --------- Add User -------------

	//E5
	AddUser(user: E5EntUser, callback: E5RequestCallback): void {
		E5StoreAdmin.Ins().SetUserGet({ loading: true, success: false, message: "" }, undefined);
		this.v3sender.Cancel(this.tokenmap.get(E5RequestAdminTags.ADDUSER));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let userjson: any = user.CopyToJson();
		userjson.aclGroupNames = userjson.aclGroups.map((group: any) => group.name);
		// // bug in api, there is no "name" in roles for add user
		// let idx: number;
		// userjson.roles = [];
		// for (idx = 0; idx < user.roles.length; idx++) {
		// 	userjson.roles.push(user.roles[idx]);
		// }
		// bug in api, timezone not supported (2020-04-11T23:25:00)
		if (userjson.accountExpirationDate !== null) {
			if (userjson.accountExpirationDate.length > 19)
				userjson.accountExpirationDate = userjson.accountExpirationDate.substr(0, 19);
		}
		//
		let req: E5RestRequest = {
			method: "POST",
			uri: "/user",
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: userjson,
			otherdata: { requesttag: E5RequestAdminTags.ADDUSER, callback: callback }
		};
		this.tokenmap.set(E5RequestAdminTags.ADDUSER, this.v3sender.Send(req));
	}

	//E5
	AddUserCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 409) {
			status.message = E5UtilI18n._("conflicterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 201) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreAdmin.Ins().SetUserGet(status, undefined);
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}

	// --------- Delete User -------------

	//E5
	async DeleteUser(username: string, callback: E5RequestCallback): Promise<void> {
		E5StoreAdmin.Ins().SetUserGet({ loading: true, success: false, message: "" }, undefined);
		this.v2sender.Cancel(this.tokenmap.get(E5RequestAdminTags.DELETEUSER));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		//
		let req: E5RestRequest = {
			method: "DELETE",
			uri: "/user/" + username,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: { requesttag: E5RequestAdminTags.DELETEUSER, callback: callback }
		};
		this.tokenmap.set(E5RequestAdminTags.DELETEUSER, this.v2sender.Send(req));
	}

	//E5
	DownloadUsersCB(res: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: true, message: "" };
		const dataStatus = res.status.toString();
		if (!dataStatus.startsWith("2")) {
			status.success = false;
			status.message = E5UtilI18n._("servernotresponding");
		}
		if (res.request.otherdata.callback !== undefined)
			res.request.otherdata.callback(status);
	}
	DeleteUserCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("admin-users-not-found");
		} else if (resp.status !== 204) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreAdmin.Ins().SetUserGet(status, undefined);
		} catch (ex) {
			if (E5MainConfig.GetDevMode())
				console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined)
			resp.request.otherdata.callback(status);
	}
	async DownloadUsers(callback: E5RequestCallback, lang: string = "en"): Promise<void> {
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: `/user/csv?lang=${lang}`,
			header: [
				["Content-Type", "text/csv"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: { requesttag: E5RequestAdminTags.DOWNLOADUSER, callback: callback }
		};
		this.tokenmap.set(E5RequestAdminTags.DOWNLOADUSER, this.v3sender.SendAndDownload(req));
	}

}
