import React from "react";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
import * as BPD from "@blueprintjs/datetime";
import { IReactionDisposer, reaction } from "mobx";
import MenuItem from '@mui/material/MenuItem';
import { E5EntCBPop, E5CBPopCriteria } from "../../../entity/customer_base/E5EntCBPop";
import { E5StoreCBPopGet, E5StoreCBPops } from "../../../store/E5StoreCBPop";
import { E5FormBPDateUtils } from "../../../util/E5FormDatetimeUtils";
import { E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5RequestCBPop } from "../../../request/E5RequestCBPop";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreACL } from "../../../store/E5StoreACL";
import { LocaleUtils } from "react-day-picker";
import { E5CBPopPopup } from "./E5CBPopPopup";
import { E5Text } from "../../../util/E5Text";
import Table from '../../../global/component/E5Table'
import TextFieldStyled from '../../../global/component/TextFieldStyled'
import StyledMobileDatePicker from '../../../global/component/DatePickerStyled';
import TextField from '@mui/material/TextField';
import { getValue, renderValue, sort } from "../../../util/E5SortByDateUtils";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
//
import "./E5CBPopList.css";
import { MobileDatePicker } from "@mui/x-date-pickers";
import MultipleSelectCheckmarks from './Acl-Select';
import Button from "../../../global/component/Button";
import { Link } from 'react-router-dom';
//E5
export enum E5CBPopAction { add = "add", delete = "delete", update = "update" }

//E5
enum E5CBPopSortDir { up = "up", down = "down" }

//E5
interface E5CBPopListState {
	filterpops: E5EntCBPop[];
	filtereddatanew: E5EntCBPop[];
	curfilter: any;
	filterinput: any;
	sortfield: string;
	sortdir: E5CBPopSortDir;
	dialopen: boolean;
	duplunix: number;
	selectpop: E5EntCBPop;
	selectedPopId: string;
	activeFilter: string;
	activeSwitch: boolean;
	editpop: E5EntCBPop;
	editpopchanged: boolean;
	onlyinfochanged: boolean;
	nilist: string[];
	wholeparkexists: boolean;

	isAnyFilterActive: boolean;
	filteredName: string | null,
	filterByACL: Array<string>;
	fromDate: string,
	untilDate: string,
	filterByNameInputValue: string,
	rowSelectionModel: number[]
	onlyComputed: boolean;
	clickedIndex: number | null;
}

//E5
export type E5CBPopSaveReceive = (success: boolean, message: string, pop?: E5EntCBPop) => void;

//E5
export type E5CBPopSaveSend = (
	action: E5CBPopAction, pop: E5EntCBPop, popid: string, infoonly: boolean, callback: E5CBPopSaveReceive) => void;

//E5
interface E5CBPopListProps {
	langinfo: E5StoreLangInfo;
	popinfo: E5StoreCBPops;
	popgetinfo: E5StoreCBPopGet;
	savefunc: E5CBPopSaveSend;
	history: any;
	nonetidsfunc: (status: E5RequestStatus) => void;
}

//E5
export const E5CBPopList = observer(class E5CBPopList extends React.PureComponent<E5CBPopListProps, E5CBPopListState> {

	// ---------------- MEMBERS ----------------

	//E5
	filter_timer?: NodeJS.Timeout;
	stop_change_pops?: IReactionDisposer;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBPopListProps, state: E5CBPopListState) {
		super(props, state);
		this.state = {
			filterpops: [], curfilter: {}, filterinput: {}, sortfield: "name", sortdir: E5CBPopSortDir.up,
			dialopen: false, duplunix: -1, selectpop: new E5EntCBPop(), editpop: new E5EntCBPop(),
			editpopchanged: false, onlyinfochanged: true, nilist: [], wholeparkexists: false, selectedPopId: '', activeFilter: '', activeSwitch: false,
			filteredName: null,
			fromDate: new Date(0).toUTCString(),
			untilDate: new Date().toString(),
			filterByACL: [],
			filtereddatanew: [],
			filterByNameInputValue: '',
			rowSelectionModel: [],
			onlyComputed: false,
			clickedIndex: null,
		};
	}

	//E5
	componentDidMount(): void {
		this.BuildFiltered();
		this.stop_change_pops = reaction((): E5EntCBPop[] => this.props.popinfo.pops,
			() => this.BuildFiltered());
	}

	//E5
	componentWillUnmount(): void {
		if (this.filter_timer !== undefined) clearTimeout(this.filter_timer);
		this.stop_change_pops?.();
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousState.filteredName !== this.state.filteredName) {
			this.FilterAll({})
		};
		if (previousState.fromDate !== this.state.fromDate) {
			this.FilterAll({})
		};
		if (previousState.untilDate !== this.state.untilDate) {
			this.FilterAll({})
		};
		if (previousState.filterByACL !== this.state.filterByACL) {
			this.FilterAll({})
		};

	}
	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		let { _ } = E5UtilI18n, { selectpop, editpop, filterinput } = this.state;

		let curpopisnew: boolean = selectpop.populationid === "",
			loading: boolean = this.props.popinfo.status.loading || this.props.popgetinfo.status.loading,
			canvisualize: false | "immediate" | "popover" = false, canduplicate: boolean = false;
		if (!curpopisnew && !this.state.editpopchanged) {
			canvisualize = "popover";
			if (selectpop.networkids === null) {
				canvisualize = "immediate";
				canduplicate = true;
				if (selectpop.criterias !== null && selectpop.criterias.length === 1) {
					let crit: E5CBPopCriteria = selectpop.criterias[0];
					if (crit.criteria === null && crit.condition === null && crit.value === null) canduplicate = false;
				}
			}
		}

		let canaddnetids: boolean = editpop.criterias === null || editpop.criterias.length === 0,
			idzero: boolean = typeof editpop.networkids === "number" && editpop.networkids === 0,
			idempty: boolean = editpop.networkids !== null && typeof editpop.networkids !== "number"
				&& editpop.networkids.length === 0,
			canaddcrits: boolean = editpop.networkids === null || idzero || idempty,
			cansave: boolean = (!canaddnetids || !canaddcrits) && editpop.name !== "",
			rowheights: number[] = this.state.filterpops.map(() => 35),  //eslint-disable-line
			displaystr: string = _("wificb-pops-actions-display"),
			downloadstr: string = _("wificb-pops-actions-download");

		let nilistchoiceopen: boolean | undefined = undefined;//display or download choice popover has default behavior
		if (this.state.nilist.length > 0)//but only if the network id list popup opens
			nilistchoiceopen = false;//then popover closes to avoid the case where it's above the popup

		let nameval: string = "", nameintent: BP.Intent = BP.Intent.NONE;
		if (filterinput.name !== undefined) {
			nameval = filterinput.name;
			nameintent = BP.Intent.DANGER;
		}

		let createstarthasfilter: string = (filterinput.creationdate !== undefined &&
			filterinput.creationdate.start !== undefined) ? " hasfilter" : "",
			createendhasfilter: string = (filterinput.creationdate !== undefined &&
				filterinput.creationdate.end !== undefined) ? " hasfilter" : "",
			createstartval: Moment | undefined = undefined, createendval: Moment | undefined = undefined;
		if (filterinput.creationdate !== undefined) {
			createstartval = filterinput.creationdate.start;
			createendval = filterinput.creationdate.end;
		}
		let createmax: Moment = moment(), createmin: Moment = moment().subtract(10, "year");

		let acljsx: JSX.Element[] = [];
		for (let { name } of E5StoreACL.Ins().aclinfo.groups)
			acljsx.push(<div className={'e5wificb-pops-checkbox-wrapper'}><BP.Checkbox key={name} label={name} value={name} onChange={
				(event: any) => { this.onChangeFilterByAcl(name) }}
				checked={this.state.filterByACL?.includes(name) ?? false} /></div>);

		return <div className="e5wificb-pops-list e5column-20">
			<div className="e5wificb-pops-header e5column-10">
				<div className="e5line-5 e5wificb-pops-titleline">
					<div className="e5compotitle e5wificb-pops-filterbloc">{_("wificb-pops-filters")}</div>
					{/* {!loading && this.state.isAnyFilterActive &&
						<BP.Button icon="cross" text={_("delfilters")} className={'delete-filters-button'} onClick={this.onResetFilters} />} */}
					{loading && <BP.Spinner className="e5spinwait" size={25} />}
					<div className="e5linefull" />
				</div>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<div className="e5line-5 e5wificb-pops-filterline">
						<div className={"e5wificb-pops-filterbloc"}>
							<TextFieldStyled
								className='e5wificb-pops-filterbloc-search'
								id="outlined-basic" label={_("wificb-pops-name")}
								value={this.state.filterByNameInputValue}
								variant="outlined"
								onChange={this.onChangeFilterByUser}
							/>
						</div>
						<div className={"e5wificb-pops-filterbloc"}>
							<MultipleSelectCheckmarks acljsx={acljsx} groups={E5StoreACL.Ins().aclinfo.groups} filterByACL={this.state.filterByACL} onChangeFilterByAcl={this.onChangeFilterByAcl} />
						</div>
						<div className={"e5wificb-pops-filterbloc e5wificb-pops-filterbloc-date" + createstarthasfilter}>
							<StyledMobileDatePicker label={_("wificb-pops-createstart")} onChange={this.onChangeFilterByDateFrom}
								value={this.state.fromDate === new Date(0).toUTCString() ? null : dayjs(this.state.fromDate)}
							/>
						</div>
						<div className={"e5wificb-pops-filterbloc e5wificb-pops-filterbloc-date" + createendhasfilter}>
							<StyledMobileDatePicker label={_("wificb-pops-createend")} onChange={this.onChangeFilterByDateUntil}
								value={this.state.untilDate === new Date().toString() ? null : dayjs(this.state.untilDate)} />

						</div>
						{!loading && this.state.isAnyFilterActive &&
							<BP.Button icon="cross" text={_("delfilters")} className={'delete-filters-button'} onClick={this.onResetFilters} />}
						{/* <div className={"e5wificb-pops-filterbloc" + createstarthasfilter}>
						<BPD.DateInput className="filterdate" formatDate={this.FormatDate} parseDate={this.ParseDate}
									   onChange={(selectdate: Date, isuserchange: boolean) =>
										   this.ChangeDateRange("creationdate",
											   true, selectdate, isuserchange)}
									   value={createstartval !== undefined ? createstartval.toDate() : undefined}
									   locale={this.props.langinfo.curlang}
									   localeUtils={new E5FormBPDateUtils() as LocaleUtils}
									   minDate={createmin.toDate()} maxDate={createmax.toDate()}
									   placeholder={_("wificb-pops-createstart")}
									   />
					</div>
					<div className={"e5wificb-pops-filterbloc" + createendhasfilter}>
						<BPD.DateInput className="filterdate" formatDate={this.FormatDate} parseDate={this.ParseDate}
									   onChange={(selectdate: Date, isuserchange: boolean) =>
										   this.ChangeDateRange("creationdate",
											   false, selectdate, isuserchange)}
									   value={createendval !== undefined ? createendval.toDate() : undefined}
									   locale={this.props.langinfo.curlang}
									   localeUtils={new E5FormBPDateUtils() as LocaleUtils}
									   minDate={createmin.toDate()} maxDate={createmax.toDate()}
									   placeholder={_("wificb-pops-createend")}/>
					</div> */}
						<BP.Button icon="add" text={_("wificb-pops-popup-add")} onClick={this.AddPop} className={'add-population'} />
					</div>
				</LocalizationProvider>
				{/* <div className="e5line-10 e5wificb-pops-filterline aclnamefilter">
					<div className="label">{E5UtilI18n._("wificb-pops-aclname")} :</div>
					{acljsx}
				</div> */}
			</div>
			<div className="e5wificb-pops-poptableouter ">
				<div className="e5wificb-pops-poptableinner">
					<Table className='' translation='wificb-pops-'
						pageSize={25}
						firstColumnName='name'
						sortMode='asc'
						columns={[{
							field: 'name',
							headerName: 'Name',
							editable: true,
							headerAlign: 'center',
							align: 'left',
							minWidth: 300,
							renderCell: (params) => {
								return params.row.name;
							},
						}, {
							field: 'aclname',
							headerName: 'ACL group',
							editable: true,
							headerAlign: 'center',
							align: 'left',
							minWidth: 300,
							renderCell: (params) => {
								return params.row.aclname;
							},
						},
						{
							field: 'creationdate',
							headerName: 'Creation',
							editable: true,
							headerAlign: 'center',
							align: 'left',
							minWidth: 100,
							valueGetter: (params: any) => {
								return getValue(params.row.creationdate);
							},
							renderCell: (params: any) => {
								return renderValue(params.row.creationdate);
							},
							sortComparator: (v1: any, v2: any) => {
								return sort(v1, v2);
							},
						},
						{
							field: 'networkidcount',
							headerName: 'Households',
							editable: true,
							headerAlign: 'center',
							align: 'left',
							minWidth: 150,
							valueGetter: (params: any) => {
								return params.row.networkidcount;
							},
							renderCell: (params: any) => {
								return params.row.networkidcount || "Unavailable";
							},
							sortComparator: (v1: any, v2: any) => {
								if (v1 === "Unavailable") return 1;
								if (v2 === "Unavailable") return -1;
						
								const num1 = parseFloat(v1) || 0;
								const num2 = parseFloat(v2) || 0;
								return num1 - num2;
							},
						},
						{
							field: 'criterias',
							headerName: 'Criteria',
							//width: 100,
							editable: true,
							headerAlign: 'center',
							minWidth: 250,
							align: 'left',
							renderCell: (params) => {
								return params.row.criterias;
							},
						}, {
							field: 'householdId',
							headerName: 'Household Id',
							//width: 100,
							editable: true,
							headerAlign: 'center',
							minWidth: 130,
							align: 'left',
							renderCell: (params) => {
								if (params.row.criterias?.includes('List of')) {
									return <BP.Button style={{ margin: '0 auto' }} className="e5linkbutton displaybut" minimal onClick={() => {
										this.setState({ selectedPopId: params.row.populationid, onlyComputed: false, activeSwitch: false });
										E5RequestCBPop.Ins().FetchNetIds(params.row.populationid,
											"USER", this.HandleLoadNetIds)
									}}>
										{displaystr}
									</BP.Button>
								} else {
									return <BP.Button style={{ margin: '0 auto' }} className="e5linkbutton displaybut" minimal onClick={() => {
										this.setState({ selectedPopId: params.row.populationid, onlyComputed: true, activeSwitch: true });
										E5RequestCBPop.Ins().FetchNetIds(params.row.populationid,
											"COMPUTED", this.HandleLoadNetIds)
									}}>
										{displaystr}
									</BP.Button>
								}
							},
						},
						]}
						rows={
							[...this.state.filterpops.map((row, index) => {

								return ({
									...row,
									id: row.name,
									creationdate: row.creationdate?.format(E5UtilI18n._("dateformat")),
									networkidcount: row.networkidcount || E5UtilI18n._("wificb-pops-unavailable"),
									criterias: this.GetCritirea(row),
								})
							}
							)]}
						getActions={(id: any) => this.CellRenderer(id, 'actions')}
						rowSelectionModel={this.state.rowSelectionModel}
						onRowDoubleClick={(data) => {
							this.setState({ onlyComputed: false })
							const population = this.state.filterpops.filter((pop) => pop.name === data.id)
							this.OpenPop(population[0])
						}}
						onCellClick={(cell) => {
							if (cell.field === "__check__") {
								const currentCheckedRows = [...this.state.rowSelectionModel];
								if (!currentCheckedRows.includes(cell.id)) {
									currentCheckedRows.push(cell.id)
								} else {
									var index = currentCheckedRows.indexOf(cell.id);    // <-- Not supported in <IE9
									if (index !== -1) {
										currentCheckedRows.splice(index, 1);
									}
								}
								this.setState({ rowSelectionModel: currentCheckedRows })
							}
						}}
					/>
				</div>
			</div>
			<BP.Dialog className="wificb-pops-popup-dialog" style={{ zIndex: 100, width: 478, backgroundColor: '#fafcff !important' }} isOpen={this.state.dialopen}
				title={_(curpopisnew ? "wificb-pops-popup-add" : "wificb-pops-popup-update")}
				canOutsideClickClose={false} canEscapeKeyClose={false} onClose={this.ClosePop}>
				<div className={BP.Classes.DIALOG_BODY}>
					<E5CBPopPopup langinfo={this.props.langinfo} pop={selectpop}
						updatefunc={this.PopIsUpdated} canaddnetworkids={canaddnetids}
						wholeparkexists={this.state.wholeparkexists} canaddcriterias={canaddcrits} />
				</div>
				<div className={BP.Classes.DIALOG_FOOTER}>
					<div className={BP.Classes.DIALOG_FOOTER_ACTIONS + " e5wificb-pops-footer-actions"}>
						{!this.props.popgetinfo.status.loading
							? <div className="e5column-10" style={{ flex: 1 }}>
								<div className="e5line-10" style={{ alignItems: "center" }}>
									<div className="e5linefull" />
									{canvisualize === false && <div style={{ height: "30px" }} />}
									{canvisualize === "immediate" && <>
										<div>{_("wificb-pops-actions")}</div>
										<BP.Button onClick={() => E5RequestCBPop.Ins().FetchNetIds(
											selectpop.populationid, "COMPUTED", this.HandleLoadNetIds)
										}>{displaystr}</BP.Button>
										<BP.Button onClick={() => E5RequestCBPop.Ins().DownloadNetIds(
											selectpop.populationid, "COMPUTED", this.HandleDownloadNetIds)
										}>{downloadstr}</BP.Button>
									</>}
									{canvisualize === "popover" && <>
										<div>{_("wificb-pops-actions")}</div>
										<BP.Popover
											isOpen={nilistchoiceopen} popoverClassName={BP.Classes.POPOVER_DISMISS}
											content={<div className="e5column-5 e5wificb-pops-actionspopover">
												<BP.Button onClick={() => {
													E5RequestCBPop.Ins().FetchNetIds(
														selectpop.populationid, this.state.onlyComputed ? 'COMPUTED' : "USER", this.HandleLoadNetIds);
													this.setState({ activeSwitch: false, selectedPopId: selectpop.populationid })

												}}>
													{displaystr}&nbsp;{_("wificb-pops-actions-user-ids")}
												</BP.Button>
												<BP.Button onClick={() => {
													E5RequestCBPop.Ins().FetchNetIds(
														selectpop.populationid, "COMPUTED", this.HandleLoadNetIds);
													this.setState({ activeSwitch: true, selectedPopId: selectpop.populationid })
												}}>
													{displaystr}&nbsp;{_("wificb-pops-actions-active-ids")}
												</BP.Button>
											</div>}>
											<BP.Button>
												{displaystr}
												<span style={{ color: "#FF0000" }}>*</span>
											</BP.Button>
										</BP.Popover>
										<BP.Popover
											isOpen={nilistchoiceopen} popoverClassName={BP.Classes.POPOVER_DISMISS}
											content={<div className="e5column-5 e5wificb-pops-actionspopover">
												<BP.Button onClick={() => E5RequestCBPop.Ins().DownloadNetIds(
													selectpop.populationid, "USER", this.HandleDownloadNetIds)}>
													{downloadstr}&nbsp;{_("wificb-pops-actions-user-ids")}
												</BP.Button>
												<BP.Button onClick={() => E5RequestCBPop.Ins().DownloadNetIds(
													selectpop.populationid, "COMPUTED", this.HandleDownloadNetIds)}
												>{downloadstr}&nbsp;{_("wificb-pops-actions-active-ids")}
												</BP.Button>
											</div>}>
											<BP.Button>
												{downloadstr}
												<span style={{ color: "#FF0000" }}>*</span>
											</BP.Button>
										</BP.Popover>
									</>}
								</div>
								<div className="e5line-10" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
									{(!curpopisnew && !this.state.editpopchanged) &&
										<BP.Popover
											enforceFocus={false} popoverClassName={BP.Classes.POPOVER_DISMISS} content={
												<div className="e5wificb-pops-confirm e5column-10">
													<div>{_("wificb-pops-confirmdelete")}</div>
													<div className="e5line-5 e5wificb-pops-flex-between">
														<Button theme="red" onClick={this.HandleDelete2}>{_("delete")}</Button>
														<Button theme="blue">{_("cancel")}</Button>
													</div>
												</div>}>
											<BP.Tooltip content={_("wificb-pops-deletetip")}>
												<Button theme="red" >{_("delete")}</Button>
											</BP.Tooltip>
										</BP.Popover>}
									{canduplicate &&
										<BP.Popover
											enforceFocus={false} popoverClassName={BP.Classes.POPOVER_DISMISS} content={
												<div className="e5wificb-pops-confirm e5column-10">
													<div>
														{_("wificb-pops-confirmduplicate")}
														<b>{selectpop.name + this.GetCopyNameSuffix()}</b>
													</div>
													<div className="e5line-5 e5wificb-pops-flex-between" >
														<Button theme="green" onClick={this.HandleDuplicate2}>{_("wificb-pops-duplicate")}</Button>
														<Button theme="blue">{_("cancel")}</Button>
													</div>
												</div>}>
											<BP.Tooltip content={_("wificb-pops-duplicatetip")}>
												<Button theme="green">{_("wificb-pops-duplicate")}</Button>
											</BP.Tooltip>
										</BP.Popover>}
									{!curpopisnew && cansave && this.state.editpopchanged
										&& !this.state.onlyinfochanged &&
										<BP.Popover
											enforceFocus={false} popoverClassName={BP.Classes.POPOVER_DISMISS} content={
												<div className="e5wificb-pops-confirm e5column-10">
													<div>{_("wificb-pops-confirmmodify")}</div>
													<div className="e5line-5 e5wificb-pops-flex-between">
														<Button theme="green" onClick={this.HandleSave2}>{_("update")}</Button>
														<Button theme="blue">{_('cancel')}</Button>
													</div>
												</div>}>
											<Button theme="green">{_(curpopisnew ? "add" : "save")}</Button>
										</BP.Popover>}
									<div>
										{cansave && this.state.editpopchanged
											&& (curpopisnew || this.state.onlyinfochanged) &&
											<Button theme="green" onClick={this.HandleSave2}>{_(curpopisnew ? "Add this new population" : "wificb-pops-save-name")}</Button>
										}
										<Button theme="blue" onClick={this.ClosePop}>{_("cancel")}</Button>
									</div>
								</div>
							</div> : <BP.Spinner className="e5spinwait" size={25} />}
					</div>
				</div>
			</BP.Dialog>
			<BP.Dialog style={{ zIndex: 1002 }} isOpen={this.state.nilist.length > 0 || this.state.selectedPopId}
				onClose={this.CloseNiList} title={E5Text.Substitute(_("wificb-pops-nilist"),
					[String(E5RequestMeta.Ins().total_network_id)])}>
				<div className='dialog-filters'>
					<TextFieldStyled
						size="small"
						className='dialog-filter'
						id="outlined-basic" label={'Household id'}
						value={this.state.activeFilter}
						variant="outlined"
						onChange={(e) => this.setState({ activeFilter: e.target.value })}
					// InputProps={{
					// 	classes: {
					// 		notchedOutline: {
					// 			borderWidth: "1px",
					// 			borderColor: "yellow !important"
					// 		}
					// 	}
					// }}
					/>

					<div className='switch-container'>
						<div className={!this.state.activeSwitch ? 'button active' : 'button'}
							onClick={() => {
								if (this.state.activeSwitch && !this.state.onlyComputed) {
									this.setState({ activeSwitch: false })
									E5RequestCBPop.Ins().FetchNetIds(this.state.selectedPopId, "USER", this.HandleLoadNetIds)
								}
							}}
						>
							All Id
						</div>
						<div className={this.state.activeSwitch ? 'button active' : 'button'}
							onClick={() => {
								if (!this.state.activeSwitch) {
									this.setState({ activeSwitch: true })
									E5RequestCBPop.Ins().FetchNetIds(this.state.selectedPopId, "COMPUTED", this.HandleLoadNetIds)
								}
							}}
						>
							Id active yesterday
						</div>
					</div>
				</div>
				<div className="wificb-pops-list-wrapper">
					{this.state.nilist.filter((id, index) => id.toLocaleLowerCase().includes(this.state.activeFilter.toLocaleLowerCase())).map((item, index) => {
						const redirectLink = `/HouseholdDashboard?householdId=${item}`

						return <div className="wificb-pops-item-wrapper">
							<p className="wificb-pops-item-text">{item}</p>
							<div className="wificb-pops-item-actions-wrapper">
								<Link className='wificb-pops-item-actions-link' to={redirectLink}>
									<img className="wificb-pops-item-actions-eye" src="/img/v3/population/eye.svg" />
								</Link>
								<div onMouseDown={() => this.onMouseDownOnCopyButton(index)}
									onClick={() => this.copyTextToClipboard(item)}
									onMouseUp={() => this.onMouseUpOnCopyButton()}
								>
									<img className="wificb-pops-item-actions-copy" src={this.state.clickedIndex === index ? "/img/v3/population/copy-black.svg" : '/img/v3/population/copy.svg'}
										onClick={() => this.copyTextToClipboard(item)} />
								</div>
							</div>
						</div>
					}
					)}
				</div>
				{/* <BP.TextArea className="e5wificb-pops-nilistarea dialog-content" value=
				{this.state.nilist.filter((id) => id.toLocaleLowerCase().includes(this.state.activeFilter.toLocaleLowerCase())).join("\n")}
				 /> */}
				<div className='dialog-footer'>
					<div className='dialog-footer-button'
						onClick={() => E5RequestCBPop.Ins().DownloadNetIds(this.state.selectedPopId, this.state.activeSwitch ? "COMPUTED" : "USER", this.HandleDownloadNetIds)}>Download</div>
					<div className='dialog-footer-button' onClick={this.CloseNiList}>Cancel</div>
				</div>
			</BP.Dialog>
		</div >;
	}

	//E5
	RenderColumn = (field: string): JSX.Element =>
		<BPT.Column cellRenderer={(ridx) => this.CellRenderer(ridx, field)}
			columnHeaderCellRenderer={() => this.HeadRenderer(field)} />;


	GetCritirea = (row: any) => {
		let datavalue = row.criterias;
		let val: string = ''
		if (datavalue !== null && datavalue?.length !== 0) {
			let idx: number;
			for (idx = 0; idx < datavalue.length; idx++) {
				let separator: string = idx === 0 ? "" : ", ",
					crit: E5CBPopCriteria = datavalue[idx], critstr: string;
				if (datavalue.length === 1 && crit.criteria === null && crit.condition === null
					&& crit.value === null) {
					critstr = E5UtilI18n._("wificb-pops-whole-park");
				} else {
					let criteria: string = E5UtilI18n._("meta-pop-filter-" + crit.criteria),
						value: string = "", condition: string = "";
					if (crit.value !== null) value = crit.value;
					if (crit.condition !== null) {
						if (crit.condition === "contains" || crit.condition === "!contains") {
							condition = ": " + E5UtilI18n._("wificb-pops-cond-" + crit.condition);
							value = "";
						} else condition = crit.condition;
					}
					critstr = criteria + " " + condition + " " + value;
				}
				val += separator + critstr;
			}
		} else {
			if (row.networkids && typeof row.networkids === 'object') {
				row.networkids = row.networkids.length;
			}
			val = E5UtilI18n._("wificb-pops-listof") + " (" + row.networkids + ")"
		};
		return val;
	}
	//E5
	CellRenderer = (rowidx: number, field: string): JSX.Element => {
		let cell: JSX.Element = <div />, { filterpops } = this.state;
		if (typeof rowidx === 'string') {
			const aclGroupId = filterpops.findIndex((item) => item.name === rowidx);
			rowidx = aclGroupId;
		}

		if (rowidx < filterpops?.length) {
			let val: string = "", cellcontent: JSX.Element | undefined,

				datavalue: any = (filterpops[rowidx] as any)[field], curselectpop: E5EntCBPop = filterpops[rowidx];
			if (field === "creationdate") {
				if (datavalue !== null) {
					let mm: Moment | undefined = datavalue as Moment;
					if (mm === undefined) val = "";
					else val = mm.format(E5UtilI18n._("dateformat"));
				}
			} else if (field === "networkidcount") {
				if (datavalue !== null) val = datavalue;
				else val = E5UtilI18n._("wificb-pops-unavailable");
			} else if (field === "criterias") {
				console.log('criterias')
				if (datavalue !== null && datavalue?.length !== 0) {
					console.log('datavalue', datavalue)

					let idx: number;
					for (idx = 0; idx < datavalue.length; idx++) {
						let separator: string = idx === 0 ? "" : ", ",
							crit: E5CBPopCriteria = datavalue[idx], critstr: string;
						if (datavalue.length === 1 && crit.criteria === null && crit.condition === null
							&& crit.value === null) {
							critstr = E5UtilI18n._("wificb-pops-whole-park");
						} else {
							let criteria: string = E5UtilI18n._("meta-pop-filter-" + crit.criteria),
								value: string = "", condition: string = "";
							if (crit.value !== null) value = crit.value;
							if (crit.condition !== null) {
								if (crit.condition === "contains" || crit.condition === "!contains") {
									condition = ": " + E5UtilI18n._("wificb-pops-cond-" + crit.condition);
									value = "";
								} else condition = crit.condition;
							}
							critstr = criteria + " " + condition + " " + value;
						}
						val += separator + critstr;
					}
					//@ts-ignore
				} else val = E5UtilI18n._("wificb-pops-listof") + " (" + (Array.isArray(curselectpop.networkids) ? curselectpop.networkids?.length : curselectpop.networkids) + ")";
			} else if (field === "actions") {
				let displaystr: string = E5UtilI18n._("wificb-pops-actions-display"),
					downloadstr: string = E5UtilI18n._("wificb-pops-actions-download");
				if (filterpops[rowidx].criterias === null || filterpops[rowidx].criterias?.length === 0) {
					let popoveropen: boolean | undefined = undefined;//popover has default behaviour
					if (this.state.dialopen || this.state.nilist.length > 0)//but only if the CRUD/nilist popup opens
						popoveropen = false;//then popover closes to avoid the case where it's above the popup
					cellcontent = <div className="e5line-2">
						{/* <BP.Popover isOpen={popoveropen} popoverClassName={BP.Classes.POPOVER_DISMISS}
									content={<div className="e5column-5 e5wificb-pops-actionspopover">
										<BP.Button onClick={() =>
											E5RequestCBPop.Ins().FetchNetIds(filterpops[rowidx].populationid,
												"USER", this.HandleLoadNetIds)}>
											{displaystr}&nbsp;{E5UtilI18n._("wificb-pops-actions-user-ids")}
										</BP.Button>
										<BP.Button onClick={() =>
											E5RequestCBPop.Ins().FetchNetIds(filterpops[rowidx].populationid,
												"COMPUTED", this.HandleLoadNetIds)}>
											{displaystr}&nbsp;{E5UtilI18n._("wificb-pops-actions-active-ids")}
										</BP.Button>
									</div>}> */}
						<BP.Button className="e5linkbutton displaybut" minimal onClick={() => {
							this.setState({ selectedPopId: filterpops[rowidx].populationid, onlyComputed: false });
							E5RequestCBPop.Ins().FetchNetIds(filterpops[rowidx].populationid,
								"USER", this.HandleLoadNetIds)
						}}>
							{displaystr}
						</BP.Button>
						{/* </BP.Popover> */}
						{/* <BP.Popover isOpen={popoveropen} popoverClassName={BP.Classes.POPOVER_DISMISS}
									content={<div className="e5column-5 e5wificb-pops-actionspopover">
										<BP.Button onClick={() =>
											E5RequestCBPop.Ins().DownloadNetIds(filterpops[rowidx].populationid,
												"USER", this.HandleDownloadNetIds)}>
											{downloadstr}&nbsp;{E5UtilI18n._("wificb-pops-actions-user-ids")}
										</BP.Button>
										<BP.Button onClick={() =>
											E5RequestCBPop.Ins().DownloadNetIds(filterpops[rowidx].populationid,
												"COMPUTED", this.HandleDownloadNetIds)}>
											{downloadstr}&nbsp;{E5UtilI18n._("wificb-pops-actions-active-ids")}
										</BP.Button>
									</div>}>
							<BP.Button className="e5linkbutton downloadbut" minimal>
								{downloadstr}
								<span style={{color: "#FF0000"}}>*</span>
							</BP.Button>
						</BP.Popover> */}
					</div>;
				} else cellcontent = <div className="e5line-2" style={{ paddingTop: 4 }}>
					<BP.Button className="e5linkbutton displaybut" minimal onClick={() => {
						this.setState({ selectedPopId: filterpops[rowidx].populationid, activeSwitch: true });
						E5RequestCBPop.Ins().FetchNetIds(filterpops[rowidx].populationid,
							"COMPUTED", this.HandleLoadNetIds)
					}}
					>
						{displaystr}
					</BP.Button>
					{/* <BP.Button className="e5linkbutton downloadbut" minimal onClick={() =>
						E5RequestCBPop.Ins().DownloadNetIds(filterpops[rowidx].populationid,
							"COMPUTED", this.HandleDownloadNetIds)}>{downloadstr}</BP.Button> */}
				</div>;
			} else if (datavalue !== null) val = datavalue;
			cell = cellcontent;
		}
		return cell;
	};
	copyTextToClipboard = async (text: string) => {
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand('copy', true, text);
		}
	};

	//E5
	HeadRenderer = (field: string): JSX.Element => {
		let cell: JSX.Element, classname: BP.IconName = "double-caret-vertical" as BP.IconName,
			intent: BP.Intent = BP.Intent.NONE;
		if (this.state.sortfield === field) {
			intent = BP.Intent.DANGER;
			if (this.state.sortdir === E5CBPopSortDir.up) classname = "caret-up";
			else classname = "caret-down";
		}
		let hassort: boolean = field !== "networkidcount" && field !== "criterias" && field !== "actions";
		cell = <div className="tbhead">
			<div className="tbheadname">{E5UtilI18n._("wificb-pops-" + field)}</div>
			{hassort && <BP.Button icon={classname} minimal small intent={intent} className="tbheadsort"
				onClick={() => this.ChangeSort(field)} />}
		</div>;
		return cell;
	};

	// ---------------- EVENTS ----------------
	showResetFiltersButton = () => this.setState({ isAnyFilterActive: true });
	hideResetFiltersButton = () => this.setState({ isAnyFilterActive: false });
	onChangeFilterByAcl = (aclName: string) => {
		let acls: string[] = [...this.state.filterByACL];
		if (acls.includes(aclName)) {
			acls = acls.filter(e => e !== aclName);
		} else {
			acls.push(aclName)
		}
		this.setState({ filterByACL: acls })
		this.showResetFiltersButton()
	}
	onChangeFilterByUser = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!event.currentTarget.value) {
			this.setState({ filteredName: null, filterByNameInputValue: '' })
		} else {
			this.setState({
				filteredName: event.currentTarget.value,

				filterByNameInputValue: event.currentTarget.value
			})
			this.showResetFiltersButton()
		}
	};
	onChangeFilterByDateFrom = (date: any) => {
		const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
		this.setState({ fromDate: formattedDate })
		this.showResetFiltersButton()
	}
	onChangeFilterByDateUntil = (date: any) => {
		const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
		this.setState({ untilDate: formattedDate })
		this.showResetFiltersButton()
	}
	onResetFilters = () => {
		this.setState({
			filteredName: null,
			fromDate: new Date(0).toUTCString(),
			untilDate: new Date().toString(),
			filterByNameInputValue: '',
			filterByACL: []
		})
	}
	onMouseDownOnCopyButton = (index: number) => {
		this.setState({
			clickedIndex: index,
		})
	};
	onMouseUpOnCopyButton = () => {
		this.setState({
			clickedIndex: null,
		})
	};
	FilterAll = ({ }) => {
		const fromDate = dayjs(this.state.fromDate).format('YYYY-MM-DD')
		const untilDate = dayjs(this.state.untilDate).format('YYYY-MM-DD')
		const name = this.state.filteredName;
		const acls: string[] = this.state.filterByACL;
		const tableData = this.state.filtereddatanew;
		const filtetByAcl = acls.length ? tableData.filter((item: { aclname: string }) => acls.includes(item.aclname)) : tableData;
		const filterByName = name ? filtetByAcl.filter(item => item.name.toLowerCase().includes(name)) : filtetByAcl;
		const filterFromDate = filterByName.filter(({ creationdate }) => {
			return new Date(creationdate) > new Date(fromDate)
		});
		const filterUntilDate = filterFromDate.filter(({ creationdate }) => new Date(creationdate) < new Date(untilDate));
		this.setState({ filterpops: filterUntilDate })

	}
	//E5
	ChangeFilterCheckbox = (field: string, checkboxitem: string, event: any): void => {
		let checked = event.currentTarget.checked, filterinput: any = this.CloneFilterInput();
		if (filterinput[field] === undefined) filterinput[field] = {};
		filterinput[field][checkboxitem] = checked;

		let allunchecked: boolean = true, key: string;
		for (key in filterinput[field])
			if (filterinput[field].hasOwnProperty(key) && filterinput[field][key] === true) {
				allunchecked = false;
				break;
			}
		if (allunchecked) filterinput[field] = undefined;

		this.setState({ filterinput }, () => {
			let newactivefilter: any = this.CloneActiveFilter();
			if (newactivefilter[field] === undefined) newactivefilter[field] = {};
			newactivefilter[field][checkboxitem] = checked;
			if (allunchecked) newactivefilter[field] = undefined;
			this.BuildFiltered(newactivefilter);
		});
	};

	//E5
	HandleLoadNetIds = (status: E5RequestStatus, netids: string[]): void => {
		if (!status.success) this.props.nonetidsfunc(status);
		this.setState({ nilist: netids });
	};

	//E5
	CloseNiList = (): void => this.setState({ nilist: [], selectedPopId: '', activeSwitch: false, activeFilter: '' });

	//E5
	HandleDownloadNetIds = (status: E5RequestStatus): void => {
		if (!status.success) this.props.nonetidsfunc(status);
	};

	//E5
	ChangeName = (event: any): void => {
		let value: string | undefined = event.currentTarget.value, filterinput: any = this.CloneFilterInput();
		if (value === "") value = undefined;
		filterinput.name = value;
		this.setState({ filterinput }, () => {
			if (this.filter_timer !== undefined) clearTimeout(this.filter_timer);
			this.filter_timer = setTimeout(() => { this.ChangeName2(value); }, 600);
		});
	};

	//E5
	ChangeName2 = (name: string | undefined): void => {
		let newactivefilter: any = this.CloneActiveFilter();
		if (name !== undefined) newactivefilter.name = this.NormalizeSyntax(name);
		else newactivefilter.name = undefined;
		this.BuildFiltered(newactivefilter);
	};

	//E5
	ChangeDateRange = (field: string, isstart: boolean, seldate: Date, userchange: boolean): void => {
		if (userchange) {
			let newactivefilter: any = this.CloneActiveFilter(), filterinput: any = this.CloneFilterInput();
			let start: Moment | undefined = undefined, end: Moment | undefined = undefined;
			if (newactivefilter[field] !== undefined) {
				start = newactivefilter[field].start;
				end = newactivefilter[field].end;
			}
			let isvalid: boolean = seldate !== null && !isNaN(seldate.getTime());
			if (isstart) {
				start = undefined;
				if (isvalid) start = moment(seldate).hour(2).minute(0).second(0);
			} else {
				end = undefined;
				if (isvalid) end = moment(seldate).hour(22).minute(0).second(0);
			}
			newactivefilter[field] = { start, end };
			filterinput[field] = { start, end };
			this.setState({ filterinput }, () => this.BuildFiltered(newactivefilter));
		}
	};

	//E5
	ChangeSort = (field: string): void => {
		let { up, down } = E5CBPopSortDir, direction: E5CBPopSortDir = up;
		if (this.state.sortfield === field && this.state.sortdir === up) direction = down;
		this.BuildFiltered(undefined, field, direction);
	};

	//E5
	OpenPop = (selectpop: E5EntCBPop): void => this.setState({
		dialopen: true, selectpop, duplunix: moment().unix(), editpop: selectpop, editpopchanged: false,
		onlyinfochanged: true
	});

	//E5
	AddPop = (): void => this.setState({
		dialopen: true, selectpop: new E5EntCBPop(), editpop: new E5EntCBPop(), editpopchanged: false,
		onlyinfochanged: true
	});

	//E5
	HandleDelete2 = (): void =>
		this.props.savefunc(E5CBPopAction.delete, this.state.selectpop, this.state.selectpop.populationid,
			false, (success: boolean) => {
				if (success) this.ClosePop();
			});

	//E5
	HandleDuplicate2 = (): void => {
		let dupl = new E5EntCBPop();
		dupl.Copy(this.state.selectpop);
		dupl.populationid = "";
		dupl.creationdate = null;
		dupl.networkidcount = null;
		dupl.name += this.GetCopyNameSuffix();
		this.props.savefunc(E5CBPopAction.add, dupl, "", false,
			(success: boolean, message: string, pop?: E5EntCBPop) => {
				if (success && pop !== undefined) this.OpenPop(pop);
			});
	};

	//E5
	HandleSave2 = (): void => {
		this.props.savefunc(this.state.selectpop.populationid === "" ? E5CBPopAction.add : E5CBPopAction.update,
			this.state.editpop, this.state.selectpop.populationid, this.state.onlyinfochanged,
			(success: boolean) => {
				if (success) this.ClosePop();
			});
	}
	//E5
	ClosePop = (): void => this.setState({ dialopen: false });

	//E5
	PopIsUpdated = (editpop: E5EntCBPop, onlyinfochanged: boolean): void => this.setState(
		{ editpop, editpopchanged: true, onlyinfochanged: this.state.onlyinfochanged && onlyinfochanged });

	//E5
	DelFilters = (): void => this.setState({ filterinput: {} }, () => this.BuildFiltered({}));

	// ---------------- UTILS ----------------

	//E5
	BuildFiltered = (curfilter?: any, sortfield?: string, sortdir?: E5CBPopSortDir): void => {
		let content: any = document.getElementsByClassName("e5wificb-populations-content"),
			inner: any = document.getElementsByClassName("e5wificb-pops-poptableinner");
		if (content !== undefined && content !== null && content.length > 0 &&
			inner !== undefined && inner !== null && inner.length > 0) {
			// find real size of window
			inner[0].style["width"] = (content[0].clientWidth) + "px";
			let innerheight: number = content[0].clientHeight - 160;//160=y of table in content + margin
			if (this.props.popinfo.pops.length * 20 + 26 + 20 > innerheight)
				inner[0].style["height"] = innerheight + "px";
			else inner[0].style["height"] = "";
		}

		if (curfilter === undefined) curfilter = this.state.curfilter;
		if (sortfield === undefined) sortfield = this.state.sortfield;
		if (sortdir === undefined) sortdir = this.state.sortdir;
		let wholeparkexists: boolean = false, filterpops: any[] = [];
		for (let pop of this.props.popinfo.pops) {
			if (!wholeparkexists && pop.criterias !== null && pop.criterias.length === 1) {
				let crit: E5CBPopCriteria = pop.criterias[0];
				if (crit.criteria === null && crit.condition === null && crit.value === null) wholeparkexists = true;
			}
			let okaclname: boolean = false, okname: boolean = curfilter["name"] === undefined ||
				this.NormalizeSyntax(pop.name).indexOf(curfilter["name"]) >= 0, okcreationdate: boolean = true;
			if (curfilter["creationdate"] !== undefined && pop.creationdate !== null) {
				okcreationdate = false;
				let itemdate: Moment = pop.creationdate, filtermin: Moment = curfilter["creationdate"].start,
					filtermax: Moment = curfilter["creationdate"].end;
				if (filtermin !== undefined && filtermax !== undefined)
					okcreationdate = itemdate.unix() >= filtermin.unix() && itemdate.unix() <= filtermax.unix();
				else if (filtermin !== undefined) okcreationdate = itemdate.unix() >= filtermin.unix();
				else if (filtermax !== undefined) okcreationdate = itemdate.unix() <= filtermax.unix();
				else okcreationdate = true;

			}
			if (curfilter["aclname"] === undefined || curfilter["aclname"][pop.aclname] === true) okaclname = true;
			if (okname && okcreationdate && okaclname) filterpops.push(pop);
		}
		this.setState({ curfilter, sortfield, sortdir, wholeparkexists }, () => {
			filterpops.sort(this.Compare);
			this.setState({ filterpops, filtereddatanew: filterpops });
		});
	};

	//E5
	HasFilter = (): boolean => {
		let key: string, has: boolean = false;
		for (key in this.state.filterinput) if (this.state.filterinput.hasOwnProperty(key)) {
			let value: any = this.state.filterinput[key];
			if (value !== undefined) {
				if (key === "creationdate") {
					if (value.start !== undefined || value.end !== undefined) has = true;
				} else has = true;
				if (has) break;
			}
		}
		return has;
	};

	//E5
	Compare = (va: E5EntCBPop, vb: E5EntCBPop): number => {
		let ret: number = 0, { sortfield, sortdir } = this.state;
		if (sortfield === "name" || sortfield === "populationid" || sortfield === "aclname")
			ret = va[sortfield].localeCompare(vb[sortfield]);
		else if (sortfield === "creationdate" && va.creationdate !== null && vb.creationdate !== null)
			ret = va.creationdate.unix().toString().localeCompare(vb.creationdate.unix().toString());
		if (sortdir === E5CBPopSortDir.down) ret = -ret;
		return ret;
	};

	//E5
	// normalize = sépare les caractères spéciaux (é => e')
	// replace = supprime les caractères spéciaux
	NormalizeSyntax = (val: string): string =>
		val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

	//E5
	CloneActiveFilter = (): any => Object.assign({}, this.state.curfilter);

	//E5
	CloneFilterInput = (): any => Object.assign({}, this.state.filterinput);

	//E5
	FormatDate = (dt: Date): string => moment(dt).format(E5UtilI18n._("dateformat"));

	//E5
	ParseDate = (str: string): Date => moment(str, E5UtilI18n._("dateformat")).toDate();

	//E5
	GetCopyNameSuffix = (): string => "-" + E5UtilI18n._("wificb-pops-copynamesuffix") + "-" + this.state.duplunix;
});
